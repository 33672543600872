import {ApolloClient} from '@apollo/client';
import {NotificationsActionCreator, NotificationType} from '@eon.cz/apollo13-frontend-common';
import {Komodita, PasswordQuality} from '@eon.cz/apollo13-graphql';
import {bindActionCreators, Dispatch, UnknownAction} from '@reduxjs/toolkit';
import {captureException} from '@sentry/browser';
import axios from 'axios';
import Router from 'next/router';
import {useStore} from 'react-redux';
import {BackendEndpoints} from '../../../../server/BackendEndpoints';
import {Lang} from '../../../Lang';
import {RootActionType} from '../../../RootAction';
import {Store} from '../../../Store';
import {apolloClient} from '../../../with/withApolloProvider';
import {PageRoute} from '../../Common/model';
import {setAdminData} from '../../Common/reducers/CommonReducers';
import {RouteService} from '../../Common/services/RouteService';
import {ProfileService} from '../../Profile/service';
import {LoginModel} from '../model';
import {
    setChangeLoginStatus,
    setChangeRefetchStatus,
    setClearLoginData,
    setErrorFetchedLogin,
    setFetchedLogin,
    setFetchingLogin,
    setLoginConfigFetched,
    setLoginConfigFetching,
    setLogouting,
    setPasswordQuality,
    setRemoveErrorLogin,
    setStoreLoginData,
    setSwitchPristup,
} from '../reducers';
import {getErrorMessage, removeCookie, setCookie, storeLoggedInRestrictedMode, storeLoggedUserForDebug} from '../service/AuthService';

type LoginConfigResponse = {
    readonly data: {
        readonly SC_LOGIN_ZAKAZAN: boolean;
        readonly SC_LOGIN_ZAKAZAN_HTML: string;
        readonly SC_ODHLASIT_PRIHLASENE: boolean;
        readonly SC_ODHLASIT_PRIHLASENE_HTML: string;
    };
};
type AuthenticateResponse = {
    readonly data: {
        readonly expiresIn: number;
        readonly mockSap: boolean;
        readonly success: boolean;
        readonly token: string;
        readonly userFullAccess: boolean;
        readonly passwordQuality: PasswordQuality;
    };
};

type AuthAction = {
    /**
     * Login with password or certificate
     *
     * @param credentials {LoginModel} Login credentials
     * @param path {string} Path to go to after login (defaults to noticeboard)
     */
    readonly login: (credentials: LoginModel, path?: string) => void;
    readonly switchPristup: (finishRegisterUzivatel?: boolean) => void;

    /**
     * Set login error with given code
     *
     * @param code Error code
     */
    readonly setLoginError: (code: string) => UnknownAction;

    readonly removeErrorLogin: () => UnknownAction;

    readonly clearLoginData: () => void;

    readonly logout: (client: ApolloClient<any> | undefined) => void;

    /**
     * Execute login to a limited access account (with data stored in the store)
     */
    readonly executeRestrictedAccessLogin: () => void;

    /**
     * Fetch login configuration
     */
    readonly fetchLoginConfig: () => void;
};

const sanitizePath = (path?: string) => {
    if (typeof path === 'string') {
        // Ensure a path is absolute, but it does not start with two slashes => redirect to another site is not possible
        if (path.length > 2 && path[0] === '/' && path[1] !== '/') {
            // Can use it
            return path;
        }
    }

    // Use default
    const komodita = ProfileService.getKomodita();
    return `/${komodita ? komodita.toLowerCase() : ''}`;
};

const doLogin = async (token: string, expiresIn: number, mockSap: boolean, passwordQuality: PasswordQuality, path: string | undefined, dispatch: Dispatch) => {
    dispatch(setPasswordQuality(passwordQuality));
    setCookie(token, expiresIn);
    ProfileService.setMockSap(mockSap);
    await Router.push({pathname: sanitizePath(path)});
};

export const useAuthAction = (): AuthAction => {
    const {getState, dispatch} = useStore<Store>();
    return {
        login: ({code, typ, admin, apolloClient, payload, signature, deviceId, mobileLogin, userId}: LoginModel, path?: string) => {
            dispatch(setFetchingLogin());

            if (admin === 'true') {
                apolloClient?.resetStore();
                removeCookie();
                dispatch(setAdminData(true));
            }
            axios
                .get<unknown, LoginConfigResponse>(`${window.location.origin}/api/${BackendEndpoints.LOGIN_CONFIG}`)
                .then(async (res) => {
                    if (res.data?.SC_LOGIN_ZAKAZAN) {
                        dispatch(setLoginConfigFetched(res?.data?.SC_LOGIN_ZAKAZAN ? res.data.SC_LOGIN_ZAKAZAN_HTML : undefined));
                    } else {
                        axios
                            .post<unknown, AuthenticateResponse>(`${window.location.origin}/authenticate`, {
                                typ: typ || 'OAUTH',
                                code,
                                payload,
                                signature,
                                deviceId,
                                mobileLogin,
                            })
                            .then(async (res) => {
                                dispatch(setFetchedLogin());

                                if (res.data.userFullAccess) {
                                    // OK
                                    storeLoggedInRestrictedMode(false);
                                    await doLogin(res.data.token, res.data.expiresIn, res.data.mockSap, res.data.passwordQuality, path, dispatch);
                                } else {
                                    // Deffer login
                                    dispatch(
                                        setStoreLoginData({
                                            token: res.data.token,
                                            expiresIn: res.data.expiresIn,
                                            mockSap: res.data.mockSap,
                                            passwordQuality: res.data.passwordQuality,
                                        }),
                                    );
                                }
                            })
                            .catch((err) => {
                                if (err.response && err.response.status === 401) {
                                    const message = mobileLogin ? Lang.UCET_PRIHLASENI_ERROR_LOGIN_MOBILE : err.response.data.error;

                                    captureException(new Error('Authentication error: Failed login attempt'), {
                                        extra: {
                                            userId,
                                            status: 401,
                                            responseData: err.response.data.error ?? 'Failed biometrics login',
                                            deviceId: deviceId?.slice(-6),
                                            payload: payload?.slice(-6),
                                            signature: signature?.slice(-6),
                                            typ,
                                        },
                                        tags: {
                                            category: 'auth',
                                        },
                                    });

                                    if (err.response.data && err.response.data.error) {
                                        dispatch(
                                            setErrorFetchedLogin({
                                                code: 'CUSTOM',
                                                message: err.response.data.error.includes('invalid json response')
                                                    ? 'Mobilní zobrazení s přihlášením bez certifikátu je umožněno pouze uživatelům, kteří nevystupují za obchodníka. Zástupci obchodníků se musí přihlásit s certifikátem a mobilní verze pro ně není určena.'
                                                    : message,
                                            }),
                                        );
                                    } else {
                                        dispatch(setErrorFetchedLogin({message: Lang.UCET_PRIHLASENI_KONTROLA_UDAJE_SPATNE, code: 'CUSTOM'}));
                                    }
                                } else {
                                    dispatch(setErrorFetchedLogin({message: Lang.KOMUNIKACE, code: 'CUSTOM'}));
                                }
                            });
                    }
                })
                .catch(() => {
                    dispatch(setErrorFetchedLogin({message: Lang.KOMUNIKACE, code: 'CUSTOM'}));
                });
        },

        setLoginError: (code: string) => dispatch(setErrorFetchedLogin({message: Lang.KOMUNIKACE, code})),

        executeRestrictedAccessLogin: () => {
            const loginData = getState().auth.restrictedAccessLoginData;

            if (!loginData) {
                throw new Error('executeRestrictedAccessLogin called without data stored');
            }

            storeLoggedInRestrictedMode(true);
            doLogin(loginData.token, loginData.expiresIn, loginData.mockSap, loginData.passwordQuality, PageRoute.NASTENKA.path, dispatch);
            dispatch(setClearLoginData());
        },

        clearLoginData: () => {
            Router.push({pathname: PageRoute.LOGIN.path}).then(() => {
                storeLoggedUserForDebug(undefined);

                // Reset store
                dispatch(setClearLoginData());
            });
        },

        removeErrorLogin: () => dispatch(setRemoveErrorLogin()),

        logout: (client) => {
            dispatch(setChangeLoginStatus());
            dispatch(setLogouting(true));
            if (typeof window !== 'undefined') {
                axios.post(`${window?.location?.origin}/logout`).then(() =>
                    client
                        ?.clearStore()
                        .then(() => {
                            ProfileService.setMockSap(false);
                            removeCookie();
                            Router.push({pathname: PageRoute.LOGIN.path})
                                .then(() => {
                                    storeLoggedUserForDebug(undefined);

                                    // Reset store
                                    dispatch({type: RootActionType.LOGOUT});
                                })
                                .catch(() => {
                                    // eslint-disable-next-line no-console
                                    console.error('Error while logging out');
                                });
                        })
                        // eslint-disable-next-line no-console
                        .catch((error) => console.error('Apollo clear store', error)),
                );
            }
        },

        switchPristup: (finishRegisterUzivatel = false) => {
            dispatch(setSwitchPristup());
            apolloClient.cache.reset().then(() => {
                axios
                    .get(`${window.location.origin}/api/${BackendEndpoints.LOGIN_CONFIG}`)
                    .then(async (res) => {
                        if (res.data.SC_LOGIN_ZAKAZAN) {
                            dispatch(setLoginConfigFetched(res?.data?.SC_LOGIN_ZAKAZAN ? res.data.SC_LOGIN_ZAKAZAN_HTML : undefined));
                        }
                        if (finishRegisterUzivatel) {
                            dispatch(setFetchedLogin());
                            dispatch(setChangeRefetchStatus());
                        } else {
                            dispatch(setChangeLoginStatus());
                            Router.push({pathname: RouteService.getPathname(PageRoute.NASTENKA, '', Komodita.ELEKTRINA)}).then(() =>
                                ProfileService.setKomodita(Komodita.ELEKTRINA),
                            );
                        }
                    })
                    .catch(() => {
                        dispatch(setErrorFetchedLogin({message: Lang.KOMUNIKACE, code: 'CUSTOM'}));
                    });
            });
        },

        fetchLoginConfig: () => {
            dispatch(setLoginConfigFetching());
            axios
                .get(`${window.location.origin}/api/${BackendEndpoints.LOGIN_CONFIG}`)
                .then((res: any) => {
                    dispatch(setLoginConfigFetched(!!res.data && !!res.data.SC_LOGIN_ZAKAZAN ? res.data.SC_LOGIN_ZAKAZAN_HTML : null));
                })
                .catch(() => {
                    dispatch(setLoginConfigFetched());
                });
        },
    };
};

const AuthActionImpl = {
    logout: (client: ApolloClient<Record<string, unknown>>, error?: string | undefined) => (dispatch: Dispatch) => {
        const addNotification = NotificationsActionCreator(dispatch).addNotification;
        dispatch(setLogouting(true));
        axios.post(`${window.location.origin}/logout`).then(() =>
            client
                .clearStore()
                .then(() => {
                    ProfileService.setMockSap(false);
                    removeCookie();
                    Router.push({pathname: PageRoute.LOGIN.path}).then(() => {
                        storeLoggedUserForDebug(undefined);
                        const errorMessage = getErrorMessage(error as string);
                        addNotification({
                            type: NotificationType.ERROR,
                            text: errorMessage,
                        });
                        // Reset store
                        dispatch({type: RootActionType.LOGOUT});
                    });
                })
                // eslint-disable-next-line no-console
                .catch((error) => console.error('Apollo clear store', error)),
        );
    },
};

export const AuthActionCreator = (dispatch: Dispatch) => bindActionCreators({...AuthActionImpl}, dispatch);
