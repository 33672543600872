// This file is generated from scripts/default-lang.ts, don't modify, run npm run generate:lang
export const Lang = {
    PORTAL_ADMIN_NAME: 'portal.admin.name',
    MENU: 'menu',
    MENU_KONTAKTY: 'menu.kontakty',
    MENU_PRISTUPY: 'menu.pristupy',
    KOMODITA: 'komodita',
    KOMODITA_TITLE: 'komodita.title',
    KOMODITA_OK: 'komodita.ok',
    KOMODITA_MOBILE_INFO_TITLE: 'komodita.mobile.info.title',
    KOMODITA_MOBILE_INFO_TEXT: 'komodita.mobile.info.text',
    HAS_NO_ACCESS: 'has.no.access',
    CHYBA_SOUBOR: 'chyba.soubor',
    NEPOVOLENY_FORMAT: 'nepovoleny.format',
    REGISTRACE: 'registrace',
    REGISTRACE_ZAPOMENUTE_HESLO: 'registrace.zapomenute.heslo',
    REGISTRACE_INFO_TITLE: 'registrace.info.title',
    REGISTRACE_INFO_DIALOG: 'registrace.info.dialog',
    REGISTRACE_INFO_ZADATEL_DIALOG: 'registrace.info.zadatel.dialog',
    REGISTRACE_INFO_DIALOG_MUNICIPALITA: 'registrace.info.dialog.municipalita',
    REGISTRACE_INFO_DIALOG_MUNICIPALITA_ZADATEL: 'registrace.info.dialog.municipalita.zadatel',
    UCET_CERTIFIKAT: 'ucet.certifikat',
    PORTAL: 'portal',
    PORTAL_MIMO_PROVOZ: 'portal.mimo.provoz',
    PROFILE_LOADING_PORTAL: 'profile.loading.portal',
    PROFILE_LOGGING_OUT: 'profile.logging.out',
    PROFILE_LOAD_ERROR: 'profile.load.error',
    PROFILE_RELOAD: 'profile.reload',
    UCET: 'ucet',
    ENERGETIK: 'energetik',
    ENERGETIK_SWITCH: 'energetik.switch',
    KOMUNIKACE: 'komunikace',
    AUTOCOMPLETE_EMAIL_PLACEHOLDER: 'autocomplete.email.placeholder',
    AUTOCOMPLETE_PHONE_PLACEHOLDER: 'autocomplete.phone.placeholder',
    AUTOCOMPLETE_ADRESA_PLACEHOLDER: 'autocomplete.adresa.placeholder',
    UCET_ADMINISTRATOR: 'ucet.administrator',
    UCET_SPRAVCE: 'ucet.spravce',
    UCET_ZMOCNENEC: 'ucet.zmocnenec',
    UCET_ZADATEL: 'ucet.zadatel',
    UCET_PRIHLASENI: 'ucet.prihlaseni',
    UCET_PRIHLASENI_UZIVATEL: 'ucet.prihlaseni.uzivatel',
    UCET_PRIHLASENI_LOADING_DESCRIPTION: 'ucet.prihlaseni.loading.description',
    UCET_PRIHLASENI_LOADING_TOKEN: 'ucet.prihlaseni.loading.token',
    UCET_PRIHLASENI_KONTROLA_UDAJE: 'ucet.prihlaseni.kontrola.udaje',
    UCET_PRIHLASENI_KONTROLA_UDAJE_SPATNE: 'ucet.prihlaseni.kontrola.udaje.spatne',
    UCET_PRIHLASENI_CERTIFIKAT: 'ucet.prihlaseni.certifikat',
    UCET_PRIHLASENI_CERTIFIKAT_BEZ: 'ucet.prihlaseni.certifikat.bez',
    UCET_PRIHLASENI_OBCHODNIK_ELEKTRINA_PLYN: 'ucet.prihlaseni.obchodnik.elektrina.plyn',
    UCET_PRIHLASENI_CERTIFIKAT_STAV: 'ucet.prihlaseni.certifikat.stav',
    UCET_PRIHLASENI_CERTIFIKAT_STAV_ZISKAVAM: 'ucet.prihlaseni.certifikat.stav.ziskavam',
    UCET_PRIHLASENI_CERTIFIKAT_STAV_OK: 'ucet.prihlaseni.certifikat.stav.ok',
    UCET_PRIHLASENI_CERTIFIKAT_STAV_CHYBA: 'ucet.prihlaseni.certifikat.stav.chyba',
    UCET_PRIHLASENI_CERTIFIKAT_SUBJEKT: 'ucet.prihlaseni.certifikat.subjekt',
    UCET_PRIHLASENI_CERTIFIKAT_VYSTAVITEL: 'ucet.prihlaseni.certifikat.vystavitel',
    UCET_PRIHLASENI_CERTIFIKAT_PLATNOSTOD: 'ucet.prihlaseni.certifikat.platnostOd',
    UCET_PRIHLASENI_CERTIFIKAT_PLATNOSTDO: 'ucet.prihlaseni.certifikat.platnostDo',
    UCET_PRIHLASENI_CERTIFIKAT_POPISCHYBY: 'ucet.prihlaseni.certifikat.popisChyby',
    UCET_PRIHLASENI_CERTIFIKAT_POPISCHYBY_CHYBA: 'ucet.prihlaseni.certifikat.popisChyby.chyba',
    UCET_PRIHLASENI_CERTIFIKAT_POPISCHYBY_VYPRSEL: 'ucet.prihlaseni.certifikat.popisChyby.vyprsel',
    UCET_PRIHLASENI_CERTIFIKAT_FETCH_ERROR_1: 'ucet.prihlaseni.certifikat.fetch.error.1',
    UCET_PRIHLASENI_CERTIFIKAT_FETCH_ERROR_2: 'ucet.prihlaseni.certifikat.fetch.error.2',
    UCET_PRIHLASENI_CERTIFIKAT_VYPRSEL: 'ucet.prihlaseni.certifikat.vyprsel',
    UCET_PRIHLASENI_CERTIFIKAT_ZNOVU: 'ucet.prihlaseni.certifikat.znovu',
    UCET_PRIHLASENI_CERTIFIKAT_ZADNY: 'ucet.prihlaseni.certifikat.zadny',
    UCET_PRIHLASENI_CERTIFIKAT_ZADNY_P1: 'ucet.prihlaseni.certifikat.zadny.p1',
    UCET_PRIHLASENI_CERTIFIKAT_ZADNY_P2: 'ucet.prihlaseni.certifikat.zadny.p2',
    UCET_PRIHLASENI_CERTIFIKAT_ZADNY_HINT1: 'ucet.prihlaseni.certifikat.zadny.hint1',
    UCET_PRIHLASENI_CERTIFIKAT_ZADNY_HINT2: 'ucet.prihlaseni.certifikat.zadny.hint2',
    UCET_PRIHLASENI_CERTIFIKAT_ZADNY_HINT3: 'ucet.prihlaseni.certifikat.zadny.hint3',
    UCET_PRIHLASENI_ERROR_TOKEN_TITLE: 'ucet.prihlaseni.error.token.title',
    UCET_PRIHLASENI_ERROR_NO_TOKEN: 'ucet.prihlaseni.error.NO_TOKEN',
    UCET_PRIHLASENI_ERROR_CERTVAL005_STRIP: 'ucet.prihlaseni.error.CERTVAL005.strip',
    UCET_PRIHLASENI_ERROR_CERTVAL005_DESCRIPTION: 'ucet.prihlaseni.error.CERTVAL005.description',
    UCET_PRIHLASENI_ERROR_CERTVAL007: 'ucet.prihlaseni.error.CERTVAL007',
    UCET_PRIHLASENI_ERROR_CERTVAL010: 'ucet.prihlaseni.error.CERTVAL010',
    UCET_PRIHLASENI_ERROR_WRONG_CREDENTIALS: 'ucet.prihlaseni.error.WRONG_CREDENTIALS',
    UCET_PRIHLASENI_ERROR_CAPTCHA_REQUIRED: 'ucet.prihlaseni.error.CAPTCHA_REQUIRED',
    UCET_PRIHLASENI_ERROR_WRONG_CREDENTIALS_TOKEN: 'ucet.prihlaseni.error.WRONG_CREDENTIALS.token',
    UCET_PRIHLASENI_ERROR_WRONG_CREDENTIALS_CERT: 'ucet.prihlaseni.error.WRONG_CREDENTIALS_CERT',
    UCET_PRIHLASENI_ERROR_OTHER: 'ucet.prihlaseni.error.OTHER',
    UCET_PRIHLASENI_ERROR_LOGIN: 'ucet.prihlaseni.error.login',
    UCET_PRIHLASENI_ERROR_LOGIN_MOBILE: 'ucet.prihlaseni.error.login.mobile',
    UCET_PRIHLASENI_LIMITEDACCESS_TITLE: 'ucet.prihlaseni.limitedAccess.title',
    UCET_PRIHLASENI_LIMITEDACCESS_DESCRIPTION: 'ucet.prihlaseni.limitedAccess.description',
    UCET_PRIHLASENI_LIMITEDACCESS_NO: 'ucet.prihlaseni.limitedAccess.no',
    UCET_PRIHLASENI_LIMITEDACCESS_YES: 'ucet.prihlaseni.limitedAccess.yes',
    UCETCERTIFIKATVALIDACESTAV_PROBIHA: 'UcetCertifikatValidaceStav.PROBIHA',
    UCETCERTIFIKATVALIDACESTAV_CHYBA_VALIDACE: 'UcetCertifikatValidaceStav.CHYBA_VALIDACE',
    UCETCERTIFIKATVALIDACESTAV_ZVALIDOVANO: 'UcetCertifikatValidaceStav.ZVALIDOVANO',
    UCETCERTIFIKATVALIDACESTAV_CHYBA: 'UcetCertifikatValidaceStav.CHYBA',
    UCETCERTIFIKATVALIDACESTAV_VYPRSEL: 'UcetCertifikatValidaceStav.VYPRSEL',
    UCET_JMENO_A_PRIJMENI: 'ucet.jmeno.a.prijmeni',
    UCET_JMENO: 'ucet.jmeno',
    UCET_PRIJMENI: 'ucet.prijmeni',
    UCET_HESLO: 'ucet.heslo',
    UCET_HESLO_ZNOVU: 'ucet.heslo.znovu',
    UCET_ADMIN: 'ucet.admin',
    UCET_LOGIN: 'ucet.login',
    UCET_CESTNE_PROHLASENI: 'ucet.cestne.prohlaseni',
    UCET_AKTIVNI: 'ucet.aktivni',
    UCET_AKTIVNI_DESCRIPTION: 'ucet.aktivni.description',
    UCET_PODPIS_DODATKU: 'ucet.podpis.dodatku',
    UCET_MULTIFAKTOR_ADMIN_FORBIDDEN: 'ucet.multifaktor.admin.forbidden',
    UCET_MULTIFAKTOR: 'ucet.multifaktor',
    UCET_MULTIFAKTOR_MAD_TOOLTIP: 'ucet.multifaktor.mad.tooltip',
    UCET_NOTIFIKACE: 'ucet.notifikace',
    UCET_NOTIFIKACE_WARNING: 'ucet.notifikace.warning',
    UCET_MULTIFAKTOR_SMS: 'ucet.multifaktor.sms',
    UCET_MULTIFAKTOR_KOD_TEXT: 'ucet.multifaktor.kod.text',
    UCET_MULTIFAKTOR_KOD: 'ucet.multifaktor.kod',
    UCET_MULTIFAKTOR_KOD_ZNOVU_ZASLAT: 'ucet.multifaktor.kod.znovu.zaslat',
    UCET_MULTIFAKTOR_KOD_ZNOVU_SUCCESS: 'ucet.multifaktor.kod.znovu.success',
    UCET_MULTIFAKTOR_EMAIL: 'ucet.multifaktor.email',
    UCET_MULTIFAKTOR_POTVRDIT_HESLO: 'ucet.multifaktor.potvrdit.heslo',
    UCET_MULTIFAKTOR_POTVRDIT_KOD: 'ucet.multifaktor.potvrdit.kod',
    UCET_MULTIFAKTOR_VYPNUTA: 'ucet.multifaktor.vypnuta',
    UCET_MULTIFAKTOR_SUCCESS: 'ucet.multifaktor.success',
    UCET_PODPIS_DODATKU_DESCRIPTION: 'ucet.podpis.dodatku.description',
    UCET_PODPIS_SOP_ELEKTRINA: 'ucet.podpis.sop.elektrina',
    UCET_PODPIS_SOP_PLYN: 'ucet.podpis.sop.plyn',
    UCET_FUNKCE_PODPIS_SOP_ELEKTRINA: 'ucet.funkce.podpis.sop.elektrina',
    UCET_FUNKCE_PODPIS_SOP_PLYN: 'ucet.funkce.podpis.sop.plyn',
    UCET_SOUHLAS: 'ucet.souhlas',
    UCET_TELEFON: 'ucet.telefon',
    UCET_DATOVA_SCHRANKA: 'ucet.datova.schranka',
    UCET_EMAIL: 'ucet.email',
    UCET_MOCK_SAP: 'ucet.mock.sap',
    UCET_ROLE: 'ucet.role',
    UCET_CERTIFIKAT_CLEAR: 'ucet.certifikat.clear',
    UCET_CERTIFIKAT_ADD_FILEINPUT: 'ucet.certifikat.add.fileinput',
    UCET_CERTIFIKAT_FILEINPUT: 'ucet.certifikat.fileinput',
    UCET_CERTIFIKAT_SUBJEKT: 'ucet.certifikat.subjekt',
    UCET_CERTIFIKAT_VYDAVATEL: 'ucet.certifikat.vydavatel',
    UCET_CERTIFIKAT_PLATNOSTOD: 'ucet.certifikat.platnostOd',
    UCET_CERTIFIKAT_PLATNOSTDO: 'ucet.certifikat.platnostDo',
    UCET_CERTIFIKAT_STAV: 'ucet.certifikat.stav',
    UCET_CERTIFIKAT_NAZEVSOUBORU: 'ucet.certifikat.nazevSouboru',
    UCET_CERTIFIKAT_SERIOVECISLO: 'ucet.certifikat.serioveCislo',
    UCET_CERTIFIKAT_TYP: 'ucet.certifikat.typ',
    UCET_CERTIFIKAT_VERZE: 'ucet.certifikat.verze',
    UCET_CERTIFIKAT_VYTVORENO: 'ucet.certifikat.vytvoreno',
    UCET_CERTIFIKAT_VYTVORIL: 'ucet.certifikat.vytvoril',
    UCET_CERTIFIKAT_DEAKTIVOVANO: 'ucet.certifikat.deaktivovano',
    UCET_CERTIFIKAT_DEAKTIVOVAL: 'ucet.certifikat.deaktivoval',
    UCET_CERTIFIKAT_VAROVANI: 'ucet.certifikat.varovani',
    UCET_REGISTRACE_SOUHLASIM_DESCRIPTION: 'ucet.registrace.souhlasim.description',
    UCET_REGISTRACE_SOUHLASIM_DESCRIPTION_LINK: 'ucet.registrace.souhlasim.description.link',
    UCET_SKUPINA: 'ucet.skupina',
    UCET_SKUPINA_NAZEV: 'ucet.skupina.nazev',
    UCET_SKUPINA_TYP: 'ucet.skupina.typ',
    UCET_SKUPINA_TYP_UNDEFINED: 'ucet.skupina.typ.undefined',
    UCET_SKUPINA_TYP_KONCOVY_ZAKAZNIK_MUNICIPALITA: 'ucet.skupina.typ.KONCOVY_ZAKAZNIK_MUNICIPALITA',
    UCET_SKUPINA_LICENCE: 'ucet.skupina.licence',
    UCET_SKUPINA_ELEKTRINA: 'ucet.skupina.elektrina',
    UCET_SKUPINA_PLYN: 'ucet.skupina.plyn',
    UCET_SKUPINA_TYP_OBCHODNIK_REGISTRACE: 'ucet.skupina.typ.OBCHODNIK.registrace',
    UCET_SKUPINA_TYP_OBCHODNIK_DESCRIPTION: 'ucet.skupina.typ.OBCHODNIK.description',
    UCET_SKUPINA_TYP_OBCHODNIK_INFO: 'ucet.skupina.typ.OBCHODNIK.info',
    UCET_SKUPINA_TYP_OBCHODNIK_INFO_EMAIL: 'ucet.skupina.typ.OBCHODNIK.info.email',
    UCET_SKUPINA_TYP_MUNICIPALITA_REGISTRACE: 'ucet.skupina.typ.MUNICIPALITA.registrace',
    UCET_SKUPINA_TYP_MUNICIPALITA_DESCRIPTION: 'ucet.skupina.typ.MUNICIPALITA.description',
    UCET_SKUPINA_TYP_ZAKAZNIK_SE_SMLOUVOU_NAPRIMO_REGISTRACE: 'ucet.skupina.typ.ZAKAZNIK_SE_SMLOUVOU_NAPRIMO.registrace',
    UCET_SKUPINA_TYP_ZAKAZNIK_SE_SMLOUVOU_NAPRIMO_DESCRIPTION: 'ucet.skupina.typ.ZAKAZNIK_SE_SMLOUVOU_NAPRIMO.description',
    UCET_SKUPINA_TYP_KONCOVY_ZAKAZNIK_REGISTRACE: 'ucet.skupina.typ.KONCOVY_ZAKAZNIK.registrace',
    UCET_SKUPINA_TYP_KONCOVY_ZAKAZNIK_DESCRIPTION: 'ucet.skupina.typ.KONCOVY_ZAKAZNIK.description',
    UCET_SKUPINA_TYP_ZADATEL_REGISTRACE: 'ucet.skupina.typ.ZADATEL.registrace',
    UCET_SKUPINA_TYP_ZADATEL_ONLY_REGISTRACE: 'ucet.skupina.typ.ZADATEL.only.registrace',
    UCET_SKUPINA_TYP_ZADATEL_DESCRIPTION: 'ucet.skupina.typ.ZADATEL.description',
    UCET_SKUPINA_TYP_VYROBCE_PROVOZOVATEL_REGISTRACE: 'ucet.skupina.typ.VYROBCE_PROVOZOVATEL.registrace',
    UCET_SKUPINA_TYP_VYROBCE_PROVOZOVATEL_DESCRIPTION: 'ucet.skupina.typ.VYROBCE_PROVOZOVATEL.description',
    UCET_SKUPINA_TYP_DODAVATEL_REGISTRACE: 'ucet.skupina.typ.DODAVATEL.registrace',
    UCET_SKUPINA_TYP_DODAVATEL_DESCRIPTION: 'ucet.skupina.typ.DODAVATEL.description',
    UCET_SKUPINA_TYP_KOMUNIKACE: 'ucet.skupina.typ.komunikace',
    EAN: 'ean',
    EAN_ICO: 'ean.ico',
    EIC: 'eic',
    CISLO_ELEKTROMERU: 'cislo.elektromeru',
    CISLO_PLYNOMERU: 'cislo.plynomeru',
    DATUM_PODANI_OD: 'datum.podani.od',
    DATUM_PODANI_DO: 'datum.podani.do',
    KOMODITA_ELEKTRINA: 'komodita.elektrina',
    KOMODITA_PLYN: 'komodita.plyn',
    KOMODITA_VYBER: 'komodita.vyber',
    KOMODITA_SWITCH: 'komodita.switch',
    PRISTUP_VYBER: 'pristup.vyber',
    KOMODITA_ZADNA_TITLE: 'komodita.zadna.title',
    KOMODITA_ZADNA_DESCRIPTION: 'komodita.zadna.description',
    PRISTUP_ZADNY_DESCRIPTION: 'pristup.zadny.description',
    NEZNAME: 'nezname',
    ADRESA_ODBERNEHO_MISTA: 'adresa.odberneho.mista',
    ELEKTRINA_TYP_TARIFU: 'elektrina.typ.tarifu',
    PASSWORD_STRENGTH_TITLE: 'password.strength.title',
    UPOZORNENI: 'upozorneni',
    PASSWORD_STRENGTH_MESSAGE: 'password.strength.message',
    PASSWORD_STRENGTH_MESSAGE_TOOLTIP: 'password.strength.message.tooltip',
    LOGIN_ERROR: 'login.error',
    MUNICIPALITA_OVERIT_BUTTON: 'municipalita.overit.button',
    MUNICIPALITA_OVERIT_PLACEHOLDER: 'municipalita.overit.placeholder',
    MUNICIPALITA_OVERIT_TITLE: 'municipalita.overit.title',
    ESD_ALOKACE: 'esd.alokace',
    ESD_ALOKACE_LIMIT: 'esd.alokace.limit',
    ESD_DETAIL: 'esd.detail',
    ESD_ALOKACE_CELKEM: 'esd.alokace.celkem',
    ESD_ODESLANO: 'esd.odeslano',
    ESD: 'esd',
    ESD_ERROR_VALIDATE_VYROBNI_EAN: 'esd.error.validate.vyrobni.ean',
    ESD_ERROR_VALIDATE_SPOTREBNI_EAN: 'esd.error.validate.spotrebni.ean',
    ESD_ERROR_VALIDATE_IDENTIFIKATORY: 'esd.error.validate.identifikatory',
    ESD_NEODESLANO: 'esd.neodeslano',
    ESD_UDAJE: 'esd.udaje',
    ESD_UDAJE_ADRESA: 'esd.udaje.adresa',
    ESD_UDAJE_ZALOZENI: 'esd.udaje.zalozeni',
    ESD_UDAJE_OMS_PRIDRUZENA: 'esd.udaje.oms.pridruzena',
    ESD_UDAJE_OMS_PRIDRUZENA_WARNING: 'esd.udaje.oms.pridruzena.warning',
    ESD_JMENO: 'esd.jmeno',
    ESD_PRIJMENI: 'esd.prijmeni',
    ESD_DATUM_NAROZENI: 'esd.datum.narozeni',
    ESD_NAZEV: 'esd.nazev',
    ESD_IC: 'esd.ic',
    ESD_OVERENI_LOADING: 'esd.overeni.loading',
    ESD_SOUHLAS_CHECKBOX: 'esd.souhlas.checkbox',
    ESD_SOUHLAS: 'esd.souhlas',
    ESD_DIC: 'esd.dic',
    ESD_CREATE_SUCCESS: 'esd.create.success',
    ESD_UPDATE_SUCCESS: 'esd.update.success',
    ESD_DELETE_SUCCESS: 'esd.delete.success',
    ESD_SEND_ERROR: 'esd.send.error',
    ESD_EMAIL: 'esd.email',
    ESD_DETAIL_OM: 'esd.detail.om',
    ESD_DETAIL_OM_VUDCI_OM: 'esd.detail.om.vudci.om',
    ESD_EMAIL_KONTAKTNI: 'esd.email.kontaktni',
    ESD_EMAIL_KONTAKTNI_PODRUZNE: 'esd.email.kontaktni.podruzne',
    ESD_EMAIL_KONTAKTNI_TOOLTIP: 'esd.email.kontaktni.tooltip',
    ESD_TELEFON: 'esd.telefon',
    ESD_TELEFON_KONTAKTNI: 'esd.telefon.kontaktni',
    ESD_TELEFON_KONTAKTNI_PODRUZNE: 'esd.telefon.kontaktni.podruzne',
    ESD_TELEFON_KONTAKTNI_TOOLTIP: 'esd.telefon.kontaktni.tooltip',
    ESD_JEDNAJICI: 'esd.jednajici',
    ESD_IC_DATUM_NAROZENI: 'esd.ic.datum.narozeni',
    ESD_IC_DATUM_NAROZENI_ZALOZENI: 'esd.ic.datum.narozeni.zalozeni',
    ESD_IC_DATUM_NAROZENI_MOBILE: 'esd.ic.datum.narozeni.mobile',
    ESD_IC_DATUM_NAROZENI_ZALOZENI_MOBILE: 'esd.ic.datum.narozeni.zalozeni.mobile',
    ESD_IC_DATUM_NAROZENI_TOOLTIP: 'esd.ic.datum.narozeni.tooltip',
    ESD_OBCHODNI_PARTNER: 'esd.obchodni.partner',
    ESD_EAN_VUDCI_OM: 'esd.ean.vudci.om',
    ESD_EAN_PRIDRUZENE_OM: 'esd.ean.pridruzene.om',
    ESD_EAN_PRIDRUZENE_OM_JMENO: 'esd.ean.pridruzene.om.jmeno',
    ESD_EAN_PRIDRUZENE_OM_JMENO_TOOLTIP: 'esd.ean.pridruzene.om.jmeno.tooltip',
    ESD_EAN_PRIDRUZENE_OM_ALOKACE: 'esd.ean.pridruzene.om.alokace',
    ESD_EAN_PRIDRUZENE_OM_ALOKACE_TOOLTIP: 'esd.ean.pridruzene.om.alokace.tooltip',
    ESD_EAN_PRIDRUZENE_OM_TOOLTIP: 'esd.ean.pridruzene.om.tooltip',
    ESD_EAN_VUDCI_OM_TOOLTIP: 'esd.ean.vudci.om.tooltip',
    ESD_ADRESA_BYDLISTE: 'esd.adresa.bydliste',
    ESD_ADRESA_FAKTURACNI: 'esd.adresa.fakturacni',
    ESD_ADRESA_KORESPONDENCNI: 'esd.adresa.korespondencni',
    ESD_ADRESA_KORESPONDENCNI_TOOLTIP: 'esd.adresa.korespondencni.tooltip',
    ESD_ADRESA_FAKTURACNI_TOOLTIP: 'esd.adresa.fakturacni.tooltip',
    ESD_ADRESA_UMISTENI: 'esd.adresa.umisteni',
    ESD_DISTRIBUCNI_SAZBA: 'esd.distribucni.sazba',
    ESD_DISTRIBUCNI_SAZBA_TEXT: 'esd.distribucni.sazba.text',
    ESD_TERMIN_ZALOZENI: 'esd.termin.zalozeni',
    ESD_TERMIN_ZALOZENI_TOOLTIP: 'esd.termin.zalozeni.tooltip',
    ESD_ZALOZENI_VLOZIT_DESCRIPTION: 'esd.zalozeni.vlozit.description',
    ESD_ZALOZENI_UPRAVIT_DESCRIPTION: 'esd.zalozeni.upravit.description',
    ESD_ZALOZENI_ZRUSIT_DESCRIPTION: 'esd.zalozeni.zrusit.description',
    ESD_BUTTON_VLOZIT: 'esd.button.vlozit',
    ESD_BUTTON_MANUAL: 'esd.button.manual',
    ESD_PREHLED_ZADOSTI: 'esd.prehled.zadosti',
    ESD_SPOLECENSTVI: 'esd.spolecenstvi',
    ESD_SPOLECENSTVI_DETAIL: 'esd.spolecenstvi.detail',
    ESD_ZAKLADNI_INFORMACE_TITLE: 'esd.zakladni.informace.title',
    ESD_CISLO_SMLOUVY: 'esd.cislo.smlouvy',
    ESD_CISLO_ZADOSTI: 'esd.cislo.zadosti',
    ESD_DATUM_ODESLANI: 'esd.datum.odeslani',
    ESD_POZADOVANE_DATUM_ZMENY: 'esd.pozadovane.datum.zmeny',
    ESD_ODESILATEL: 'esd.odesilatel',
    ESD_STAV: 'esd.stav',
    ESD_POZNAMKA: 'esd.poznamka',
    ESD_PRVNI_STAZENI_KYM: 'esd.prvni.stazeni.kym',
    ESD_PRVNI_STAZENI_KDY: 'esd.prvni.stazeni.kdy',
    ESD_DUVOD: 'esd.duvod',
    ESD_PRILOHY: 'esd.prilohy',
    ESD_PRILOHY_VZOR: 'esd.prilohy.vzor',
    ESD_PRILOHY_TYP: 'esd.prilohy.typ',
    ESD_KONTAKTNI_UDAJE: 'esd.kontaktni.udaje',
    ESD_KONTAKTNI_UDAJE_MOBILE: 'esd.kontaktni.udaje.mobile',
    ESD_VUDCI_OM: 'esd.vudci.om',
    ESD_ZAKAZNIK: 'esd.zakaznik',
    ESD_ADRESA: 'esd.adresa',
    ESD_ADRESA_SPOLECENSTVI: 'esd.adresa.spolecenstvi',
    ESD_DATUM_ZALOZENI: 'esd.datum.zalozeni',
    ESD_DATUM_ZALOZENI_SPOLECENSTVI: 'esd.datum.zalozeni.spolecenstvi',
    ESD_DATUM_ZALOZENI_SPOLECENSTVI_BEZ_DATA: 'esd.datum.zalozeni.spolecenstvi.bez.data',
    ESD_DATUM_UPRAVY: 'esd.datum.upravy',
    ESD_DATUM_ZRUSENI: 'esd.datum.zruseni',
    ESD_DATUM_POSLEDNI_ZMENY: 'esd.datum.posledni.zmeny',
    ESD_POSLEDNI_ZADATEL: 'esd.posledni.zadatel',
    ESD_ZDE: 'esd.zde',
    ESD_PROHLASENI: 'esd.prohlaseni',
    ESD_PRIDAT_ERROR: 'esd.pridat.error',
    ESD_BUTTON_ZALOZIT: 'esd.button.zalozit',
    ESD_BUTTON_UPRAVIT: 'esd.button.upravit',
    ESD_BUTTON_ZRUSIT: 'esd.button.zrusit',
    ESD_BUTTON_PRIDAT: 'esd.button.pridat',
    ESD_DESCRIPTION: 'esd.description',
    ESD_ZAKLADNI_INFORMACE: 'esd.zakladni.informace',
    ROZCESTNIK: 'rozcestnik',
    ROZCESTNIK_ERROR: 'rozcestnik.error',
    ROZCESTNIK_DESCRIPTION: 'rozcestnik.description',
    ROZCESTNIK_ZADNY: 'rozcestnik.zadny',
    SPRAVA_UCTU: 'sprava.uctu',
    SPRAVA_UCTU_OPEN_API_FORM_EDIT_TOOLTIP: 'sprava.uctu.open.api.form.edit.tooltip',
    SPRAVA_UCTU_OPEN_API_VYGENEROVANY_TOKEN_UPOZORNENI: 'sprava.uctu.open.api.vygenerovany.token.upozorneni',
    SPRAVA_UCTU_OPEN_API_VYGENEROVANY_TOKEN: 'sprava.uctu.open.api.vygenerovany.token',
    SPRAVA_UCTU_OPEN_API_SCHRANKA: 'sprava.uctu.open.api.schranka',
    SPRAVA_UCTU_OPEN_API_SCHRANKA_DONE: 'sprava.uctu.open.api.schranka.done',
    SPRAVA_UCTU_OPEN_API_CELKOVY_PRISTUPY: 'sprava.uctu.open.api.celkovy.pristupy',
    SPRAVA_UCTU_OPEN_API_TOOLTIP: 'sprava.uctu.open.api.tooltip',
    SPRAVA_UCTU_OPEN_API_ZAKAZNIK: 'sprava.uctu.open.api.zakaznik',
    SPRAVA_UCTU_OPEN_API_REGISTR: 'sprava.uctu.open.api.registr',
    SPRAVA_UCTU_OPEN_API_FORM_PLATNOST: 'sprava.uctu.open.api.form.platnost',
    SPRAVA_UCTU_OPEN_API_FORM_PLATNOST_1: 'sprava.uctu.open.api.form.platnost.1',
    SPRAVA_UCTU_OPEN_API_FORM_PLATNOST_3: 'sprava.uctu.open.api.form.platnost.3',
    SPRAVA_UCTU_OPEN_API_FORM_PLATNOST_10: 'sprava.uctu.open.api.form.platnost.10',
    SPRAVA_UCTU_OPEN_API_FORM_EMAIL: 'sprava.uctu.open.api.form.email',
    SPRAVA_UCTU_OPEN_API_FORM_UPOZORNENI: 'sprava.uctu.open.api.form.upozorneni',
    SPRAVA_UCTU_OPEN_API_FORM_UPOZORNENI_1: 'sprava.uctu.open.api.form.upozorneni.1',
    SPRAVA_UCTU_OPEN_API_FORM_UPOZORNENI_2: 'sprava.uctu.open.api.form.upozorneni.2',
    SPRAVA_UCTU_OPEN_API_FORM_UPOZORNENI_3: 'sprava.uctu.open.api.form.upozorneni.3',
    SPRAVA_UCTU_OPEN_API_FORM_UPOZORNENI_TOOLTIP: 'sprava.uctu.open.api.form.upozorneni.tooltip',
    SPRAVA_UCTU_OPEN_API_FORM_UPOZORNENI_TOOLTIP_2: 'sprava.uctu.open.api.form.upozorneni.tooltip.2',
    SPRAVA_UCTU_OPEN_API: 'sprava.uctu.open.api',
    SPRAVA_UCTU_OPEN_API_DETAIL_TITLE: 'sprava.uctu.open.api.detail.title',
    SPRAVA_UCTU_OPEN_API_DETAIL_LOG_VYUZITI: 'sprava.uctu.open.api.detail.log.vyuziti',
    SPRAVA_UCTU_OPEN_API_DETAIL_LOG_VYUZITI_VYGENEROVANI_ACCESS_TOKENU: 'sprava.uctu.open.api.detail.log.vyuziti.VYGENEROVANI_ACCESS_TOKENU',
    SPRAVA_UCTU_OPEN_API_DETAIL_LOG_VYUZITI_STAHOVANI_DAT: 'sprava.uctu.open.api.detail.log.vyuziti.STAHOVANI_DAT',
    SPRAVA_UCTU_OPEN_API_DETAIL_LOG_DATUM: 'sprava.uctu.open.api.detail.log.datum',
    SPRAVA_UCTU_OPEN_API_DETAIL_LOG_VOLANI: 'sprava.uctu.open.api.detail.log.volani',
    SPRAVA_UCTU_OPEN_API_DETAIL_LOG_VOLANI_USPECH: 'sprava.uctu.open.api.detail.log.volani.USPECH',
    SPRAVA_UCTU_OPEN_API_DETAIL_LOG_VOLANI_NEUSPECH: 'sprava.uctu.open.api.detail.log.volani.NEUSPECH',
    SPRAVA_UCTU_OPEN_API_DETAIL_LOG_CHYBA: 'sprava.uctu.open.api.detail.log.chyba',
    SPRAVA_UCTU_OPEN_API_DETAIL_INFO: 'sprava.uctu.open.api.detail.info',
    SPRAVA_UCTU_OPEN_API_DETAIL_VSECHNA_OM: 'sprava.uctu.open.api.detail.vsechna.om',
    SPRAVA_UCTU_OPEN_API_DETAIL_VSECHNA_OM_ELEKTRINA: 'sprava.uctu.open.api.detail.vsechna.om.elektrina',
    SPRAVA_UCTU_OPEN_API_DETAIL_VSECHNA_OM_PLYN: 'sprava.uctu.open.api.detail.vsechna.om.plyn',
    SPRAVA_UCTU_OPEN_API_DETAIL_VYBRANA_OM: 'sprava.uctu.open.api.detail.vybrana.om',
    SPRAVA_UCTU_OPEN_API_DETAIL_VYBRANA_OM_ELEKTRINA: 'sprava.uctu.open.api.detail.vybrana.om.elektrina',
    SPRAVA_UCTU_OPEN_API_DETAIL_VYBRANA_OM_PLYN: 'sprava.uctu.open.api.detail.vybrana.om.plyn',
    SPRAVA_UCTU_OPEN_API_DETAIL_NOTIFIKACE: 'sprava.uctu.open.api.detail.notifikace',
    SPRAVA_UCTU_OPEN_API_DETAIL_ZAZNAMY: 'sprava.uctu.open.api.detail.zaznamy',
    SPRAVA_UCTU_OPEN_API_DETAIL_NOTIFIKACE_TEXTACE: 'sprava.uctu.open.api.detail.notifikace.textace',
    SPRAVA_UCTU_OPEN_API_DETAIL_PLATNOST: 'sprava.uctu.open.api.detail.platnost',
    SPRAVA_UCTU_OPEN_API_PREHLED: 'sprava.uctu.open.api.prehled',
    SPRAVA_UCTU_OPEN_API_NAZEV: 'sprava.uctu.open.api.nazev',
    SPRAVA_UCTU_OPEN_API_UZIVATEL: 'sprava.uctu.open.api.uzivatel',
    SPRAVA_UCTU_OPEN_API_VYTVORENO: 'sprava.uctu.open.api.vytvoreno',
    SPRAVA_UCTU_OPEN_API_PLATNOST: 'sprava.uctu.open.api.platnost',
    SPRAVA_UCTU_OPEN_API_AKTIVNI: 'sprava.uctu.open.api.aktivni',
    SPRAVA_UCTU_OPEN_API_TEST: 'sprava.uctu.open.api.test',
    SPRAVA_UCTU_OPEN_API_TEST_TOOLTIP: 'sprava.uctu.open.api.test.tooltip',
    SPRAVA_UCTU_OPEN_API_NAVOD_BUTTON: 'sprava.uctu.open.api.navod.button',
    SPRAVA_UCTU_OPEN_API_PLACEHOLDER: 'sprava.uctu.open.api.placeholder',
    SPRAVA_UCTU_OPEN_API_BUTTON: 'sprava.uctu.open.api.button',
    SPRAVA_UCTU_OPEN_API_TECH_POPIS_TITLE: 'sprava.uctu.open.api.tech.popis.title',
    SPRAVA_UCTU_OPEN_API_TECH_POPIS: 'sprava.uctu.open.api.tech.popis',
    SPRAVA_UCTU_OPEN_API_ODKAZ: 'sprava.uctu.open.api.odkaz',
    SPRAVA_UCTU_OPEN_API_INFO: 'sprava.uctu.open.api.info',
    SPRAVA_UCTU_OPEN_API_CLIENT_ID: 'sprava.uctu.open.api.client.id',
    SPRAVA_UCTU_OPEN_API_CLIENT_SECRET: 'sprava.uctu.open.api.client.secret',
    SPRAVA_UCTU_OPEN_API_POPIS: 'sprava.uctu.open.api.popis',
    SPRAVA_UCTU_OM: 'sprava.uctu.om',
    SPRAVA_UCTU_OM_ADRESA: 'sprava.uctu.om.adresa',
    SPRAVA_UCTU_OM_POVINNE: 'sprava.uctu.om.povinne',
    SPRAVA_UCTU_OM_VYBER: 'sprava.uctu.om.vyber',
    SPRAVA_UCTU_OM_FILTER_TITLE: 'sprava.uctu.om.filter.title',
    SPRAVA_UCTU_OM_ELEKTRINA: 'sprava.uctu.om.elektrina',
    SPRAVA_UCTU_OM_PLYN: 'sprava.uctu.om.plyn',
    SPRAVA_UCTU_OM_EAN_VYROBNI: 'sprava.uctu.om.ean.vyrobni',
    SPRAVA_UCTU_PRISTUP_DEACTIVATED: 'sprava.uctu.pristup.deactivated',
    SPRAVA_UCTU_PRISTUP_DELETED: 'sprava.uctu.pristup.deleted',
    SPRAVA_UCTU_PRISTUP_CREATED: 'sprava.uctu.pristup.created',
    SPRAVA_UCTU_PRISTUP_UPDATED: 'sprava.uctu.pristup.updated',
    SPRAVA_UCTU_ENERGETIK_TITLE: 'sprava.uctu.energetik.title',
    SPRAVA_UCTU_DETAIL_PRISTUP: 'sprava.uctu.detail.pristup',
    SPRAVA_UCTU_MESTO: 'sprava.uctu.mesto',
    SPRAVA_UCTU_ULICE: 'sprava.uctu.ulice',
    SPRAVA_UCTU_CISLOPOPISNE: 'sprava.uctu.cisloPopisne',
    SPRAVA_UCTU_CISLOORIENTACNI: 'sprava.uctu.cisloOrientacni',
    SPRAVA_UCTU_PSC: 'sprava.uctu.psc',
    SPRAVA_UCTU_MISTNICAST: 'sprava.uctu.mistniCast',
    SPRAVA_UCTU_OM_ELEKTRINA_VSE: 'sprava.uctu.om.elektrina.vse',
    SPRAVA_UCTU_OM_ELEKTRINA_OPENAPI_VSE: 'sprava.uctu.om.elektrina.openapi.vse',
    SPRAVA_UCTU_OM_VSE_TOOLTIP: 'sprava.uctu.om.vse.tooltip',
    SPRAVA_UCTU_OM_PLYN_VSE: 'sprava.uctu.om.plyn.vse',
    SPRAVA_UCTU_OM_PLYN_OPENAPI_VSE: 'sprava.uctu.om.plyn.openapi.vse',
    SPRAVA_UCTU_PRISTUPY_ENERGETIKA: 'sprava.uctu.pristupy.energetika',
    SPRAVA_UCTU_PRISTUP_ENERGETIKA: 'sprava.uctu.pristup.energetika',
    SPRAVA_UCTU_PRISTUPY_ENERGETIKA_BUTTON: 'sprava.uctu.pristupy.energetika.button',
    SPRAVA_UCTU_PRISTUPY_ENERGETIKA_BUTTON_TOOLTIP: 'sprava.uctu.pristupy.energetika.button.tooltip',
    SPRAVA_UCTU_PRISTUPY_NEW: 'sprava.uctu.pristupy.new',
    SPRAVA_UCTU_PRISTUPY_UPDATE: 'sprava.uctu.pristupy.update',
    SPRAVA_UCTU_CONFIRM_RESET: 'sprava.uctu.confirm.reset',
    SPRAVA_UCTU_CONFIRM_ACTIVATE: 'sprava.uctu.confirm.activate',
    SPRAVA_UCTU_PRISTUP_CONFIRM_ACTIVATE: 'sprava.uctu.pristup.confirm.activate',
    SPRAVA_UCTU_CONFIRM_UPDATE: 'sprava.uctu.confirm.update',
    SPRAVA_UCTU_CONFIRM_DEACTIVATE: 'sprava.uctu.confirm.deactivate',
    SPRAVA_UCTU_PRISTUP_CONFIRM_DEACTIVATE: 'sprava.uctu.pristup.confirm.deactivate',
    SPRAVA_UCTU_PRISTUP_CONFIRM_DELETE: 'sprava.uctu.pristup.confirm.delete',
    SPRAVA_UCTU_UCTY: 'sprava.uctu.ucty',
    SPRAVA_UCTU_UCTY_BIOMETRICS: 'sprava.uctu.ucty.biometrics',
    SPRAVA_UCTU_UCTY_BIOMETRICS_ENABLED: 'sprava.uctu.ucty.biometrics.enabled',
    SPRAVA_UCTU_UCTY_BIOMETRICS_DISABLED: 'sprava.uctu.ucty.biometrics.disabled',
    SPRAVA_UCTU_NOVY_UCET_BUTTON: 'sprava.uctu.novy.ucet.button',
    SPRAVA_UCTU_NOVY_UCET: 'sprava.uctu.novy.ucet',
    SPRAVA_UCTU_DETAIL: 'sprava.uctu.detail',
    SPRAVA_UCTU_EDITACE: 'sprava.uctu.editace',
    SPRAVA_UCTU_UZIVATEL_NEVYTVOREN: 'sprava.uctu.uzivatel.nevytvoren',
    SPRAVA_UCTU_EMAIL: 'sprava.uctu.email',
    SPRAVA_UCTU_TELEFON: 'sprava.uctu.telefon',
    SPRAVA_UCTU_PRIJMENI_JMENO: 'sprava.uctu.prijmeni.jmeno',
    SPRAVA_UCTU_JMENO: 'sprava.uctu.jmeno',
    SPRAVA_UCTU_PRIJMENI: 'sprava.uctu.prijmeni',
    SPRAVA_UCTU_AKTIVNI: 'sprava.uctu.aktivni',
    SPRAVA_UCTU_ADMIN: 'sprava.uctu.admin',
    SPRAVA_UCTU_TAB_PROFIL: 'sprava.uctu.tab.profil',
    SPRAVA_UCTU_TAB_CERTIFIKATY: 'sprava.uctu.tab.certifikaty',
    SPRAVA_UCTU_CERTIFIKATY: 'sprava.uctu.certifikaty',
    SPRAVA_UCTU_CERTIFIKATY_TITLE: 'sprava.uctu.certifikaty.title',
    SPRAVA_UCTU_CERTIFIKATY_ONLYACTIVE: 'sprava.uctu.certifikaty.onlyActive',
    SPRAVA_UCTU_CERTIFIKATY_MENU_DETAIL: 'sprava.uctu.certifikaty.menu.detail',
    SPRAVA_UCTU_CERTIFIKATY_MENU_DEACTIVATE: 'sprava.uctu.certifikaty.menu.deactivate',
    SPRAVA_UCTU_CERTIFIKATY_MENU_DOWNLOAD: 'sprava.uctu.certifikaty.menu.download',
    SPRAVA_UCTU_CERTIFIKATY_DEACTIVATE_CONFIRM: 'sprava.uctu.certifikaty.deactivate.confirm',
    SPRAVA_UCTU_CERTIFIKATY_DEACTIVATED: 'sprava.uctu.certifikaty.deactivated',
    SPRAVA_UCTU_CERTIFIKATY_BUTTON_ADD: 'sprava.uctu.certifikaty.button.add',
    SPRAVA_UCTU_CERTIFIKATY_ADD_TITLE: 'sprava.uctu.certifikaty.add.title',
    SPRAVA_UCTU_CERTIFIKATY_ADD_FILEINPUT: 'sprava.uctu.certifikaty.add.fileInput',
    SPRAVA_UCTU_CERTIFIKATY_ADD_READERROR: 'sprava.uctu.certifikaty.add.readError',
    SPRAVA_UCTU_CERTIFIKATY_ADD_ADDED: 'sprava.uctu.certifikaty.add.added',
    SPRAVA_UCTU_CERTIFIKATY_ADD_ADD_ONE: 'sprava.uctu.certifikaty.add.add.one',
    SPRAVA_UCTU_CERTIFIKATY_ADD_TOO_BIG: 'sprava.uctu.certifikaty.add.too.big',
    SPRAVA_UCTU_CERTIFIKATY_ADD_ERROR: 'sprava.uctu.certifikaty.add.error',
    SPRAVA_UCTU_CERTIFIKATY_ADD_CHECKING: 'sprava.uctu.certifikaty.add.checking',
    SPRAVA_UCTU_CERTIFIKATY_ADD_CREATING: 'sprava.uctu.certifikaty.add.creating',
    SPRAVA_UCTU_CERTIFIKATY_ADD_CHECKTITLE: 'sprava.uctu.certifikaty.add.checkTitle',
    SPRAVA_UCTU_CERTIFIKATY_WARNING: 'sprava.uctu.certifikaty.warning',
    SPRAVA_UCTU_CERTIFIKATY_STATE_ACTIVE: 'sprava.uctu.certifikaty.state.active',
    SPRAVA_UCTU_CERTIFIKATY_STATE_CANCELED: 'sprava.uctu.certifikaty.state.canceled',
    SPRAVA_UCTU_CERTIFIKATY_STATE_EXPIRED: 'sprava.uctu.certifikaty.state.expired',
    SPRAVA_UCTU_CERTIFIKATY_CLEAR: 'sprava.uctu.certifikaty.clear',
    SPRAVA_UCTU_CERTIFIKATY_ZADNEAKTIVNI: 'sprava.uctu.certifikaty.zadneAktivni',
    SPRAVA_UCTU_CERTIFIKATY_ZADNE: 'sprava.uctu.certifikaty.zadne',
    SPRAVA_UCTU_CERTIFIKATY_DETAIL: 'sprava.uctu.certifikaty.detail',
    SPRAVA_UCTU_CERTIFIKATY_DETAIL_INFORMACE: 'sprava.uctu.certifikaty.detail.informace',
    SPRAVA_UCTU_CERTIFIKATY_DETAIL_LIDE: 'sprava.uctu.certifikaty.detail.lide',
    SPRAVA_UCTU_MENU_EDIT: 'sprava.uctu.menu.edit',
    SPRAVA_UCTU_MENU_DETAIL: 'sprava.uctu.menu.detail',
    SPRAVA_UCTU_MENU_ACTIVATE: 'sprava.uctu.menu.activate',
    SPRAVA_UCTU_MENU_DELETE: 'sprava.uctu.menu.delete',
    SPRAVA_UCTU_MENU_DEACTIVATE: 'sprava.uctu.menu.deactivate',
    SPRAVA_UCTU_MENU_RESETPASSWORD: 'sprava.uctu.menu.resetPassword',
    SPRAVA_UCTU_ADDMENU: 'sprava.uctu.addmenu',
    SPRAVA_UCTU_TYP_ZMOCNENEC: 'sprava.uctu.typ.zmocnenec',
    SPRAVA_UCTU_TYP_SPRAVCE: 'sprava.uctu.typ.spravce',
    SPRAVA_UCTU_TYP_ZADATEL: 'sprava.uctu.typ.zadatel',
    SPRAVA_UCTU_TYP_PRISTUP: 'sprava.uctu.typ.pristup',
    SPRAVA_UCTU_TYP_PRISTUP_TOOLTIP_SPRAVCE: 'sprava.uctu.typ.pristup.tooltip.spravce',
    SPRAVA_UCTU_TYP_PRISTUP_TOOLTIP_UZIVATEL: 'sprava.uctu.typ.pristup.tooltip.uzivatel',
    SPRAVA_UCTU_VAZBA: 'sprava.uctu.vazba',
    SPRAVA_UCTU_ADD_ROZSIRENI_UCTU: 'sprava.uctu.add.rozsireni.uctu',
    SPRAVA_UCTU_ADD_ROZSIRENI_UCTU_TOOLTIP: 'sprava.uctu.add.rozsireni.uctu.tooltip',
    SPRAVA_UCTU_ADD_ROZSIRENI_UCTU_DESCRIPTION: 'sprava.uctu.add.rozsireni.uctu.description',
    KONTAKTY: 'kontakty',
    KONTAKTY_NASTENKA: 'kontakty.nastenka',
    KONTAKTY_NASTENKA_TEXT: 'kontakty.nastenka.text',
    KONTAKTY_WARNING_EAN: 'kontakty.warning.ean',
    KONTAKTY_ZVALIDOVAT: 'kontakty.zvalidovat',
    KONTAKTY_ZVALIDOVAT_TITLE: 'kontakty.zvalidovat.title',
    KONTAKTY_K_ZVALIDOVANI: 'kontakty.k.zvalidovani',
    KONTAKTY_VYTVOREN: 'kontakty.vytvoren',
    KONTAKTY_PRIDAT: 'kontakty.pridat',
    KONTAKTY_SMAZAT: 'kontakty.smazat',
    KONTAKTY_DRUH_KOMUNIKACE: 'kontakty.druh.komunikace',
    KONTAKTY_NO_CATEGORIES_TITLE: 'kontakty.no.categories.title',
    KONTAKTY_NO_CATEGORIES_CONTENT: 'kontakty.no.categories.content',
    KONTAKTY_ODSTRANIT: 'kontakty.odstranit',
    KONTAKTY_ULICE: 'kontakty.ulice',
    KONTAKTY_CISLO_POPISNE: 'kontakty.cislo.popisne',
    KONTAKTY_CISLO_ORIENTACNI: 'kontakty.cislo.orientacni',
    KONTAKTY_MESTO: 'kontakty.mesto',
    KONTAKTY_MISTNI_CAST: 'kontakty.mistni.cast',
    KONTAKTY_PSC: 'kontakty.psc',
    KONTAKTY_LTR_VAROVANI: 'kontakty.ltr.varovani',
    KONTAKTY_WITH_EANOREIC: 'kontakty.with.eanOrEic',
    KONTAKTY_WITHOUT_EANOREIC: 'kontakty.without.eanOrEic',
    SESTAVY_TAB_PRILOHY_KE_STAZENI: 'sestavy.tab.PRILOHY_KE_STAZENI',
    SESTAVY_TAB_SESTAVY_KE_STAZENI: 'sestavy.tab.SESTAVY_KE_STAZENI',
    SESTAVY_TAB_MONTAZNI_LISTY: 'sestavy.tab.MONTAZNI_LISTY',
    SESTAVY_TAB_DANOVE_DOKLADY: 'sestavy.tab.DANOVE_DOKLADY',
    SESTAVY_TAB_TECHNICKE_DETAILY_OM: 'sestavy.tab.TECHNICKE_DETAILY_OM',
    SESTAVY_PRILOHY_KE_STAZENI: 'sestavy.prilohy.ke.stazeni',
    SESTAVY_PRILOHY_KE_STAZENI_KYM: 'sestavy.prilohy.ke.stazeni.kym',
    SESTAVY_PRILOHY_KE_STAZENI_POPRVE_KYM: 'sestavy.prilohy.ke.stazeni.poprve.kym',
    SESTAVY_PRILOHY_KE_STAZENI_KDY: 'sestavy.prilohy.ke.stazeni.kdy',
    SESTAVY_PRILOHY_KE_STAZENI_POPRVE_KDY: 'sestavy.prilohy.ke.stazeni.poprve.kdy',
    SESTAVY_PRILOHY_KE_STAZENI_STAZEN: 'sestavy.prilohy.ke.stazeni.stazen',
    SESTAVY_PRILOHY_KE_STAZENI_DANOVE_DOKLADY_EMPTY: 'sestavy.prilohy.ke.stazeni.danove.doklady.empty',
    SESTAVY_PRILOHY_KE_STAZENI_VELKA_PRILOHA_PRIPRAVENA: 'sestavy.prilohy.ke.stazeni.velka.priloha.pripravena',
    SESTAVY_PRILOHY_KE_STAZENI_DESCRIPTION: 'sestavy.prilohy.ke.stazeni.description',
    SESTAVY_SESTAVY_KE_STAZENI: 'sestavy.sestavy.ke.stazeni',
    SESTAVY_MONTAZNI_LISTY: 'sestavy.montazni.listy',
    SESTAVY_DANOVE_DOKLADY_KE_STAZENI: 'sestavy.danove.doklady.ke.stazeni',
    SESTAVY_DANOVE_DOKLADY_PRED_SPLATNOSTI: 'sestavy.danove.doklady.pred.splatnosti',
    SESTAVY_DANOVE_DOKLADY_PO_SPLATNOSTI: 'sestavy.danove.doklady.po.splatnosti',
    SESTAVY_DANOVE_DOKLADY_TRI_DNY_PO_SPLATNOSTI: 'sestavy.danove.doklady.tri.dny.po.splatnosti',
    SESTAVY_PRILOHY_KE_STAZENI_TYP: 'sestavy.prilohy.ke.stazeni.typ',
    SESTAVY_PRILOHY_KE_STAZENI_TYP_VSE: 'sestavy.prilohy.ke.stazeni.typ.VSE',
    SESTAVY_PRILOHY_KE_STAZENI_TYP_ZRS0_MOO: 'sestavy.prilohy.ke.stazeni.typ.ZRS0_MOO',
    SESTAVY_PRILOHY_KE_STAZENI_TYP_ZRS0_MOP: 'sestavy.prilohy.ke.stazeni.typ.ZRS0_MOP',
    SESTAVY_PRILOHY_KE_STAZENI_TYP_ZRS0_VO: 'sestavy.prilohy.ke.stazeni.typ.ZRS0_VO',
    SESTAVY_PRILOHY_KE_STAZENI_TYP_ZRS0_ZP: 'sestavy.prilohy.ke.stazeni.typ.ZRS0_ZP',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_DATUM_OD: 'sestavy.prilohy.ke.stazeni.filtration.datum.od',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_DATUM_VYSTAVENI_OD: 'sestavy.prilohy.ke.stazeni.filtration.datum.vystaveni.od',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_DATUM_DO: 'sestavy.prilohy.ke.stazeni.filtration.datum.do',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_DATUM_VYSTAVENI_DO: 'sestavy.prilohy.ke.stazeni.filtration.datum.vystaveni.do',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_CISLO: 'sestavy.prilohy.ke.stazeni.filtration.cislo',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_STAV: 'sestavy.prilohy.ke.stazeni.filtration.stav',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_STAV_VSE: 'sestavy.prilohy.ke.stazeni.filtration.stav.VSE',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_STAV_CEKA_NA_ZAPLACENI: 'sestavy.prilohy.ke.stazeni.filtration.stav.CEKA_NA_ZAPLACENI',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_STAV_CEKA_NA_ZAPLACENI_PO_SPLATNOSTI: 'sestavy.prilohy.ke.stazeni.filtration.stav.CEKA_NA_ZAPLACENI_PO_SPLATNOSTI',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_STAV_CEKA_NA_ZAPLACENI_PRED_SPLATNOSTI: 'sestavy.prilohy.ke.stazeni.filtration.stav.CEKA_NA_ZAPLACENI_PRED_SPLATNOSTI',
    SESTAVY_PRILOHY_KE_STAZENI_FILTRATION_STAV_UHRAZENO: 'sestavy.prilohy.ke.stazeni.filtration.stav.UHRAZENO',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_ZFA_DDPP: 'sestavy.danovy.doklad.ke.stazeni.typ.ZFA_DDPP',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_ZFA_DDPPOP: 'sestavy.danovy.doklad.ke.stazeni.typ.ZFA_DDPPOP',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_ZFA_AF: 'sestavy.danovy.doklad.ke.stazeni.typ.ZFA_AF',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_ZFA_AST: 'sestavy.danovy.doklad.ke.stazeni.typ.ZFA_AST',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_ZFA_AZAL: 'sestavy.danovy.doklad.ke.stazeni.typ.ZFA_AZAL',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_ZFA_AOF: 'sestavy.danovy.doklad.ke.stazeni.typ.ZFA_AOF',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_ZFA_DDS: 'sestavy.danovy.doklad.ke.stazeni.typ.ZFA_DDS',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_ZFA_FOP: 'sestavy.danovy.doklad.ke.stazeni.typ.ZFA_FOP',
    SESTAVY_DANOVY_DOKLAD_KE_STAZENI_TYP_VSE: 'sestavy.danovy.doklad.ke.stazeni.typ.VSE',
    SESTAVY_DANOVY_DOKLAD_RYCHLY_PREHLED: 'sestavy.danovy.doklad.rychly.prehled',
    SESTAVY_DANOVY_DOKLAD_WIDGET_UPOZORNENI: 'sestavy.danovy.doklad.widget.upozorneni',
    SESTAVY_DANOVY_DOKLAD_WIDGET_UPOZORNENI_CASTKA: 'sestavy.danovy.doklad.widget.upozorneni.castka',
    SESTAVY_DANOVY_DOKLAD_WIDGET_UPOZORNENI_PREJIT: 'sestavy.danovy.doklad.widget.upozorneni.prejit',
    SESTAVY_MONTAZNI_LISTY_TYP_MKM_MONTAZKA: 'sestavy.montazni.listy.typ.MKM_MONTAZKA',
    SESTAVY_MONTAZNI_LISTY_TYP_MKM_KLZ: 'sestavy.montazni.listy.typ.MKM_KLZ',
    SESTAVY_MONTAZNI_LISTY_ZADNY_DOKLAD: 'sestavy.montazni.listy.zadny.doklad',
    SESTAVY_LEFT_MENU_DANOVE_DOKLADY: 'sestavy.left.menu.danove.doklady',
    SESTAVY_LEFT_MENU_SESTAVY_KE_STAZENI: 'sestavy.left.menu.sestavy.ke.stazeni',
    SESTAVY_LEFT_MENU_MONTAZNI_LISTY: 'sestavy.left.menu.montazni.listy',
    SESTAVY_ERROR_MESSAGE: 'sestavy.error.message',
    SESTAVY_PRILOHY_KE_STAZENI_DATUMUCINNOSTI: 'sestavy.prilohy.ke.stazeni.datumUcinnosti',
    SESTAVY_PRILOHY_KE_STAZENI_VARIABILNISYMBOL: 'sestavy.prilohy.ke.stazeni.variabilniSymbol',
    SESTAVY_PRILOHY_KE_STAZENI_KUHRADE: 'sestavy.prilohy.ke.stazeni.kUhrade',
    SESTAVY_PRILOHY_KE_STAZENI_UHRAZENO: 'sestavy.prilohy.ke.stazeni.uhrazeno',
    SESTAVY_PRILOHY_KE_STAZENI_CISLO: 'sestavy.prilohy.ke.stazeni.cislo',
    SESTAVY_PRILOHY_KE_STAZENI_CISLODOKLADU: 'sestavy.prilohy.ke.stazeni.cisloDokladu',
    SESTAVY_PRILOHY_KE_STAZENI_TYPDOKLADU: 'sestavy.prilohy.ke.stazeni.typDokladu',
    SESTAVY_PRILOHY_KE_STAZENI_CASTKAKUHRADE: 'sestavy.prilohy.ke.stazeni.castkaKUhrade',
    SESTAVY_PRILOHY_KE_STAZENI_IKONYPROSTAZENI: 'sestavy.prilohy.ke.stazeni.ikonyProStazeni',
    SESTAVY_PRILOHY_KE_STAZENI_UPOMINKY: 'sestavy.prilohy.ke.stazeni.upominky',
    SESTAVY_PRILOHY_KE_STAZENI_STAVDOKLADU: 'sestavy.prilohy.ke.stazeni.stavDokladu',
    SESTAVY_PRILOHY_KE_STAZENI_UHRAZENACASTKA: 'sestavy.prilohy.ke.stazeni.uhrazenaCastka',
    SESTAVY_PRILOHY_KE_STAZENI_TERMINSPLATNOSTI: 'sestavy.prilohy.ke.stazeni.terminSplatnosti',
    SESTAVY_PRILOHY_KE_STAZENI_SPLATKOVYKALENDAR: 'sestavy.prilohy.ke.stazeni.splatkovyKalendar',
    SESTAVY_PRILOHY_KE_STAZENI_DATUMVYSTAVENI: 'sestavy.prilohy.ke.stazeni.datumVystaveni',
    SESTAVY_PRILOHY_KE_STAZENI_DUZP: 'sestavy.prilohy.ke.stazeni.duzp',
    SESTAVY_PRILOHY_KE_STAZENI_EAN: 'sestavy.prilohy.ke.stazeni.ean',
    SESTAVY_PRILOHY_KE_STAZENI_EIC: 'sestavy.prilohy.ke.stazeni.eic',
    SESTAVY_PRILOHY_KE_STAZENI_TERMINPROVEDENISZ: 'sestavy.prilohy.ke.stazeni.terminProvedeniSz',
    SESTAVY_PRILOHY_KE_STAZENI_NAZEV: 'sestavy.prilohy.ke.stazeni.nazev',
    SESTAVY_PRILOHY_KE_STAZENI_MIME: 'sestavy.prilohy.ke.stazeni.mime',
    SESTAVY_PRILOHY_KE_STAZENI_STAV: 'sestavy.prilohy.ke.stazeni.stav',
    SESTAVY_PRILOHY_KE_STAZENI_VELKA_PRILOHA: 'sestavy.prilohy.ke.stazeni.velka.priloha',
    SESTAVY_PRILOHY_KE_STAZENI_VELKA_PRILOHA_TOOLTIP: 'sestavy.prilohy.ke.stazeni.velka.priloha.tooltip',
    SESTAVY_PRILOHY_KE_STAZENI_VELIKOST: 'sestavy.prilohy.ke.stazeni.velikost',
    SESTAVY_PRILOHY_KE_STAZENI_CISLOZAKAZKY: 'sestavy.prilohy.ke.stazeni.cisloZakazky',
    SESTAVY_TECHNICKE_DETAILY_OM: 'sestavy.technicke.detaily.om',
    SESTAVY_TECHNICKE_DETAILY_OM_JEDNOTLIVE_SMLOUVA: 'sestavy.technicke.detaily.om.jednotlive.smlouva',
    SESTAVY_TECHNICKE_DETAILY_OM_JEDNOTLIVE_PRISTROJE: 'sestavy.technicke.detaily.om.jednotlive.pristroje',
    SESTAVY_TECHNICKE_DETAILY_OM_IMPORT: 'sestavy.technicke.detaily.om.import',
    SESTAVY_TECHNICKE_DETAILY_OM_CEKEJTE: 'sestavy.technicke.detaily.om.cekejte',
    SESTAVY_TECHNICKE_DETAILY_OM_TITLE: 'sestavy.technicke.detaily.om.title',
    SESTAVY_TECHNICKE_DETAILY_OM_CISLO: 'sestavy.technicke.detaily.om.cislo',
    SESTAVY_TECHNICKE_DETAILY_OM_NAZEV: 'sestavy.technicke.detaily.om.nazev',
    SESTAVY_TECHNICKE_DETAILY_OM_VYTVORIL: 'sestavy.technicke.detaily.om.vytvoril',
    SESTAVY_TECHNICKE_DETAILY_OM_KE_STAZENI: 'sestavy.technicke.detaily.om.ke.stazeni',
    SESTAVY_TECHNICKE_DETAILY_OM_STAHNOUT: 'sestavy.technicke.detaily.om.stahnout',
    SESTAVY_TECHNICKE_DETAILY_OM_JEDNOTLIVE: 'sestavy.technicke.detaily.om.jednotlive',
    SESTAVY_TECHNICKE_DETAILY_OM_JEDNOTLIVE_POPIS: 'sestavy.technicke.detaily.om.jednotlive.popis',
    SESTAVY_TECHNICKE_DETAILY_OM_HROMADNE: 'sestavy.technicke.detaily.om.hromadne',
    SESTAVY_TECHNICKE_DETAILY_OM_HROMADNE_POPIS: 'sestavy.technicke.detaily.om.hromadne.popis',
    SESTAVY_TECHNICKE_DETAILY_OM_DATUM_OD: 'sestavy.technicke.detaily.om.datum.od',
    SESTAVY_TECHNICKE_DETAILY_OM_DATUM_OD_PRISTROJ: 'sestavy.technicke.detaily.om.datum.od.pristroj',
    SESTAVY_TECHNICKE_DETAILY_OM_DATUM_DO: 'sestavy.technicke.detaily.om.datum.do',
    SESTAVY_TECHNICKE_DETAILY_OM_DATUM_DO_PRISTROJ: 'sestavy.technicke.detaily.om.datum.do.pristroj',
    SESTAVY_TECHNICKE_DETAILY_OM_CREATE_SESTAVA: 'sestavy.technicke.detaily.om.create.sestava',
    SESTAVY_TECHNICKE_DETAILY_IMPORT_DIALOG_TITLE: 'sestavy.technicke.detaily.import.dialog.title',
    HROMADNY_IMPORT_DELETE: 'hromadny.import.delete',
    HROMADNY_IMPORT_DELETE_CHYBNE: 'hromadny.import.delete.chybne',
    ODBERNA_MISTA_TITLE: 'odberna.mista.title',
    ODBERNA_MISTA_NASTENKA: 'odberna.mista.nastenka',
    ODBERNA_MISTA_VSE: 'odberna.mista.vse',
    ODBERNA_MISTA_NAZEV: 'odberna.mista.nazev',
    ODBERNA_MISTA_NAZEV_TOOLTIP: 'odberna.mista.nazev.tooltip',
    ODBERNA_MISTA_NAZEV_UPDATE: 'odberna.mista.nazev.update',
    ODBERNA_MISTA_NAZEV_UPDATE_DONE: 'odberna.mista.nazev.update.done',
    ODBERNA_MISTA_MAP: 'odberna.mista.map',
    ODBERNA_MISTA_OBCE_MAP: 'odberna.mista.obce.map',
    ODBERNA_MISTA_TYP_DIAGRAMU: 'odberna.mista.typ.diagramu',
    ODBERNA_MISTA_SMLUVNI_OBDOBI: 'odberna.mista.smluvni.obdobi',
    ODBERNA_MISTA_SMLUVNI_OBDOBI_OD: 'odberna.mista.smluvni.obdobi.od',
    ODBERNA_MISTA_SMLUVNI_OBDOBI_DO: 'odberna.mista.smluvni.obdobi.do',
    ODBERNA_MISTA_ZAKAZNIK: 'odberna.mista.zakaznik',
    ODBERNA_MISTA_OBEC: 'odberna.mista.obec',
    ODBERNA_MISTA_TYP_MERENI: 'odberna.mista.typ.mereni',
    ODBERNA_MISTA_STAV_SMLOUVY: 'odberna.mista.stav.smlouvy',
    ODBERNA_MISTA_HISTORIE_SPOTREB_TYP_SPOTREBY: 'odberna.mista.historie.spotreb.typ.spotreby',
    ODBERNA_MISTA_HISTORIE_SPOTREB_TYP_DODAVKY: 'odberna.mista.historie.spotreb.typ.dodavky',
    ODBERNA_MISTA_CISLO_ELEKTROMERU_MATERIAL: 'odberna.mista.cislo.elektromeru.material',
    ODBERNA_MISTA_CISLO_ELEKTROMERU_SOUCTOVY_PROFIL_TEXT: 'odberna.mista.cislo.elektromeru.souctovy.profil.text',
    ODBERNA_MISTA_CISLO_ELEKTROMERU_SOUCTOVY_PROFIL_TOOLTIP: 'odberna.mista.cislo.elektromeru.souctovy.profil.tooltip',
    ODBERNA_MISTA_CISLO_ELEKTROMERU_SOUCTOVY_PROFIL_EAN: 'odberna.mista.cislo.elektromeru.souctovy.profil.ean',
    ODBERNA_MISTA_CISLO_PLYNOMERU_MATERIAL: 'odberna.mista.cislo.plynomeru.material',
    ODBERNA_MISTA_CHARAKTER_ODBERU_VARENI: 'odberna.mista.charakter.odberu.VARENI',
    ODBERNA_MISTA_CHARAKTER_ODBERU_TUV: 'odberna.mista.charakter.odberu.TUV',
    ODBERNA_MISTA_CHARAKTER_ODBERU_VYTAPENI: 'odberna.mista.charakter.odberu.VYTAPENI',
    ODBERNA_MISTA_CHARAKTER_ODBERU_TECHNOLOGIE: 'odberna.mista.charakter.odberu.TECHNOLOGIE',
    ODBERNA_MISTA_NEBYLO_DOHLEDANO: 'odberna.mista.nebylo.dohledano',
    ODBERNA_MISTA_SEZNAM_SMLUV: 'odberna.mista.seznam.smluv',
    ODBERNA_MISTA_PODANI_ODECTU: 'odberna.mista.podani.odectu',
    ODBERNA_MISTA_PODANI_REKLAMACE: 'odberna.mista.podani.reklamace',
    ODBERNA_MISTA_SPALNE_TEPLO: 'odberna.mista.spalne.teplo',
    ODBERNA_MISTA_PREHLED_ZPET: 'odberna.mista.prehled.zpet',
    ODBERNA_MISTA_KAPACITA: 'odberna.mista.kapacita',
    ODBERNA_MISTA_MNOZSTVI: 'odberna.mista.mnozstvi',
    ODBERNA_MISTA_SMART_MERENI: 'odberna.mista.smart.mereni',
    ODBERNA_MISTA_SMLOUVA: 'odberna.mista.smlouva',
    ODBERNA_MISTA_MESICNI: 'odberna.mista.mesicni',
    ODBERNA_MISTA_KLOUZAVA: 'odberna.mista.klouzava',
    ODBERNA_MISTA_DOBA_NEURCITA: 'odberna.mista.doba.neurcita',
    ODBERNA_MISTA_ROCNI: 'odberna.mista.rocni',
    ODBERNA_MISTA_DATUM_OD: 'odberna.mista.datum.od',
    ODBERNA_MISTA_DATUM_DO: 'odberna.mista.datum.do',
    ODBERNA_MISTA_HODNOTA: 'odberna.mista.hodnota',
    ODBERNA_MISTA_REGULACNI_STUPNE: 'odberna.mista.regulacni.stupne',
    ODBERNA_MISTA_JMENO: 'odberna.mista.jmeno',
    ODBERNA_MISTA_PRIJMENI: 'odberna.mista.prijmeni',
    ODBERNA_MISTA_TELEFON: 'odberna.mista.telefon',
    ODBERNA_MISTA_EMAIL: 'odberna.mista.email',
    ODBERNA_MISTA_SJEDNAT_OSOBA: 'odberna.mista.sjednat.osoba',
    ODBERNA_MISTA_TECHNICKE_UDAJE: 'odberna.mista.technicke.udaje',
    ODBERNA_MISTA_NAPETOVA_UROVEN: 'odberna.mista.napetova.uroven',
    ODBERNA_MISTA_REZERVOVANY_VYKON: 'odberna.mista.rezervovany.vykon',
    ODBERNA_MISTA_REZERVOVANY_PRIKON: 'odberna.mista.rezervovany.prikon',
    ODBERNA_MISTA_KAPACITA_ROCNI: 'odberna.mista.kapacita.rocni',
    ODBERNA_MISTA_KAPACITA_MESICNI: 'odberna.mista.kapacita.mesicni',
    ODBERNA_MISTA_HODNOTA_JISTICE: 'odberna.mista.hodnota.jistice',
    ODBERNA_MISTA_POCET_FAZI: 'odberna.mista.pocet.fazi',
    ODBERNA_MISTA_ODECET_FREKVENCE: 'odberna.mista.odecet.frekvence',
    ODBERNA_MISTA_OBDOBI_ODECTU: 'odberna.mista.obdobi.odectu',
    ODBERNA_MISTA_POCET_FAZI_VYROBNA: 'odberna.mista.pocet.fazi.vyrobna',
    ODBERNA_MISTA_HDO_TOU: 'odberna.mista.hdo.tou',
    ODBERNA_MISTA_HDO_NULL: 'odberna.mista.hdo.null',
    ODBERNA_MISTA_HDO_TOOLTIP_TITLE: 'odberna.mista.hdo.tooltip.title',
    ODBERNA_MISTA_HDO_TOOLTIP_TITLE_SHORT: 'odberna.mista.hdo.tooltip.title.short',
    ODBERNA_MISTA_HDO_TOOLTIP_MESSAGE: 'odberna.mista.hdo.tooltip.message',
    ODBERNA_MISTA_DISTRIBUCNI_SADA: 'odberna.mista.distribucni.sada',
    ODBERNA_MISTA_TDD: 'odberna.mista.tdd',
    ODBERNA_MISTA_ODBERNE_MISTO: 'odberna.mista.odberne.misto',
    ODBERNA_MISTA_TYP_OM: 'odberna.mista.typ.om',
    ODBERNA_MISTA_STAV_OM: 'odberna.mista.stav.om',
    ODBERNA_MISTA_TYP: 'odberna.mista.typ',
    ODBERNA_MISTA_HODNOTA_M3: 'odberna.mista.hodnota.m3',
    ODBERNA_MISTA_SMLUVNI_UDAJE: 'odberna.mista.smluvni.udaje',
    ODBERNA_MISTA_KAPACITA_MESICNI_M3: 'odberna.mista.kapacita.mesicni.m3',
    ODBERNA_MISTA_KAPACITA_KLOUZAVA_M3: 'odberna.mista.kapacita.klouzava.m3',
    ODBERNA_MISTA_SMLUVNI_ROCNI_SPOTREBA: 'odberna.mista.smluvni.rocni.spotreba',
    ODBERNA_MISTA_SMLUVNI_ROCNI_SPOTREBA_MWH: 'odberna.mista.smluvni.rocni.spotreba.mwh',
    ODBERNA_MISTA_PREPOCTENA_ROCNI_SPOTREBA: 'odberna.mista.prepoctena.rocni.spotreba',
    ODBERNA_MISTA_MESICNI_MNOZSTVI_M3: 'odberna.mista.mesicni.mnozstvi.m3',
    ODBERNA_MISTA_SKUPINA_STAV_NOUZE: 'odberna.mista.skupina.stav.nouze',
    ODBERNA_MISTA_PLAN_ROCNI_SPOTREBA: 'odberna.mista.plan.rocni.spotreba',
    ODBERNA_MISTA_VYUZITI_OM: 'odberna.mista.vyuziti.om',
    ODBERNA_MISTA_CHARAKTER_ODBERU: 'odberna.mista.charakter.odberu',
    ODBERNA_MISTA_CISLO: 'odberna.mista.cislo',
    ODBERNA_MISTA_MATERIAL: 'odberna.mista.material',
    ODBERNA_MISTA_HDO: 'odberna.mista.hdo',
    ODBERNA_MISTA_HDO_TOOLTIP: 'odberna.mista.hdo.tooltip',
    ODBERNA_MISTA_OD: 'odberna.mista.od',
    ODBERNA_MISTA_DO: 'odberna.mista.do',
    ODBERNA_MISTA_HISTORIE: 'odberna.mista.historie',
    ODBERNA_MISTA_HISTORIE_ODECTU: 'odberna.mista.historie.odectu',
    ODBERNA_MISTA_PRISTROJE: 'odberna.mista.pristroje',
    ODBERNA_MISTA_AKTIVNI_SMLOUVA: 'odberna.mista.aktivni.smlouva',
    ODBERNA_MISTA_NEAKTIVNI_SMLOUVA: 'odberna.mista.neaktivni.smlouva',
    ODBERNA_MISTA_BUDOUCI_SMLOUVA: 'odberna.mista.budouci.smlouva',
    ODBERNA_MISTA_SMLOUVY_NENALEZENY: 'odberna.mista.smlouvy.nenalezeny',
    ODBERNA_MISTA_SIDLO: 'odberna.mista.sidlo',
    ODBERNA_MISTA_BYDLISTE: 'odberna.mista.bydliste',
    ODBERNA_MISTA_ELEKTROMER: 'odberna.mista.elektromer',
    ODBERNA_MISTA_TABULKA: 'odberna.mista.tabulka',
    ODBERNA_MISTA_GRAF: 'odberna.mista.graf',
    ODBERNA_MISTA_GRAF_CELKOVA_SPOTREBA: 'odberna.mista.graf.celkova.spotreba',
    ODBERNA_MISTA_GRAF_CELKOVA_SPOTREBA_TITLE: 'odberna.mista.graf.celkova.spotreba.title',
    ODBERNA_MISTA_GRAF_PRUMERNA_SPOTREBA: 'odberna.mista.graf.prumerna.spotreba',
    ODBERNA_MISTA_GRAF_PRUMERNA_SPOTREBA_TITLE: 'odberna.mista.graf.prumerna.spotreba.title',
    ODBERNA_MISTA_HISTORIE_SPOTREB_NAPOVEDA: 'odberna.mista.historie.spotreb.napoveda',
    ODBERNA_MISTA_HISTORIE_SPOTREB_CELKEM: 'odberna.mista.historie.spotreb.celkem',
    ODBERNA_MISTA_HISTORIE_SPOTREB_CELKEM_UNIT: 'odberna.mista.historie.spotreb.celkem.unit',
    ODBERNA_MISTA_HISTORIE_SPOTREB_MIN: 'odberna.mista.historie.spotreb.min',
    ODBERNA_MISTA_HISTORIE_SPOTREB_MAX: 'odberna.mista.historie.spotreb.max',
    ODBERNA_MISTA_HISTORIE_SPOTREB_WARNING: 'odberna.mista.historie.spotreb.warning',
    ODBERNA_MISTA_DATUM: 'odberna.mista.datum',
    ODBERNA_MISTA_STAV_VT: 'odberna.mista.stav.vt',
    ODBERNA_MISTA_SPOTREBA_VT: 'odberna.mista.spotreba.vt',
    ODBERNA_MISTA_KOEFICIENT: 'odberna.mista.koeficient',
    ODBERNA_MISTA_DUVOD_ODECTU: 'odberna.mista.duvod.odectu',
    ODBERNA_MISTA_TYP_DIAGRAM: 'odberna.mista.typ.diagram',
    ODBERNA_MISTA_STAV_M3: 'odberna.mista.stav.m3',
    ODBERNA_MISTA_SPOTREBA_M3: 'odberna.mista.spotreba.m3',
    ODBERNA_MISTA_KOMBINACE_DATA_NENALEZENA: 'odberna.mista.kombinace.data.nenalezena',
    ODBERNA_MISTA_ADRESA_ODBERNEHO_MISTA: 'odberna.mista.adresa.odberneho.mista',
    ODBERNA_MISTA_HLAVNI: 'odberna.mista.hlavni',
    ODBERNA_MISTA_PLYN_PREDPOKLADANA_SPOTREBA: 'odberna.mista.plyn.predpokladana.spotreba',
    ODBERNA_MISTA_PLYN_STAV_NOUZE1: 'odberna.mista.plyn.stav.nouze1',
    ODBERNA_MISTA_PLYN_STAV_NOUZE2: 'odberna.mista.plyn.stav.nouze2',
    ODBERNA_MISTA_SMLOUVA_CISLO_PLATNE_SMLOUVY: 'odberna.mista.smlouva.cislo.platne.smlouvy',
    ODBERNA_MISTA_SMLOUVA_CISLO_ROZPRACOVANE_SMLOUVY: 'odberna.mista.smlouva.cislo.rozpracovane.smlouvy',
    ODBERNA_MISTA_SMLOUVA_CISLO_ROZPRACOVANE_VICE: 'odberna.mista.smlouva.cislo.rozpracovane.vice',
    ODBERNA_MISTA_SMLOUVA_PRIPOJENI_PLATNOST_OD_DO: 'odberna.mista.smlouva.pripojeni.platnost.od.do',
    ODBERNA_MISTA_SMLOUVA_PRIPOJENI: 'odberna.mista.smlouva.pripojeni',
    ODBERNA_MISTA_SMLOUVA_PRIPOJENI_NO_DMSID: 'odberna.mista.smlouva.pripojeni.no.dmsid',
    ODBERNA_MISTA_SMLOUVA_PRIPOJENI_NO_ACTIVE: 'odberna.mista.smlouva.pripojeni.no.active',
    ODBERNA_MISTA_KONTAKTY_OZNAMOVANI: 'odberna.mista.kontakty.oznamovani',
    ODBERNA_MISTA_STAV_NT_KWH: 'odberna.mista.stav.nt.kwh',
    ODBERNA_MISTA_SPOTREBA_NT_KWH: 'odberna.mista.spotreba.nt.kwh',
    ODBERNA_MISTA_VYBERTE_TYP_SPOTREBA_DODAVKA: 'odberna.mista.vyberte.typ.spotreba.dodavka',
    ODBERNA_MISTA_PLYNOMER: 'odberna.mista.plynomer',
    ODBERNA_MISTA_MENU_DETAIL: 'odberna.mista.menu.detail',
    ODBERNA_MISTA_DETAIL: 'odberna.mista.detail',
    ODBERNA_MISTA_STAV: 'odberna.mista.stav',
    ODBERNA_MISTA_STATISTIKA: 'odberna.mista.statistika',
    ODBERNA_MISTA_STATISTIKA_RADKY_STRANKA: 'odberna.mista.statistika.radky.stranka',
    ODBERNA_MISTA_STATISTIKA_PREDCHOZI: 'odberna.mista.statistika.predchozi',
    ODBERNA_MISTA_STATISTIKA_DALSI: 'odberna.mista.statistika.dalsi',
    ODBERNA_MISTA_STATISTIKA_CELKEM: 'odberna.mista.statistika.celkem',
    ODBERNA_MISTA_STATISTIKA_UCINIK: 'odberna.mista.statistika.ucinik',
    ODBERNA_MISTA_STATISTIKA_TOOLTIP: 'odberna.mista.statistika.tooltip',
    ODBERNA_MISTA_ZAKAZNIK_IC: 'odberna.mista.zakaznik.ic',
    ODBERNA_MISTA_ZAKAZNIK_DATUM_NAROZENI: 'odberna.mista.zakaznik.datum.narozeni',
    ODBERNA_MISTA_ZAKAZNIK_NAZEV_FIRMY: 'odberna.mista.zakaznik.nazev.firmy',
    ODBERNA_MISTA_ZAKAZNIK_JMENO_PRIJMENI: 'odberna.mista.zakaznik.jmeno.prijmeni',
    ODBERNA_MISTA_ZAKAZNIK_RC: 'odberna.mista.zakaznik.rc',
    ODBERNA_MISTA_VYGENEROVAT_REPORT_BUTTON: 'odberna.mista.vygenerovat.report.button',
    ODBERNA_MISTA_VYGENEROVAT_REPORT_BUTTON_TOOLTIP: 'odberna.mista.vygenerovat.report.button.tooltip',
    ODBERNA_MISTA_VYGENEROVAT_REPORT_DATRUM_DO_AKTUALNI_MESIC: 'odberna.mista.vygenerovat.report.datrum.do.aktualni.mesic',
    ODBERNA_MISTA_VYGENEROVAT_REPORT_DATRUM_DO_MAX_ONE_YEAR: 'odberna.mista.vygenerovat.report.datrum.do.max.one.year',
    ODBERNA_MISTA_REPORT_TYP_TITLE: 'odberna.mista.report.typ.title',
    ODBERNA_MISTA_REPORT_DATA_Z_MERENI: 'odberna.mista.report.data.z.mereni',
    ODBERNA_MISTA_REPORT_DATA_Z_MERENI_TOOLTIP: 'odberna.mista.report.data.z.mereni.tooltip',
    ODBERNA_MISTA_REPORT_OSTATNI_TECHNICKA_DATA: 'odberna.mista.report.ostatni.technicka.data',
    ODBERNA_MISTA_REPORT_OSTATNI_MERENI_DATA_TOOLTIP: 'odberna.mista.report.ostatni.mereni.data.tooltip',
    ODBERNA_MISTA_REPORT_OSTATNI_TECHNICKA_DATA_TOOLTIP: 'odberna.mista.report.ostatni.technicka.data.tooltip',
    ODBERNA_MISTA_REPORT_ROZSAH_MESICU: 'odberna.mista.report.rozsah.mesicu',
    ODBERNA_MISTA_REPORT_VSECHNA_ODBERNA_MISTA: 'odberna.mista.report.vsechna.odberna.mista',
    ODBERNA_MISTA_REPORT_TYP_MERENI_NULL: 'odberna.mista.report.typ.mereni.null',
    ODBERNA_MISTA_REPORT_TYP_MERENI_A: 'odberna.mista.report.typ.mereni.A',
    ODBERNA_MISTA_REPORT_TYP_MERENI_B: 'odberna.mista.report.typ.mereni.B',
    ODBERNA_MISTA_REPORT_ROZSAH_MESICU_OD: 'odberna.mista.report.rozsah.mesicu.od',
    ODBERNA_MISTA_REPORT_ROZSAH_MESICU_DO: 'odberna.mista.report.rozsah.mesicu.do',
    ODBERNA_MISTA_REPORT_VYBER_OM_ERROR: 'odberna.mista.report.vyber.om.error',
    ODBERNA_MISTA_REPORT_EMAIL: 'odberna.mista.report.email',
    ODBERNA_MISTA_REPORT_SUCCESS: 'odberna.mista.report.success',
    VSECHNA_ODBERNA_MISTA_CHECKBOX: 'vsechna.odberna.mista.checkbox',
    VSECHNA_ODBERNA_HDO_TITLE: 'vsechna.odberna.hdo.title',
    VSECHNA_ODBERNA_HDO_AKTUALNI_OBDOBI: 'vsechna.odberna.hdo.aktualni.obdobi',
    VSECHNA_ODBERNA_HDO_NASLEDUJICI_OBDOBI: 'vsechna.odberna.hdo.nasledujici.obdobi',
    VSECHNA_ODBERNA_HDO_PRACOVNI_DNY: 'vsechna.odberna.hdo.pracovni.dny',
    VSECHNA_ODBERNA_HDO_CELY_TYDEN: 'vsechna.odberna.hdo.cely.tyden',
    VSECHNA_ODBERNA_HDO_VIKEND: 'vsechna.odberna.hdo.vikend',
    VSECHNA_ODBERNA_HDO_VIKEND_ONLY: 'vsechna.odberna.hdo.vikend.only',
    VSECHNA_ODBERNA_HDO_PATEK: 'vsechna.odberna.hdo.patek',
    VSECHNA_ODBERNA_HDO_SOBOTA: 'vsechna.odberna.hdo.sobota',
    VSECHNA_ODBERNA_HDO_NEDELE: 'vsechna.odberna.hdo.nedele',
    ODBERNA_MISTA_HISTORIE_SPOTREB_TIMESTAMP: 'odberna.mista.historie.spotreb.timestamp',
    ODBERNA_MISTA_HISTORIE_SPOTREB_AFTER_JULY: 'odberna.mista.historie.spotreb.after.july',
    ODBERNA_MISTA_GRAF_TOOLTIP: 'odberna.mista.graf.tooltip',
    ODBERNA_MISTA_KATEGORIE_MERENI_ERROR: 'odberna.mista.kategorie.mereni.error',
    ODBERNA_MISTA_KATEGORIE_MERENI_CHECKBOX: 'odberna.mista.kategorie.mereni.checkbox',
    ODBERNA_MISTA_KATEGORIE_MERENI_CHECKBOX_PLATNA: 'odberna.mista.kategorie.mereni.checkbox.platna',
    ODBERNA_MISTA_KATEGORIE_MERENI_CHECKBOX_DOPOCTENA: 'odberna.mista.kategorie.mereni.checkbox.dopoctena',
    ODBERNA_MISTA_KATEGORIE_MERENI_CHECKBOX_MANUALNI: 'odberna.mista.kategorie.mereni.checkbox.manualni',
    ODBERNA_MISTA_KATEGORIE_MERENI_CHECKBOX_TOOLTIP: 'odberna.mista.kategorie.mereni.checkbox.tooltip',
    ODBERNA_MISTA_STAV_AKTIVNI: 'odberna.mista.stav.aktivni',
    ODBERNA_MISTA_STAV_NEAKTIVNI: 'odberna.mista.stav.neaktivni',
    ODBERNA_MISTA_STAV_BUDOUCI: 'odberna.mista.stav.budouci',
    ODBERNA_MISTA_STAV_VSE: 'odberna.mista.stav.vse',
    ODBERNA_MISTA_REGULACNI_STUPEN_3PCT: 'odberna.mista.regulacni.stupen.3pct',
    ODBERNA_MISTA_REGULACNI_STUPEN_4PCT: 'odberna.mista.regulacni.stupen.4pct',
    ODBERNA_MISTA_REGULACNI_STUPEN_5PCT: 'odberna.mista.regulacni.stupen.5pct',
    ODBERNA_MISTA_REGULACNI_STUPEN_6PCT: 'odberna.mista.regulacni.stupen.6pct',
    ODBERNA_MISTA_REGULACNI_STUPEN_7KW: 'odberna.mista.regulacni.stupen.7kw',
    ODBERNA_MISTA_REGULACNI_STUPEN_7HOD: 'odberna.mista.regulacni.stupen.7hod',
    ODBERNA_MISTA_WARNING: 'odberna.mista.warning',
    ODBERNA_MISTA_WARNING_SMLOUVA_JINY: 'odberna.mista.warning.smlouva.jiny',
    ODBERNA_MISTA_WARNING_FUTURE: 'odberna.mista.warning.future',
    ODBERNA_MISTA_MAX_YEARS: 'odberna.mista.max.years',
    ODBERNA_MISTA_OBCHODNIK: 'odberna.mista.obchodnik',
    ODBERNA_MISTA_VYROBNA: 'odberna.mista.vyrobna',
    ODBERNA_MISTA_VYROBNA_EAN_VYROBNI: 'odberna.mista.vyrobna.ean.vyrobni',
    ODBERNA_MISTA_EAN: 'odberna.mista.ean',
    ODBERNA_MISTA_VYROBNA_KATEGORIE_VYROBCE: 'odberna.mista.vyrobna.kategorie.vyrobce',
    ODBERNA_MISTA_VYROBNA_TYP_ZDROJE: 'odberna.mista.vyrobna.typ.zdroje',
    ODBERNA_MISTA_TYPY_PROFILU_NAMERENA: 'odberna.mista.typy.profilu.namerena',
    ODBERNA_MISTA_TYPY_PROFILU_FAKTURACNI: 'odberna.mista.typy.profilu.fakturacni',
    ODBERNA_MISTA_SMART_MERENI_STATUS_ODECTU_F: 'odberna.mista.smart.mereni.status.odectu.F',
    ODBERNA_MISTA_SMART_MERENI_STATUS_ODECTU_G: 'odberna.mista.smart.mereni.status.odectu.G',
    ODBERNA_MISTA_SMART_MERENI_STATUS_ODECTU_N: 'odberna.mista.smart.mereni.status.odectu.N',
    ODBERNA_MISTA_SMART_MERENI_STATUS_ODECTU_E: 'odberna.mista.smart.mereni.status.odectu.E',
    ODBERNA_MISTA_SMART_MERENI_STATUS_ODECTU_M: 'odberna.mista.smart.mereni.status.odectu.M',
    ODBERNA_MISTA_SMART_MERENI_STATUS_ODECTU_V: 'odberna.mista.smart.mereni.status.odectu.V',
    ODBERNA_MISTA_SMART_MERENI_STATUS_ODECTU_B: 'odberna.mista.smart.mereni.status.odectu.B',
    ODBERNA_MISTA_SMART_MERENI_STATUS_ODECTU_W: 'odberna.mista.smart.mereni.status.odectu.W',
    ODBERNA_MISTA_SMART_MERENI_STAV_REGISTRU_HINT: 'odberna.mista.smart.mereni.stav.registru.hint',
    ODBERNA_MISTA_SMART_MERENI_COL_DATUM: 'odberna.mista.smart.mereni.col.datum',
    ODBERNA_MISTA_SMART_MERENI_COL_CAS: 'odberna.mista.smart.mereni.col.cas',
    ODBERNA_MISTA_SMART_MERENI_COL_SPOTREBA_SOUCET: 'odberna.mista.smart.mereni.col.spotreba.soucet',
    ODBERNA_MISTA_SMART_MERENI_COL_SPOTREBA: 'odberna.mista.smart.mereni.col.spotreba',
    ODBERNA_MISTA_SMART_MERENI_COL_SPOTREBA_NT: 'odberna.mista.smart.mereni.col.spotreba.NT',
    ODBERNA_MISTA_SMART_MERENI_COL_SPOTREBA_VT: 'odberna.mista.smart.mereni.col.spotreba.VT',
    ODBERNA_MISTA_SMART_MERENI_COL_STAV_REGISTRU: 'odberna.mista.smart.mereni.col.stav.registru',
    ODBERNA_MISTA_SMART_MERENI_COL_STAV_REGISTRU_SOUCET: 'odberna.mista.smart.mereni.col.stav.registru.soucet',
    ODBERNA_MISTA_SMART_MERENI_COL_STAV_REGISTRU_NT: 'odberna.mista.smart.mereni.col.stav.registru.NT',
    ODBERNA_MISTA_SMART_MERENI_COL_STAV_REGISTRU_VT: 'odberna.mista.smart.mereni.col.stav.registru.VT',
    ODBERNA_MISTA_SMART_MERENI_COL_STATUS: 'odberna.mista.smart.mereni.col.status',
    ODBERNA_MISTA_SMART_MERENI_TABULKA_NO_DATA: 'odberna.mista.smart.mereni.tabulka.no.data',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA: 'odberna.mista.smart.mereni.statistika',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_CELKEM_JEDNOTARIF: 'odberna.mista.smart.mereni.statistika.celkem.jednotarif',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_CELKEM_DVOUTARIF: 'odberna.mista.smart.mereni.statistika.celkem.dvoutarif',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_SPOTREBA_NT: 'odberna.mista.smart.mereni.statistika.spotreba.NT',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_SPOTREBA_VT: 'odberna.mista.smart.mereni.statistika.spotreba.VT',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_MIN: 'odberna.mista.smart.mereni.statistika.min',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_MAX: 'odberna.mista.smart.mereni.statistika.max',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_MINMAX_TITLE: 'odberna.mista.smart.mereni.statistika.minmax.title',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_VSECHNY_VYSKYTY: 'odberna.mista.smart.mereni.statistika.vsechny.vyskyty',
    ODBERNA_MISTA_SMART_MERENI_STATISTIKA_NO_DATA: 'odberna.mista.smart.mereni.statistika.no.data',
    ODECTY_DAVKA_SMAZANA: 'odecty.davka.smazana',
    ODECTY_DAVKA_DETAIL_ODECET_NENALEZEN: 'odecty.davka.detail.odecet.nenalezen',
    ODECTY_JEDNOTARIF: 'odecty.jednotarif',
    ODECTY_DVOUTARIF: 'odecty.dvoutarif',
    ODECTY_ODECET_SMAZAN: 'odecty.odecet.smazan',
    ODECTY_DAVKA_ODESLANA: 'odecty.davka.odeslana',
    ODECTY_NOVY_ODECET: 'odecty.novy.odecet',
    ODECTY_UPRAVA_ODECET: 'odecty.uprava.odecet',
    ODECTY_ODECET_VYTVOREN: 'odecty.odecet.vytvoren',
    ODECTY_ODECET_ULOZEN: 'odecty.odecet.ulozen',
    ODECTY_NOVY_NENALEZENA_SMLOUVA: 'odecty.novy.nenalezena.smlouva',
    ODECTY_NENALEZENA_ADRESA: 'odecty.nenalezena.adresa',
    ODECTY_ODECET_AKTUALIZOVAN: 'odecty.odecet.aktualizovan',
    ODECTY_ODECET_NEAKTUALIZOVAN: 'odecty.odecet.neaktualizovan',
    ODECTY_CHYBA: 'odecty.chyba',
    ODECTY_SAMOODECET_NENALEZEN: 'odecty.samoodecet.nenalezen',
    ODECTY_AKTUALIZOVAT: 'odecty.aktualizovat',
    ODECTY_UPRAVIT: 'odecty.upravit',
    ODECTY_SMAZAT: 'odecty.smazat',
    ODECTY_DETAIL_ODECET: 'odecty.detail.odecet',
    ODECTY_MENU_DETAIL: 'odecty.menu.detail',
    ODECTY_MENU_UPRAVIT: 'odecty.menu.upravit',
    ODECTY_MENU_SMAZAT: 'odecty.menu.smazat',
    ODECTY_CISLO_ELEKTROMERU: 'odecty.cislo.elektromeru',
    ODECTY_STAV_NT: 'odecty.stav.nt',
    ODECTY_STAV_NT_POSLEDNI: 'odecty.stav.nt.posledni',
    ODECTY_STAV_VT: 'odecty.stav.vt',
    ODECTY_STAV_VT_POSLEDNI: 'odecty.stav.vt.posledni',
    ODECTY_STAV: 'odecty.stav',
    ODECTY_STAV_POSLEDNI: 'odecty.stav.posledni',
    ODECTY_ERROR_POD_DETAIL_ZAKAZNIK: 'odecty.error.pod.detail.zakaznik',
    ODECTY_ERROR_POD_DETAIL_OBCHODNIK: 'odecty.error.pod.detail.obchodnik',
    ODECTY_POD_DETAIL_C123BNN_INFO: 'odecty.pod.detail.c123bnn.info',
    ODECTY_DATUM_ODECTU: 'odecty.datum.odectu',
    ODECTY_EXPORT_ODESLANO: 'odecty.export.odeslano',
    ODECTY_EXPORT_DATUM_ODECTU: 'odecty.export.datum.odectu',
    ODECTY_EXPORT_CISLO_DAVKY: 'odecty.export.cislo.davky',
    ODECTY_EXPORT_CISLO_DAVKY_EO: 'odecty.export.cislo.davky.EO',
    ODECTY_EXPORT_CISLO_DAVKY_GO: 'odecty.export.cislo.davky.GO',
    ODECTY_DATUM_ODECTU_OD: 'odecty.datum.odectu.od',
    ODECTY_DATUM_ODECTU_DO: 'odecty.datum.odectu.do',
    ODECTY_DUVOD_ODECTU: 'odecty.duvod.odectu',
    ODECTY_CHYBNE_ODECTY_ODSTRANENY: 'odecty.chybne.odecty.odstraneny',
    ODECTY_ODECET_PRIDAN: 'odecty.odecet.pridan',
    ODECTY_ODECET_ODSTRANEN: 'odecty.odecet.odstranen',
    ODECTY_USPESNE_ODESLANO: 'odecty.uspesne.odeslano',
    ODECTY_ODECET_USPESNE_ODESLAN: 'odecty.odecet.uspesne.odeslan',
    ODECTY_ODECET_NAJDETE_CISLO_DAVKY: 'odecty.odecet.najdete.cislo.davky',
    ODECTY_NOVY_IMPORT: 'odecty.novy.import',
    ODECTY_ULOZENI_DAVKY: 'odecty.ulozeni.davky',
    ODECTY_ULOZENI_ODECTU: 'odecty.ulozeni.odectu',
    ODECTY_ODESLANI_DAVKY_CHYBA: 'odecty.odeslani.davky.chyba',
    ODECTY_DETAIL_INFORMACE_CISLO_DAVKY: 'odecty.detail.informace.cislo.davky',
    ODECTY_ULOZENI_ROZPRACOVANA_DAVKA: 'odecty.ulozeni.rozpracovana.davka',
    ODECTY_ULOZENI_ROZPRACOVANA_ODECET: 'odecty.ulozeni.rozpracovana.odecet',
    ODECTY_NAJDETE_CISLO_DAVKY: 'odecty.najdete.cislo.davky',
    ODECTY_NAJDETE_CISLO_DAVKY_ODECET: 'odecty.najdete.cislo.davky.odecet',
    ODECTY_VYTVORENO: 'odecty.vytvoreno',
    ODECTY_ODESLAL: 'odecty.odeslal',
    ODECTY_ODESLANO: 'odecty.odeslano',
    ODECTY_SMLOUVA_NENALEZENA: 'odecty.smlouva.nenalezena',
    ODECTY_JEDNOTLIVE: 'odecty.jednotlive',
    ODECTY_JEDNOTLIVE_ZAKAZNIK: 'odecty.jednotlive.zakaznik',
    ODECTY_APPBAR_TEXTACE: 'odecty.appbar.textace',
    ODECTY_HROMADNE: 'odecty.hromadne',
    ODECTY_NOVA_DAVKA: 'odecty.nova.davka',
    ODECTY_ZAVRIT: 'odecty.zavrit',
    ODECTY_ODECTY_IMPORT: 'odecty.odecty.import',
    ODECTY_KWH: 'odecty.kWh',
    ODECTY_MENU_VSE: 'odecty.menu.vse',
    ODECTY_MENU_VRESENI: 'odecty.menu.vreseni',
    ODECTY_MENU_VYRESENO: 'odecty.menu.vyreseno',
    ODECTY_MENU_ZAMITNUTO: 'odecty.menu.zamitnuto',
    ODECTY_DAVKA_MENU_VRESENI: 'odecty.davka.menu.vreseni',
    ODECTY_DAVKA_MENU_VYRESENO: 'odecty.davka.menu.vyreseno',
    ODECTY_DAVKA_MENU_ZAMITNUTO: 'odecty.davka.menu.zamitnuto',
    ODECTY_DAVKA_MENU_KONCEPT: 'odecty.davka.menu.koncept',
    ODECTY_DAVKA_MENU_CELKEM: 'odecty.davka.menu.celkem',
    ODECTY_MENU_KONCEPT: 'odecty.menu.koncept',
    ODECTY_DATUM_ODESLANI_OD: 'odecty.datum.odeslani.od',
    ODECTY_DATUM_ODESLANI_DO: 'odecty.datum.odeslani.do',
    ODECTY_DATUM_VYTVORENI_OD: 'odecty.datum.vytvoreni.od',
    ODECTY_DATUM_VYTVORENI_DO: 'odecty.datum.vytvoreni.do',
    ODECTY_VYTVORIT_ODECET: 'odecty.vytvorit.odecet',
    SAMOODECET_PREHLED_DAVEK: 'samoodecet.prehled.davek',
    SAMOODECET_PREHLED_ODECTU: 'samoodecet.prehled.odectu',
    SAMOODECET_ZADAT_SAMOODECET: 'samoodecet.zadat.samoodecet',
    SAMOODECET_CISLO_DAVKY: 'samoodecet.cislo.davky',
    SAMOODECET_CISLO_ODECTU: 'samoodecet.cislo.odectu',
    SAMOODECET_ODECET: 'samoodecet.odecet',
    SAMOODECET_STAV: 'samoodecet.stav',
    SAMOODECET_STAV_RESENI: 'samoodecet.stav.reseni',
    SAMOODECET_STAV_K_ODESLANI: 'samoodecet.stav.k.odeslani',
    SAMOODECET_STAV_MERIDLA: 'samoodecet.stav.meridla',
    SAMOODECET_STAV_MERIDLA_VT: 'samoodecet.stav.meridla.VT',
    SAMOODECET_STAV_MERIDLA_NT: 'samoodecet.stav.meridla.NT',
    SAMOODECET_DATUM_VLOZENI: 'samoodecet.datum.vlozeni',
    SAMOODECET_DATUM: 'samoodecet.datum',
    SAMOODECET_DUVOD: 'samoodecet.duvod',
    SAMOODECET_OPRAVNY: 'samoodecet.opravny',
    SAMOODECET_PERIODICKY_INFO: 'samoodecet.periodicky.info',
    SAMOODECET_PERIODICKY_INFO_VICE: 'samoodecet.periodicky.info.vice',
    SAMOODECET_OPRAVNY_NOVY: 'samoodecet.opravny.novy',
    SAMOODECET_ODECET_NOVY: 'samoodecet.odecet.novy',
    SAMOODECET_ODECET_OPRAVNY: 'samoodecet.odecet.opravny',
    SAMOODECET_NEVEROHODNY: 'samoodecet.neverohodny',
    SAMOODECET_NEVEROHODNY_FORM: 'samoodecet.neverohodny.form',
    SAMOODECET_NEVEROHODNY_TOOLTIP: 'samoodecet.neverohodny.tooltip',
    SAMOODECET_NEVEROHODNY_ODECET_TOOLTIP: 'samoodecet.neverohodny.odecet.tooltip',
    SAMOODECET_DUVOD_ZAMITNUTI: 'samoodecet.duvod.zamitnuti',
    SAMOODECET_STAV_PODANI: 'samoodecet.stav.podani',
    SAMOODECET_DAVKA_CONFIRM_DELETE: 'samoodecet.davka.confirm.delete',
    SAMOODECET_DAVKA_ODESLANI_STAV_NEODESLANO: 'samoodecet.davka.odeslani.stav.neodeslano',
    SAMOODECET_DAVKA_ODESLANI_STAV_ODESILANI: 'samoodecet.davka.odeslani.stav.odesilani',
    SAMOODECET_DAVKA_ODESLANI_STAV_CHYBA_ODESLANI: 'samoodecet.davka.odeslani.stav.chyba.odeslani',
    SAMOODECET_DAVKA_ODESLANI_STAV_ODESLANO: 'samoodecet.davka.odeslani.stav.odeslano',
    SAMOODECET_DAVKA_DETAIL_HEADLINE: 'samoodecet.davka.detail.headline',
    SAMOODECET_DAVKA_DETAIL_GRAF_HEADLINE: 'samoodecet.davka.detail.graf.headline',
    SAMOODECET_DAVKA_CONFIRM_DELETE_ODECET: 'samoodecet.davka.confirm.delete.odecet',
    SAMOODECET_DAVKA_CONFIRM_DELETE_LAST_ODECET: 'samoodecet.davka.confirm.delete.last.odecet',
    SAMOODECET_PODANI_NOVY_ODECET: 'samoodecet.podani.novy.odecet',
    SAMOODECET_PODANI_UPRAVA_ODECTU: 'samoodecet.podani.uprava.odectu',
    SAMOODECET_PODANI_STAV_ODESLANO: 'samoodecet.podani.stav.odeslano',
    SAMOODECET_PODANI_STAV_PODANO: 'samoodecet.podani.stav.podano',
    SAMOODECET_PODANI_STAV_NEVALIDNI_ODECET: 'samoodecet.podani.stav.nevalidni.odecet',
    SAMOODECET_PODANI_STAV_CHYBA_PODANI: 'samoodecet.podani.stav.chyba.podani',
    SAMOODECET_PODANI_STAV_PRIJATO_VEROHODNY: 'samoodecet.podani.stav.prijato.verohodny',
    SAMOODECET_PODANI_STAV_PRIJATO_NEVEROHODNY: 'samoodecet.podani.stav.prijato.neverohodny',
    SAMOODECET_PODANI_STAV_CEKA_NA_ODESLANI: 'samoodecet.podani.stav.ceka.na.odeslani',
    SAMOODECET_PODANI_STAV_APPERAK: 'samoodecet.podani.stav.apperak',
    SAMOODECET_PODANI_STAV_UTILMD: 'samoodecet.podani.stav.utilmd',
    SAMOODECET_DETAIL_MISTO: 'samoodecet.detail.misto',
    SAMOODECET_DETAIL_CREATED_DATE: 'samoodecet.detail.created.date',
    SAMOODECET_DETAIL_STATISTIKY_HEADLINE: 'samoodecet.detail.statistiky.headline',
    SAMOODECET_DETAIL_SENT_BY: 'samoodecet.detail.sent.by',
    SAMOODECET_DETAIL_SENT_DATE: 'samoodecet.detail.sent.date',
    SAMOODECET_DETAIL_POCET_CELKEM: 'samoodecet.detail.pocet.celkem',
    SAMOODECET_HISTORIE_STAVU_HEADLINE: 'samoodecet.historie.stavu.headline',
    SAMOODECET_HISTORIE_STAVU_DATUM_CAS: 'samoodecet.historie.stavu.datum.cas',
    SAMOODECET_HISTORIE_STAVU_STAV: 'samoodecet.historie.stavu.stav',
    SAMOODECET_HISTORIE_STAVU_SAP_RESPONSE: 'samoodecet.historie.stavu.sap.response',
    SAMOODECET_IMPORT_HEADLINE: 'samoodecet.import.headline',
    SAMOODECET_IMPORT_PAPERBLOCK_TEXT: 'samoodecet.import.paperblock.text',
    SAMOODECET_IMPORT_STAV_ODESLANI_HEADLINE: 'samoodecet.import.stav.odeslani.headline',
    SAMOODECET_IMPORT_STAV_ODESLANI_TEXT: 'samoodecet.import.stav.odeslani.text',
    SAMOODECET_IMPORT_RESULT_HEADLINE: 'samoodecet.import.result.headline',
    SAMOODECET_IMPORT_RESULT_ERRORS: 'samoodecet.import.result.errors',
    SAMOODECET_IMPORT_RESULT_WARNINGS: 'samoodecet.import.result.warnings',
    SAMOODECET_IMPORT_RESULT_SUCCESS: 'samoodecet.import.result.success',
    SAMOODECET_IMPORT_ERROR_HEADLINE: 'samoodecet.import.error.headline',
    SAMOODECET_EAN: 'samoodecet.ean',
    SAMOODECET_EAN_PLACEHOLDER: 'samoodecet.ean.placeholder',
    SAMOODECET_EIC: 'samoodecet.eic',
    SAMOODECET_EIC_PLACEHOLDER: 'samoodecet.eic.placeholder',
    SAMOODECET_CISLOPRISTROJE: 'samoodecet.cisloPristroje',
    SAMOODECET_STAVMERIDLAVT: 'samoodecet.stavMeridlaVT',
    SAMOODECET_STAVMERIDLANT: 'samoodecet.stavMeridlaNT',
    SAMOODECET_STAVMERIDLA: 'samoodecet.stavMeridla',
    SAMOODECET_TYPTARIFU: 'samoodecet.typTarifu',
    SAMOODECET_IMPORT_MIN_COUNT_TEXT: 'samoodecet.import.min.count.text',
    SAMOODECET_IMPORT_ERROR_TEXT: 'samoodecet.import.error.text',
    SAMOODECET_VYTVORENO: 'samoodecet.vytvoreno',
    SAMOODECET_ODESLANO: 'samoodecet.odeslano',
    SAMOODECET_VYTVORIL: 'samoodecet.vytvoril',
    SAMOODECET_CHYBA: 'samoodecet.chyba',
    SAMOODECET_STAV_RESENI_CEKA_NA_ODESLANI: 'samoodecet.stav.reseni.CEKA_NA_ODESLANI',
    SAMOODECET_STAV_RESENI_ODESLANO: 'samoodecet.stav.reseni.ODESLANO',
    SAMOODECET_STAV_RESENI_CHYBA_PODANI: 'samoodecet.stav.reseni.CHYBA_PODANI',
    SAMOODECET_STAV_RESENI_NEVALIDNI_ODECET: 'samoodecet.stav.reseni.NEVALIDNI_ODECET',
    SAMOODECET_STAV_RESENI_NEVEROHODNY: 'samoodecet.stav.reseni.NEVEROHODNY',
    SAMOODECET_STAV_RESENI_VEROHODNY: 'samoodecet.stav.reseni.VEROHODNY',
    SAMOODECET_STAV_RESENI_VSE: 'samoodecet.stav.reseni.VSE',
    SAMOODECET_STAV_PODANI_DAVKA_KONCEPT: 'samoodecet.stav.podani.davka.KONCEPT',
    SAMOODECET_STAV_PODANI_DAVKA_ODESLANO: 'samoodecet.stav.podani.davka.ODESLANO',
    SAMOODECET_STAV_PODANI_ODECET_KONCEPT: 'samoodecet.stav.podani.odecet.KONCEPT',
    SAMOODECET_STAV_PODANI_ODECET_V_RESENI: 'samoodecet.stav.podani.odecet.V_RESENI',
    SAMOODECET_STAV_PODANI_ODECET_VYRESENO: 'samoodecet.stav.podani.odecet.VYRESENO',
    SAMOODECET_STAV_PODANI_ODECET_ZAMITNUTO: 'samoodecet.stav.podani.odecet.ZAMITNUTO',
    SAMOODECET_FILTRATION_ROZSIRENE_ZOBRAZIT: 'samoodecet.filtration.rozsirene.zobrazit',
    SAMOODECET_FILTRATION_ROZSIRENE_SKRYT: 'samoodecet.filtration.rozsirene.skryt',
    SAMOODECET_FILTRATION_ROZSIRENE: 'samoodecet.filtration.rozsirene',
    POZADAVKY: 'pozadavky',
    POZADAVKY_DOKONCENI_REGISTRACE: 'pozadavky.dokonceni.registrace',
    POZADAVKY_DOKONCENI_REGISTRACE_DESCRIPTION: 'pozadavky.dokonceni.registrace.description',
    POZADAVKY_VASE_POZADAVKY: 'pozadavky.vase.pozadavky',
    POZADAVKY_VASE_POZADAVKY_DESCRIPTION: 'pozadavky.vase.pozadavky.description',
    POZADAVEK_NOVY: 'pozadavek.novy',
    POZADAVEK_NAZEV: 'pozadavek.nazev',
    POZADAVEK_POPIS: 'pozadavek.popis',
    POZADAVEK_TYP: 'pozadavek.typ',
    POZADAVEK_STAV: 'pozadavek.stav',
    POZADAVEK_DETAIL: 'pozadavek.detail',
    POZADAVEK_PRIRAZENO: 'pozadavek.prirazeno',
    POZADAVEK_VYTVORENO: 'pozadavek.vytvoreno',
    POZADAVEK_ZMEMENO: 'pozadavek.zmemeno',
    POZADAVEK_PRIDAT_KOMENTAR: 'pozadavek.pridat.komentar',
    POZADAVEK_KOMENTARE: 'pozadavek.komentare',
    POZADAVEK_NOVY_KOMENTAR: 'pozadavek.novy.komentar',
    POZADAVEK_KOMENTAR_TEXT: 'pozadavek.komentar.text',
    POZADAVEK_ZALOZIT_POZADAVEK: 'pozadavek.zalozit.pozadavek',
    POZADAVEK_VYTVORIL: 'pozadavek.vytvoril',
    POZADAVEK_PREDAT_K_RESENI: 'pozadavek.predat.k.reseni',
    POZADAVEK_PREDAT_K_RESENI_POTVRZENI: 'pozadavek.predat.k.reseni.potvrzeni',
    POZADAVEK_PREDAT_K_RESENI_POTVRZENI_DESCRIPTION: 'pozadavek.predat.k.reseni.potvrzeni.description',
    POZADAVEK_STORNO_POTVRZENI: 'pozadavek.storno.potvrzeni',
    POZADAVEK_STORNO_POTVRZENI_DESCRIPTION: 'pozadavek.storno.potvrzeni.description',
    POZADAVEK_CHYBA: 'pozadavek.chyba',
    POZADAVEK_NAHRAVAM: 'pozadavek.nahravam',
    POZADAVEK_NENI_VYPLNENO: 'pozadavek.neni.vyplneno',
    POZADAVEK_PREKROCENI_DELKY_KOMENTARE: 'pozadavek.prekroceni.delky.komentare',
    POZADAVEK_PREKROCENI_DELKY_NAZEV: 'pozadavek.prekroceni.delky.nazev',
    POZADAVEK_ODESLANO: 'pozadavek.odeslano',
    POZADAVEK_DETAIL_BUTTON: 'pozadavek.detail.button',
    POZADAVEK_TYP_VSE: 'pozadavek.typ.vse',
    REKLAMACE: 'reklamace',
    REKLAMACE_ZADAT_REKLAMACI: 'reklamace.zadat.reklamaci',
    REKLAMACE_NOVA_REKLAMACE: 'reklamace.nova.reklamace',
    REKLAMACE_ELEKTRINA_NOVA_DESCRIPTION: 'reklamace.elektrina.nova.description',
    REKLAMACE_NOVA_WARNING: 'reklamace.nova.warning',
    REKLAMACE_PLYN_NOVA_DESCRIPTION: 'reklamace.plyn.nova.description',
    REKLAMACE_UPDATE_REKLAMACE: 'reklamace.update.reklamace',
    REKLAMACE_TYP: 'reklamace.typ',
    REKLAMACE_EAN: 'reklamace.ean',
    REKLAMACE_EIC: 'reklamace.eic',
    REKLAMACE_DUVOD: 'reklamace.duvod',
    REKLAMACE_REFERENCE_DISTRIBUTORA: 'reklamace.reference.distributora',
    REKLAMACE_SMLOUVA: 'reklamace.smlouva',
    REKLAMACE_PRISTROJ: 'reklamace.pristroj',
    REKLAMACE_STAVY_MERIDEL: 'reklamace.stavy.meridel',
    REKLAMACE_EMAIL: 'reklamace.email',
    REKLAMACE_TELEFON: 'reklamace.telefon',
    REKLAMACE_AKTUALNI_STAV_MERIDLA: 'reklamace.aktualni.stav.meridla',
    REKLAMACE_AKTUALNI_STAV_MERIDLA_K: 'reklamace.aktualni.stav.meridla.k',
    REKLAMACE_AKTUALNI_STAV_MERIDLA_NT: 'reklamace.aktualni.stav.meridla.NT',
    REKLAMACE_AKTUALNI_STAV_MERIDLA_VT: 'reklamace.aktualni.stav.meridla.VT',
    REKLAMACE_POZADOVANY_STAV_MERIDLA: 'reklamace.pozadovany.stav.meridla',
    REKLAMACE_POZADOVANY_STAV_MERIDLA_K: 'reklamace.pozadovany.stav.meridla.k',
    REKLAMACE_POZADOVANY_STAV_MERIDLA_NT: 'reklamace.pozadovany.stav.meridla.NT',
    REKLAMACE_POZADOVANY_STAV_MERIDLA_VT: 'reklamace.pozadovany.stav.meridla.VT',
    REKLAMACE_DATUM_ODECTU: 'reklamace.datum.odectu',
    REKLAMACE_POPIS: 'reklamace.popis',
    REKLAMACE_CISLO: 'reklamace.cislo',
    REKLAMACE_CISLO_FILTER: 'reklamace.cislo.filter',
    REKLAMACE_VYTVORIL_PLACEHOLDER: 'reklamace.vytvoril.placeholder',
    REKLAMACE_CONFIRM_DELETE: 'reklamace.confirm.delete',
    REKLAMACE_AKTUALNISTAVMERIDLA: 'reklamace.aktualniStavMeridla',
    REKLAMACE_POZADOVANYSTAVMERIDLA: 'reklamace.pozadovanyStavMeridla',
    REKLAMACE_TYP_ELEKTRINA_VSE: 'reklamace.typ.elektrina.VSE',
    REKLAMACE_TYP_ELEKTRINA_VYUCTOVANI_DISTRIBUCE: 'reklamace.typ.elektrina.VYUCTOVANI_DISTRIBUCE',
    REKLAMACE_TYP_ELEKTRINA_TECHNICKE_ZAVADY_MERENI: 'reklamace.typ.elektrina.TECHNICKE_ZAVADY_MERENI',
    REKLAMACE_TYP_ELEKTRINA_UREDNI_PREZKOUSENI: 'reklamace.typ.elektrina.UREDNI_PREZKOUSENI',
    REKLAMACE_TYP_ELEKTRINA_KVALITA_NAPETI: 'reklamace.typ.elektrina.KVALITA_NAPETI',
    REKLAMACE_TYP_ELEKTRINA_SKODY_NA_ODBERNEM_ZARIZENI: 'reklamace.typ.elektrina.SKODY_NA_ODBERNEM_ZARIZENI',
    REKLAMACE_TYP_ELEKTRINA_NEOZNAMENI_PRERUSENI: 'reklamace.typ.elektrina.NEOZNAMENI_PRERUSENI',
    REKLAMACE_TYP_ELEKTRINA_OSTATNI_TECHNICKE_STIZNOSTI: 'reklamace.typ.elektrina.OSTATNI_TECHNICKE_STIZNOSTI',
    REKLAMACE_TYP_ELEKTRINA_OSTATNI_NETECHNICKE_STIZNOSTI: 'reklamace.typ.elektrina.OSTATNI_NETECHNICKE_STIZNOSTI',
    REKLAMACE_TYP_ELEKTRINA_ZADOST_NAHRADA: 'reklamace.typ.elektrina.ZADOST_NAHRADA',
    REKLAMACE_TYP_PLYN_VSE: 'reklamace.typ.plyn.VSE',
    REKLAMACE_TYP_PLYN_VYUCTOVANI_DISTRIBUCE: 'reklamace.typ.plyn.VYUCTOVANI_DISTRIBUCE',
    REKLAMACE_TYP_PLYN_TECHNICKE_ZAVADY_MERENI: 'reklamace.typ.plyn.TECHNICKE_ZAVADY_MERENI',
    REKLAMACE_TYP_PLYN_UREDNI_PREZKOUSENI: 'reklamace.typ.plyn.UREDNI_PREZKOUSENI',
    REKLAMACE_TYP_PLYN_KVALITA_PLYNU: 'reklamace.typ.plyn.KVALITA_PLYNU',
    REKLAMACE_TYP_PLYN_SKODY_NA_ODBERNEM_ZARIZENI: 'reklamace.typ.plyn.SKODY_NA_ODBERNEM_ZARIZENI',
    REKLAMACE_TYP_PLYN_NEOZNAMENI_PRERUSENI: 'reklamace.typ.plyn.NEOZNAMENI_PRERUSENI',
    REKLAMACE_TYP_PLYN_OSTATNI_TECHNICKE_STIZNOSTI: 'reklamace.typ.plyn.OSTATNI_TECHNICKE_STIZNOSTI',
    REKLAMACE_TYP_PLYN_OSTATNI_NETECHNICKE_STIZNOSTI: 'reklamace.typ.plyn.OSTATNI_NETECHNICKE_STIZNOSTI',
    REKLAMACE_TYP_PLYN_ZADOST_NAHRADA: 'reklamace.typ.plyn.ZADOST_NAHRADA',
    REKLAMACE_ZALOZIT_REKLAMACI: 'reklamace.zalozit.reklamaci',
    REKLAMACE_DATUM_PODANI: 'reklamace.datum.podani',
    REKLAMACE_DATUM_PODANI_OD: 'reklamace.datum.podani.od',
    REKLAMACE_DATUM_PODANI_DO: 'reklamace.datum.podani.do',
    REKLAMACE_DATUM_VYTVORENO_OD: 'reklamace.datum.vytvoreno.od',
    REKLAMACE_DATUM_VYTVORENO_DO: 'reklamace.datum.vytvoreno.do',
    REKLAMACE_DETAIL: 'reklamace.detail',
    REKLAMACE_STAV: 'reklamace.stav',
    REKLAMACE_ZAKAZNIK: 'reklamace.zakaznik',
    REKLAMACE_ADRESA: 'reklamace.adresa',
    REKLAMACE_SMLUVNI_OBDOBI_OD: 'reklamace.smluvni.obdobi.od',
    REKLAMACE_SMLUVNI_OBDOBI_DO: 'reklamace.smluvni.obdobi.do',
    REKLAMACE_PRILOHY: 'reklamace.prilohy',
    REKLAMACE_VYTVORENO: 'reklamace.vytvoreno',
    REKLAMACE_ZMENENO: 'reklamace.zmeneno',
    REKLAMACE_ODESLANO: 'reklamace.odeslano',
    REKLAMACE_VYTVORIL: 'reklamace.vytvoril',
    REKLAMACE_ZMENIL: 'reklamace.zmenil',
    REKLAMACE_ODESLAL: 'reklamace.odeslal',
    REKLAMACE_POTVRDIT_ODESLANI_BEZ_PRILOHY: 'reklamace.potvrdit.odeslani.bez.prilohy',
    REKLAMACE_DUVOD_STORNA: 'reklamace.duvod.storna',
    REKLAMACE_TERMIN_VYRIZENI: 'reklamace.termin.vyrizeni',
    REKLAMACE_TERMIN_VYRIZENI_ZMENA: 'reklamace.termin.vyrizeni.zmena',
    REKLAMACE_ADRESA_ODBERNEHO_MISTA: 'reklamace.adresa.odberneho.mista',
    REKLAMACE_NOTIFICATION_ERROR_HEAD_TEXT: 'reklamace.notification.error.head.text',
    REKLAMACE_NOTIFICATION_ERROR_TAIL_TEXT: 'reklamace.notification.error.tail.text',
    REKLAMACE_NOTIFICATION_INFO_SEND: 'reklamace.notification.info.send',
    REKLAMACE_NOTIFICATION_INFO_DRAFT: 'reklamace.notification.info.draft',
    REKLAMACE_NOTIFICATION_SENT_BEFORE: 'reklamace.notification.sent.before',
    REKLAMACE_NOTIFICATION_SENT_AFTER: 'reklamace.notification.sent.after',
    REKLAMACE_NOTIFICATION_DELETED: 'reklamace.notification.deleted',
    REKLAMACE_KOMENTARE_NEWCOMMENT_TITLE: 'reklamace.komentare.newComment.title',
    REKLAMACE_KOMENTARE_NEWCOMMENT_TEXT: 'reklamace.komentare.newComment.text',
    REKLAMACE_MENU_UPRAVIT: 'reklamace.menu.upravit',
    REKLAMACE_MENU_SMAZAT: 'reklamace.menu.smazat',
    REKLAMACE_MENU_DETAIL: 'reklamace.menu.detail',
    REKLAMACE_MENU_STORNO: 'reklamace.menu.storno',
    REKLAMACE_STORNO_TITLE: 'reklamace.storno.title',
    REKLAMACE_STORNO_TEXT: 'reklamace.storno.text',
    REKLAMACE_STORNO_NOTIFICATION: 'reklamace.storno.notification',
    REKLAMACE_STORNO_NOTIFICATION_BEFORE: 'reklamace.storno.notification.before',
    REKLAMACE_STORNO_NOTIFICATION_AFTER: 'reklamace.storno.notification.after',
    REKLAMACE_DASHBOARD_WIDGET_SUBTITLE: 'reklamace.dashboard.widget.subtitle',
    REKLAMACE_DASHBOARD_WIDGET_BODY: 'reklamace.dashboard.widget.body',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_ITEMS_BEFORE: 'reklamace.dashboard.widget.content.items.before',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_V_RESENI_AFTER_0: 'reklamace.dashboard.widget.content.v.reseni.after.0',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_V_RESENI_AFTER_1: 'reklamace.dashboard.widget.content.v.reseni.after.1',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_V_RESENI_AFTER_2: 'reklamace.dashboard.widget.content.v.reseni.after.2',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_V_RESENI_AFTER_3: 'reklamace.dashboard.widget.content.v.reseni.after.3',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_CEKA_NA_DOPLNENI_AFTER_0: 'reklamace.dashboard.widget.content.ceka.na.doplneni.after.0',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_CEKA_NA_DOPLNENI_AFTER_1: 'reklamace.dashboard.widget.content.ceka.na.doplneni.after.1',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_CEKA_NA_DOPLNENI_AFTER_2: 'reklamace.dashboard.widget.content.ceka.na.doplneni.after.2',
    REKLAMACE_DASHBOARD_WIDGET_CONTENT_CEKA_NA_DOPLNENI_AFTER_3: 'reklamace.dashboard.widget.content.ceka.na.doplneni.after.3',
    REKLAMACE_DASHBOARD_WIDGET_BUTTON: 'reklamace.dashboard.widget.button',
    REKLAMACE_DOPLNENI_ODESLANO: 'reklamace.doplneni.odeslano',
    REKLAMACE_EAN_NENALEZENA_SMLOUVA: 'reklamace.ean.nenalezena.smlouva',
    REKLAMACE_EAN_NEOBSAHUJE_ZNAKY: 'reklamace.ean.neobsahuje.znaky',
    REKLAMACE_PREHLED_STAVU: 'reklamace.prehled.stavu',
    REKLAMACE_POMER_JEDNOTLIVE_STAVY: 'reklamace.pomer.jednotlive.stavy',
    REKLAMACE_TYDENNI_PREHLED: 'reklamace.tydenni.prehled',
    REKLAMACE_CELKOVY_POCET_PODANYCH_REKLAMACI_TYDEN: 'reklamace.celkovy.pocet.podanych.reklamaci.tyden',
    REKLAMACE_MESICNI_PREHLED: 'reklamace.mesicni.prehled',
    REKLAMACE_CELKOVY_POCET_PODANYCH_REKLAMACI_MESIC: 'reklamace.celkovy.pocet.podanych.reklamaci.mesic',
    REKLAMACE_ROCNI_PREHLED: 'reklamace.rocni.prehled',
    REKLAMACE_CELKOVY_POCET_PODANYCH_REKLAMACI_ROK: 'reklamace.celkovy.pocet.podanych.reklamaci.rok',
    REKLAMACE_CHYBNE_ODECTENO: 'reklamace.chybne.odecteno',
    REKLAMACE_SMLUVNI_OBDOBI: 'reklamace.smluvni.obdobi',
    REKLAMACE_CISLO_PRISTROJE: 'reklamace.cislo.pristroje',
    REKLAMACE_123456: 'reklamace.123456',
    REKLAMACE_NEKDE_CHYBA: 'reklamace.nekde.chyba',
    REKLAMACE_PLYN: 'reklamace.plyn',
    REKLAMACE_USPESNE_ODESLANO: 'reklamace.uspesne.odeslano',
    REKLAMACE_USPESNE_ODESLANA_BUTTON: 'reklamace.uspesne.odeslana.button',
    REKLAMACE_ZAVRIT: 'reklamace.zavrit',
    REKLAMACE_PODAT_REKLAMACI: 'reklamace.podat.reklamaci',
    REKLAMACE_EIC_NENALEZENA_SMLOUVA: 'reklamace.eic.nenalezena.smlouva',
    REKLAMACE_EIC_NEOBSAHUJE_ZNAKY: 'reklamace.eic.neobsahuje.znaky',
    REKLAMACE_LEFT_MENU_VSE: 'reklamace.left.menu.vse',
    REKLAMACE_LEFT_MENU_CEKA_NA_DOPLNENI: 'reklamace.left.menu.ceka.na.doplneni',
    REKLAMACE_LEFT_MENU_V_RESENI: 'reklamace.left.menu.v.reseni',
    REKLAMACE_LEFT_MENU_KONCEPT: 'reklamace.left.menu.koncept',
    REKLAMACE_LEFT_MENU_VYRESENE: 'reklamace.left.menu.vyresene',
    REKLAMACE_LEFT_MENU_ZAMITNUTO: 'reklamace.left.menu.zamitnuto',
    REKLAMACE_LEFT_MENU_STORNO: 'reklamace.left.menu.storno',
    REKLAMACE_LEFT_MENU_STATISTIKY: 'reklamace.left.menu.statistiky',
    REKLAMACE_CHYBA: 'reklamace.chyba',
    REKLAMACE_STAV_RESENI: 'reklamace.stav.reseni',
    REKLAMACE_DUVOD_ZAMITNUTI: 'reklamace.duvod.zamitnuti',
    REKLAMACE_STAV_RESENI_CEKA_NA_ODESLANI: 'reklamace.stav.reseni.CEKA_NA_ODESLANI',
    REKLAMACE_STAV_RESENI_ODESLANO: 'reklamace.stav.reseni.ODESLANO',
    REKLAMACE_STAV_RESENI_NEUSPESNE_PODANI: 'reklamace.stav.reseni.NEUSPESNE_PODANI',
    REKLAMACE_STAV_RESENI_CEKA_NA_DOPLNENI: 'reklamace.stav.reseni.CEKA_NA_DOPLNENI',
    REKLAMACE_STAV_RESENI_DOPLNENI_ODESLANO: 'reklamace.stav.reseni.DOPLNENI_ODESLANO',
    REKLAMACE_STAV_RESENI_NEOPRAVNENA: 'reklamace.stav.reseni.NEOPRAVNENA',
    REKLAMACE_STAV_RESENI_OPRAVNENA: 'reklamace.stav.reseni.OPRAVNENA',
    REKLAMACE_STAV_RESENI_ZMENA_TERMINU: 'reklamace.stav.reseni.ZMENA_TERMINU',
    REKLAMACE_STAV_RESENI_ODESLAN_POZADAVEK_NA_STORNO: 'reklamace.stav.reseni.ODESLAN_POZADAVEK_NA_STORNO',
    REKLAMACE_STAV_RESENI_STORNOVANO: 'reklamace.stav.reseni.STORNOVANO',
    REKLAMACE_STAV_RESENI_VSE: 'reklamace.stav.reseni.VSE',
    DODATKY: 'dodatky',
    DODATKY_HISTORIE: 'dodatky.historie',
    DODATKY_LEFT_MENU_VSE: 'dodatky.left.menu.vse',
    DODATKY_LEFT_MENU_CEKA_NA_POTVRZENI_OBCHODNIKEM: 'dodatky.left.menu.ceka.na.potvrzeni.obchodnikem',
    DODATKY_LEFT_MENU_POTVRZENO_OBCHODNIKEM: 'dodatky.left.menu.potvrzeno.obchodnikem',
    DODATKY_LEFT_MENU_OBOUSTRANNE_PODEPSANO: 'dodatky.left.menu.oboustranne.podepsano',
    DODATKY_CISLO_SMLOUVY: 'dodatky.cislo.smlouvy',
    DODATKY_CISLO_DODATKU: 'dodatky.cislo.dodatku',
    DODATKY_DATUM_VYTVORENI: 'dodatky.datum.vytvoreni',
    DODATKY_PODEPSAT: 'dodatky.podepsat',
    DODATKY_PODEPSAT_VYBRANE: 'dodatky.podepsat.vybrane',
    DODATKY_DODATEK_PODEPSAN_OK: 'dodatky.dodatek.podepsan.ok',
    DODATKY_DODATEK_PODEPSAN_KO: 'dodatky.dodatek.podepsan.ko',
    DODATKY_POTVRZUJI_DODATEK: 'dodatky.potvrzuji.dodatek',
    DODATKY_FILTRATION_DATUM_VYTVORENI_OD: 'dodatky.filtration.datum.vytvoreni.od',
    DODATKY_FILTRATION_DATUM_VYTVORENI_DO: 'dodatky.filtration.datum.vytvoreni.do',
    DODATKY_STAV: 'dodatky.stav',
    DODATKY_STAV_VSE: 'dodatky.stav.VSE',
    DODATKY_STAV_CEKA_NA_POTVRZENI_OBCHODNIKEM: 'dodatky.stav.CEKA_NA_POTVRZENI_OBCHODNIKEM',
    DODATKY_STAV_POTVRZENO_OBCHODNIKEM: 'dodatky.stav.POTVRZENO_OBCHODNIKEM',
    DODATKY_STAV_OBOUSTRANNE_PODEPSANO: 'dodatky.stav.OBOUSTRANNE_PODEPSANO',
    DODATKY_HISTORIE_STAV: 'dodatky.historie.stav',
    DODATKY_HISTORIE_DATUM_ZMENY: 'dodatky.historie.datum.zmeny',
    DODATKY_HISTORIE_ID_UZIVATELE: 'dodatky.historie.id.uzivatele',
    DODATKY_HISTORIE_UZIVATEL: 'dodatky.historie.uzivatel',
    DODATKY_HISTORIE_STAVU_DODATKU: 'dodatky.historie.stavu.dodatku',
    DODATKY_DASHBOARD_WIDGET: 'dodatky.dashboard.widget',
    DODATKY_KE_STAZENI: 'dodatky.ke.stazeni',
    DODATKY_TYP_ZRQ22: 'dodatky.typ.ZRQ22',
    DODATKY_TYP_ZRQ24: 'dodatky.typ.ZRQ24',
    DODATKY_STAZENI_POTVRZENO_OBCHODNIKEM: 'dodatky.stazeni.POTVRZENO_OBCHODNIKEM',
    DODATKY_PRILOHA_NAZEV: 'dodatky.priloha.nazev',
    DODATKY_PRILOHA_MIME: 'dodatky.priloha.mime',
    DODATKY_PRILOHA_VELIKOST: 'dodatky.priloha.velikost',
    DODATKY_PRILOHA_PODEPSAL: 'dodatky.priloha.podepsal',
    ODECTY_IMPORT_ERROR_DUPLICITA_HROMADNE_PODANI: 'odecty.import.error.duplicita.hromadne.podani',
    ODECTY_IMPORT_ERROR_CISLOPRISTROJE: 'odecty.import.error.cisloPristroje',
    ODECTY_IMPORT_ERROR_EAN: 'odecty.import.error.ean',
    ODECTY_IMPORT_ERROR_DATUM: 'odecty.import.error.datum',
    ODECTY_IMPORT_ERROR_DUVOD: 'odecty.import.error.duvod',
    ODECTY_IMPORT_ERROR_STAVMERIDLAVT: 'odecty.import.error.stavMeridlaVt',
    ODECTY_IMPORT_ERROR_STAVMERIDLANT: 'odecty.import.error.stavMeridlaNt',
    ODECTY_IMPORT_ERROR_VALIDACE_TYPU_MERENI: 'odecty.import.error.VALIDACE.TYPU.MERENI',
    ODECTY_IMPORT_FIELD_CISLOPRISTROJE: 'odecty.import.field.cisloPristroje',
    ODECTY_IMPORT_FIELD_EAN: 'odecty.import.field.ean',
    ODECTY_IMPORT_FIELD_TYP_TARIFU: 'odecty.import.field.typ.tarifu',
    ODECTY_IMPORT_FIELD_EIC: 'odecty.import.field.eic',
    ODECTY_IMPORT_FIELD_DATUM: 'odecty.import.field.datum',
    ODECTY_IMPORT_FIELD_DATUMODECTU: 'odecty.import.field.datumOdectu',
    ODECTY_IMPORT_FIELD_DUVOD: 'odecty.import.field.duvod',
    ODECTY_IMPORT_FIELD_OPRAVNYODECET: 'odecty.import.field.opravnyOdecet',
    ODECTY_IMPORT_FIELD_ZAMITNOUTNEVEROHODNEIHNED: 'odecty.import.field.zamitnoutNeverohodneIhned',
    ODECTY_IMPORT_FIELD_STAVMERIDLANT: 'odecty.import.field.stavMeridlaNt',
    ODECTY_IMPORT_FIELD_STAVMERIDLAVT: 'odecty.import.field.stavMeridlaVt',
    ODECTY_IMPORT_FIELD_STAVMERIDLA: 'odecty.import.field.stavMeridla',
    ODECTY_IMPORT_DIALOG: 'odecty.import.dialog',
    ODECTY_IMPORT_PRAZDNY: 'odecty.import.prazdny',
    ODECTY_IMPORT_RESULT_HEADLINE: 'odecty.import.result.headline',
    ODECTY_IMPORT_RESULT_ERRORS: 'odecty.import.result.errors',
    ODECTY_IMPORT_RESULT_WARNINGS: 'odecty.import.result.warnings',
    ODECTY_IMPORT_RESULT_SUCCESS: 'odecty.import.result.success',
    ODECTY_ODESILAM: 'odecty.odesilam',
    ODECTY_IMPORT_ROW_ERRORS_TITLE: 'odecty.import.row.errors.title',
    ODECTY_IMPORT_ROW_WARNINGS_TITLE: 'odecty.import.row.warnings.title',
    ODECTY_IMPORT_MENU_DETAIL: 'odecty.import.menu.detail',
    ODECTY_IMPORT_MENU_UPRAVIT: 'odecty.import.menu.upravit',
    ODECTY_IMPORT_MENU_DELETE: 'odecty.import.menu.delete',
    ODECTY_IMPORT_SHOW_ONLY_ERRORS: 'odecty.import.show.only.errors',
    ODECTY_IMPORTOVANA_DATA: 'odecty.importovana.data',
    ODECTY_ULOZIT_KONCEPT: 'odecty.ulozit.koncept',
    ODECTY_ODSTRANIT_CHYBNE: 'odecty.odstranit.chybne',
    ODECTY_IMPORT_GLOBAL_ERRORS: 'odecty.import.global.errors',
    ODECTY_IMPORT_CONFIRM_DELETE_ERRORS: 'odecty.import.confirm.delete.errors',
    ODECTY_IMPORT_CONFIRM_ROW: 'odecty.import.confirm.row',
    ODECTY_IMPORT_CONFIRM_UPDATE_ROW: 'odecty.import.confirm.update.row',
    ODECTY_IMPORT_CONFIRM_ADD_ROW: 'odecty.import.confirm.add.row',
    ODECTY_IMPORT_SAVED_BEFORE: 'odecty.import.saved.before',
    ODECTY_IMPORT_SAVED_AFTER: 'odecty.import.saved.after',
    ODECTY_IMPORT_SENT_BEFORE: 'odecty.import.sent.before',
    ODECTY_IMPORT_SENT_AFTER: 'odecty.import.sent.after',
    ODECTY_IMPORT_ROW_DETAIL_TITLE: 'odecty.import.row.detail.title',
    ODECTY_IMPORT_ROW_DETAIL_COMMON: 'odecty.import.row.detail.common',
    ODECTY_IMPORT_DIALOGTITLE: 'odecty.import.dialogTitle',
    ODECTY_IMPORT_ELEKTRINA_HEADLINE: 'odecty.import.elektrina.headline',
    ODECTY_IMPORT_PLYN_HEADLINE: 'odecty.import.plyn.headline',
    ODECTY_CONFIRM_STORNO_ZADOST: 'odecty.confirm.storno.zadost',
    ODECTY_CONFIRM_DELETE_ZADOST: 'odecty.confirm.delete.zadost',
    ODECTY_CONFIRM_DELETE_DAVKA: 'odecty.confirm.delete.davka',
    ODECTY_CONFIRM_DELETE_LAST_ZADOST: 'odecty.confirm.delete.last.zadost',
    IMPORT_CONFIRM_DELETE_LAST_ROW: 'import.confirm.delete.last.row',
    ZADOSTI_DESCRIPTION: 'zadosti.description',
    ZADOSTI_DAVKA_ODESLANA: 'zadosti.davka.odeslana',
    ZADOSTI_ERROR_MESSAGE: 'zadosti.error.message',
    ZADOSTI_PREHLED_DAVEK: 'zadosti.prehled.davek',
    ZADOSTI_PREHLED_ZADOSTI: 'zadosti.prehled.zadosti',
    ZADOSTI_VYTVORIT_ZADOST: 'zadosti.vytvorit.zadost',
    ZADOSTI_TAB_DAVKY: 'zadosti.tab.davky',
    ZADOSTI_TAB_ZADOSTI: 'zadosti.tab.zadosti',
    ZADOSTI_OBNOVENI: 'zadosti.obnoveni',
    ZADOSTI_PRERUSENI: 'zadosti.preruseni',
    ZADOSTI_SMLOUVA_NN: 'zadosti.smlouva.nn',
    ZADOSTI_SMLOUVA_COMMON_DESCRIPTION: 'zadosti.smlouva.common.description',
    ZADOSTI_SMLOUVA_NN_DESCRIPTION: 'zadosti.smlouva.nn.description',
    ZADOSTI_SMLOUVA_IMPORT_NN: 'zadosti.smlouva.import.nn',
    ZADOSTI_SMLOUVA_IMPORT_VN: 'zadosti.smlouva.import.vn',
    ZADOSTI_SMLOUVA_VN: 'zadosti.smlouva.vn',
    ZADOSTI_SMLOUVA_VN_DESCRIPTION: 'zadosti.smlouva.vn.description',
    ZADOSTI_ZAKAZNIK: 'zadosti.zakaznik',
    ZADOSTI_ZAKAZNIK_POVINNOST_ZVEREJNOVAT_SMLOUVY_TITLE: 'zadosti.zakaznik.povinnost.zverejnovat.smlouvy.title',
    ZADOSTI_ZAKAZNIK_POVINNOST_ZVEREJNOVAT_SMLOUVY: 'zadosti.zakaznik.povinnost.zverejnovat.smlouvy',
    ZADOSTI_KONTAKTNI_UDAJE: 'zadosti.kontaktni.udaje',
    ZADOSTI_ZAKAZNIK_DRUH: 'zadosti.zakaznik.druh',
    ZADOSTI_ODBERNE_MISTO: 'zadosti.odberne.misto',
    ZADOSTI_SPOTREBICE: 'zadosti.spotrebice',
    ZADOSTI_DUVOD: 'zadosti.duvod',
    ZADOSTI_ZAHAJENI_DISTRIBUCE: 'zadosti.zahajeni.distribuce',
    ZADOSTI_UKONCENI_DISTRIBUCE: 'zadosti.ukonceni.distribuce',
    ZADOSTI_SKUTECNY_TERMIN_UCINNOSTI: 'zadosti.skutecny.termin.ucinnosti',
    ZADOSTI_TERMIN_REALIZACE_SZ: 'zadosti.termin.realizace.sz',
    ZADOSTI_UP_POZADOVANY_TERMIN: 'zadosti.up.pozadovany.termin',
    ZADOSTI_TERMIN_OBNOVENI: 'zadosti.termin.obnoveni',
    ZADOSTI_TERMIN_PRERUSENI: 'zadosti.termin.preruseni',
    ZADOSTI_ZAKAZNIK_TYP_FYZICKA_OSOBA: 'zadosti.zakaznik.typ.fyzicka.osoba',
    ZADOSTI_ZAKAZNIK_TYP_PRAVNICKA_OSOBA: 'zadosti.zakaznik.typ.pravnicka.osoba',
    ZADOSTI_ZAKAZNIK_FYZICKA_OSOBA: 'zadosti.zakaznik.fyzicka.osoba',
    ZADOSTI_ZAKAZNIK_PRAVNICKA_OSOBA: 'zadosti.zakaznik.pravnicka.osoba',
    ZADOSTI_ZAKAZNIK_PODNIKATEL: 'zadosti.zakaznik.podnikatel',
    ZADOSTI_ZAKAZNIK_TELEFON: 'zadosti.zakaznik.telefon',
    ZADOSTI_ZAKAZNIK_EMAIL: 'zadosti.zakaznik.email',
    ZADOSTI_ZAKAZNIK_JMENO: 'zadosti.zakaznik.jmeno',
    ZADOSTI_ZAKAZNIK_PRIJMENI: 'zadosti.zakaznik.prijmeni',
    ZADOSTI_ZAKAZNIK_NAZEV: 'zadosti.zakaznik.nazev',
    ZADOSTI_ZAKAZNIK_TYP: 'zadosti.zakaznik.typ',
    ZADOSTI_ZAKAZNIK_NAZEV_FIRMY: 'zadosti.zakaznik.nazev.firmy',
    ZADOSTI_ZAKAZNIK_DATUM_NAROZENI: 'zadosti.zakaznik.datum.narozeni',
    ZADOSTI_ZAKAZNIK_IC: 'zadosti.zakaznik.ic',
    ZADOSTI_ZAKAZNIK_DIC: 'zadosti.zakaznik.dic',
    ZADOSTI_ZAKAZNIK_OKRESNI_SOUD: 'zadosti.zakaznik.okresni.soud',
    ZADOSTI_ZAKAZNIK_VLOZKA_CISLO: 'zadosti.zakaznik.vlozka.cislo',
    ZADOSTI_ZAKAZNIK_ODDIL: 'zadosti.zakaznik.oddil',
    ZADOSTI_ZAKAZNIK_TITUL_PRED: 'zadosti.zakaznik.titul.pred',
    ZADOSTI_ZAKAZNIK_TITUL_ZA: 'zadosti.zakaznik.titul.za',
    ZADOSTI_ZAKAZNIK_RODNE_CISLO: 'zadosti.zakaznik.rodne.cislo',
    ZADOSTI_ZAKAZNIK_TRVALA_ADRESA: 'zadosti.zakaznik.trvala.adresa',
    ZADOSTI_ZAKAZNIK_KORESPONDENCNI_ADRESA: 'zadosti.zakaznik.korespondencni.adresa',
    ZADOSTI_ZAKAZNIK_POBOX: 'zadosti.zakaznik.pobox',
    ZADOSTI_PLNA_MOC_SOUHLAS: 'zadosti.plna.moc.souhlas',
    ZADOSTI_BLOK_ADRESA: 'zadosti.blok.adresa',
    ZADOSTI_PRILOHY: 'zadosti.prilohy',
    ZADOSTI_ZADNA_PRILOHA: 'zadosti.zadna.priloha',
    ZADOSTI_PODEPSAT_VYBRANE: 'zadosti.podepsat.vybrane',
    ZADOSTI_ZAKAZNIK_FAKTURACNI_ADRESA: 'zadosti.zakaznik.fakturacni.adresa',
    ZADOSTI_ZAKAZNIK_ULICE: 'zadosti.zakaznik.ulice',
    ZADOSTI_ZAKAZNIK_CISLO_POPISNE: 'zadosti.zakaznik.cislo.popisne',
    ZADOSTI_ZAKAZNIK_CISLO_EVIDENCNI: 'zadosti.zakaznik.cislo.evidencni',
    ZADOSTI_ZAKAZNIK_CISLO_ORIENTACNI: 'zadosti.zakaznik.cislo.orientacni',
    ZADOSTI_ZAKAZNIK_MESTO: 'zadosti.zakaznik.mesto',
    ZADOSTI_ZAKAZNIK_MISTNI_CAST: 'zadosti.zakaznik.mistni.cast',
    ZADOSTI_ZAKAZNIK_PSC: 'zadosti.zakaznik.psc',
    ZADOSTI_ZAKAZNIK_KONTAKT: 'zadosti.zakaznik.kontakt',
    ZADOSTI_ZAKAZNIK_KONTAKT_JMENO: 'zadosti.zakaznik.kontakt.jmeno',
    ZADOSTI_ZAKAZNIK_KONTAKT_PRIJMENI: 'zadosti.zakaznik.kontakt.prijmeni',
    ZADOSTI_ZAKAZNIK_KONTAKT_EMAIL: 'zadosti.zakaznik.kontakt.email',
    ZADOSTI_ZAKAZNIK_KONTAKT_TELEFON: 'zadosti.zakaznik.kontakt.telefon',
    ZADOSTI_OM_ULICE: 'zadosti.om.ulice',
    ZADOSTI_OM_CISLO_POPISNE: 'zadosti.om.cislo.popisne',
    ZADOSTI_OM_CISLO_ORIENTACNI: 'zadosti.om.cislo.orientacni',
    ZADOSTI_OM_MESTO: 'zadosti.om.mesto',
    ZADOSTI_OM_OBEC: 'zadosti.om.obec',
    ZADOSTI_OM_CISLO_EVIDENCNI: 'zadosti.om.cislo.evidencni',
    ZADOSTI_OM_MISTNI_CAST: 'zadosti.om.mistni.cast',
    ZADOSTI_OM_PSC: 'zadosti.om.psc',
    ZADOSTI_TECH: 'zadosti.tech',
    ZADOSTI_TECH_TYP_MERENI: 'zadosti.tech.typ.mereni',
    ZADOSTI_TECH_REZ_VYKON_KW: 'zadosti.tech.rez.vykon.kw',
    ZADOSTI_TECH_HODNOTA_JISTICE: 'zadosti.tech.hodnota.jistice',
    ZADOSTI_TECH_POCET_FAZI: 'zadosti.tech.pocet.fazi',
    ZADOSTI_TECH_DIST_SAZBA: 'zadosti.tech.dist.sazba',
    ZADOSTI_TECH_TDD: 'zadosti.tech.tdd',
    ZADOSTI_TECH_POCET_FAZI_1: 'zadosti.tech.pocet.fazi.1',
    ZADOSTI_TECH_POCET_FAZI_3: 'zadosti.tech.pocet.fazi.3',
    ZADOSTI_TECH_FREKVENCE_ODECTU: 'zadosti.tech.frekvence.odectu',
    ZADOSTI_TECH_FREKVENCE_1: 'zadosti.tech.frekvence.1',
    ZADOSTI_TECH_FREKVENCE_3: 'zadosti.tech.frekvence.3',
    ZADOSTI_TECH_FREKVENCE_12: 'zadosti.tech.frekvence.12',
    ZADOSTI_TECH_TERMIN_ODECTU: 'zadosti.tech.termin.odectu',
    ZADOSTI_TECH_REZ_VYKON_MW: 'zadosti.tech.rez.vykon.mw',
    ZADOSTI_TECH_REZ_PRIKON_MW: 'zadosti.tech.rez.prikon.mw',
    ZADOSTI_TECH_UMISTENI: 'zadosti.tech.umisteni',
    ZADOSTI_TECH_TRANSFORMATOR: 'zadosti.tech.transformator',
    ZADOSTI_KAPACITA: 'zadosti.kapacita',
    ZADOSTI_KAPACITA_ROCNI: 'zadosti.kapacita.rocni',
    ZADOSTI_KAPACITA_LEDEN: 'zadosti.kapacita.leden',
    ZADOSTI_KAPACITA_UNOR: 'zadosti.kapacita.unor',
    ZADOSTI_KAPACITA_BREZEN: 'zadosti.kapacita.brezen',
    ZADOSTI_KAPACITA_DUBEN: 'zadosti.kapacita.duben',
    ZADOSTI_KAPACITA_KVETEN: 'zadosti.kapacita.kveten',
    ZADOSTI_KAPACITA_CERVEN: 'zadosti.kapacita.cerven',
    ZADOSTI_KAPACITA_CERVENEC: 'zadosti.kapacita.cervenec',
    ZADOSTI_KAPACITA_SRPEN: 'zadosti.kapacita.srpen',
    ZADOSTI_KAPACITA_ZARI: 'zadosti.kapacita.zari',
    ZADOSTI_KAPACITA_RIJEN: 'zadosti.kapacita.rijen',
    ZADOSTI_KAPACITA_LISTOPAD: 'zadosti.kapacita.listopad',
    ZADOSTI_KAPACITA_PROSINEC: 'zadosti.kapacita.prosinec',
    ZADOSTI_TYP: 'zadosti.typ',
    ZADOSTI_EAN: 'zadosti.ean',
    ZADOSTI_EIC: 'zadosti.eic',
    ZADOSTI_IC_DATUM_NAROZENI: 'zadosti.ic.datum.narozeni',
    ZADOSTI_JMENO: 'zadosti.jmeno',
    ZADOSTI_POZADOVANY_TERMIN: 'zadosti.pozadovany.termin',
    ZADOSTI_TELEFON: 'zadosti.telefon',
    ZADOSTI_EMAIL: 'zadosti.email',
    ZADOSTI_IMPORTOVANA_DATA: 'zadosti.importovana.data',
    ZADOSTI_REG_STUPNE: 'zadosti.reg.stupne',
    ZADOSTI_REG_STUPNE_3: 'zadosti.reg.stupne.3',
    ZADOSTI_REG_STUPNE_4: 'zadosti.reg.stupne.4',
    ZADOSTI_REG_STUPNE_5: 'zadosti.reg.stupne.5',
    ZADOSTI_REG_STUPNE_6: 'zadosti.reg.stupne.6',
    ZADOSTI_REG_STUPNE_7_KW: 'zadosti.reg.stupne.7.kw',
    ZADOSTI_REG_STUPNE_7_HOD: 'zadosti.reg.stupne.7.hod',
    ZADOSTI_REG_STUPNE_KONTAKT: 'zadosti.reg.stupne.kontakt',
    ZADOSTI_REG_STUPNE_KONTAKT_JMENO: 'zadosti.reg.stupne.kontakt.jmeno',
    ZADOSTI_REG_STUPNE_KONTAKT_PRIJMENI: 'zadosti.reg.stupne.kontakt.prijmeni',
    ZADOSTI_REG_STUPNE_KONTAKT_EMAIL: 'zadosti.reg.stupne.kontakt.email',
    ZADOSTI_REG_STUPNE_KONTAKT_TELEFON: 'zadosti.reg.stupne.kontakt.telefon',
    ZADOSTI_OSTATNI_POZNAMKA: 'zadosti.ostatni.poznamka',
    ZADOSTI_OSTATNI_PRILOHA: 'zadosti.ostatni.priloha',
    ZADOSTI_CISLO_DAVKY: 'zadosti.cislo.davky',
    ZADOSTI_ZAHAJENI_ODBERU: 'zadosti.zahajeni.odberu',
    ZADOSTI_PODPIS_SMLOUVY: 'zadosti.podpis.smlouvy',
    ZADOSTI_SMLOUVY: 'zadosti.smlouvy',
    ZADOSTI_CISLO_ZADOSTI: 'zadosti.cislo.zadosti',
    ZADOSTI_TYP_ZADOSTI: 'zadosti.typ.zadosti',
    ZADOSTI_TYP_NN: 'zadosti.typ.NN',
    ZADOSTI_TYP_VN: 'zadosti.typ.VN',
    ZADOSTI_TYP_VSE: 'zadosti.typ.VSE',
    ZADOSTI_TYP_OBNOVENI: 'zadosti.typ.OBNOVENI',
    ZADOSTI_TYP_PRERUSENI: 'zadosti.typ.PRERUSENI',
    ZADOSTI_NAPETOVA_HLADINA: 'zadosti.napetova.hladina',
    ZADOSTI_NAPETOVA_HLADINA_OSTATNI: 'zadosti.napetova.hladina.OSTATNI',
    ZADOSTI_NAPETOVA_HLADINA_ZVN: 'zadosti.napetova.hladina.ZVN',
    ZADOSTI_NAPETOVA_HLADINA_VVN: 'zadosti.napetova.hladina.VVN',
    ZADOSTI_NAPETOVA_HLADINA_VN: 'zadosti.napetova.hladina.VN',
    ZADOSTI_NAPETOVA_HLADINA_NN: 'zadosti.napetova.hladina.NN',
    ZADOSTI_NAPETOVA_HLADINA_MISTNI_SIT: 'zadosti.napetova.hladina.MISTNI_SIT',
    ZADOSTI_NAPETOVA_HLADINA_DALKOVOD: 'zadosti.napetova.hladina.DALKOVOD',
    ZADOSTI_NAPETOVA_HLADINA_VSE: 'zadosti.napetova.hladina.VSE',
    ZADOSTI_DETAIL_POZADOVANY_TERMIN_PRERUSENI: 'zadosti.detail.pozadovany.termin.preruseni',
    ZADOSTI_DETAIL_POZADOVANY_TERMIN_OBNOVENI: 'zadosti.detail.pozadovany.termin.obnoveni',
    ZADOSTI_POZADOVANY_TERMIN_PRERUSENI: 'zadosti.pozadovany.termin.preruseni',
    ZADOSTI_POZADOVANY_TERMIN_OBNOVENI: 'zadosti.pozadovany.termin.obnoveni',
    ZADOSTI_RS_FILTRATION_CISLO_DAVKY: 'zadosti.rs.filtration.cislo.davky',
    ZADOSTI_RS_FILTRATION_CISLO_ZADOSTI: 'zadosti.rs.filtration.cislo.zadosti',
    ZADOSTI_RS_DAVKY_FILTRATION_TYP_ZADOSTI: 'zadosti.rs.davky.filtration.typ.zadosti',
    ZADOSTI_RS_DAVKY_FILTRATION_DATUM_ZAHAJENI_DISTRIBUCE_OD: 'zadosti.rs.davky.filtration.datum.zahajeni.distribuce.od',
    ZADOSTI_RS_DAVKY_FILTRATION_DATUM_ZAHAJENI_DISTRIBUCE_DO: 'zadosti.rs.davky.filtration.datum.zahajeni.distribuce.do',
    ZADOSTI_RS_DAVKY_FILTRATION_DATUM_UKONCENI_DISTRIBUCE_OD: 'zadosti.rs.davky.filtration.datum.ukonceni.distribuce.od',
    ZADOSTI_RS_DAVKY_FILTRATION_DATUM_UKONCENI_DISTRIBUCE_DO: 'zadosti.rs.davky.filtration.datum.ukonceni.distribuce.do',
    ZADOSTI_RS_DAVKY_FILTRATION_DATUM_VYTVORENI_OD: 'zadosti.rs.davky.filtration.datum.vytvoreni.od',
    ZADOSTI_RS_DAVKY_FILTRATION_DATUM_VYTVORENI_DO: 'zadosti.rs.davky.filtration.datum.vytvoreni.do',
    ZADOSTI_RS_DAVKY_FILTRATION_DATUM_ODESLANI_OD: 'zadosti.rs.davky.filtration.datum.odeslani.od',
    ZADOSTI_RS_DAVKY_FILTRATION_DATUM_ODESLANI_DO: 'zadosti.rs.davky.filtration.datum.odeslani.do',
    ZADOSTI_RS_DAVKY_FILTRATION_STAV_ODESLANI: 'zadosti.rs.davky.filtration.stav.odeslani',
    ZADOSTI_RS_DAVKY_FILTRATION_STAV_ODESLANI_VSE: 'zadosti.rs.davky.filtration.stav.odeslani.vse',
    ZADOSTI_RS_DAVKY_FILTRATION_STAV_ODESLANI_CHYBNE: 'zadosti.rs.davky.filtration.stav.odeslani.chybne',
    ZADOSTI_UP_DAVKY_FILTRATION_TYP_ZADOSTI: 'zadosti.up.davky.filtration.typ.zadosti',
    ZADOSTI_UP_DAVKY_FILTRATION_DATUM_ZAHAJENI_DISTRIBUCE_OD: 'zadosti.up.davky.filtration.datum.zahajeni.distribuce.od',
    ZADOSTI_UP_DAVKY_FILTRATION_DATUM_ZAHAJENI_DISTRIBUCE_DO: 'zadosti.up.davky.filtration.datum.zahajeni.distribuce.do',
    ZADOSTI_UP_DAVKY_FILTRATION_DATUM_UKONCENI_DISTRIBUCE_OD: 'zadosti.up.davky.filtration.datum.ukonceni.distribuce.od',
    ZADOSTI_UP_DAVKY_FILTRATION_DATUM_UKONCENI_DISTRIBUCE_DO: 'zadosti.up.davky.filtration.datum.ukonceni.distribuce.do',
    ZADOSTI_UP_DAVKY_FILTRATION_DATUM_VYTVORENI_OD: 'zadosti.up.davky.filtration.datum.vytvoreni.od',
    ZADOSTI_UP_DAVKY_FILTRATION_DATUM_VYTVORENI_DO: 'zadosti.up.davky.filtration.datum.vytvoreni.do',
    ZADOSTI_UP_DAVKY_FILTRATION_DATUM_ODESLANI_OD: 'zadosti.up.davky.filtration.datum.odeslani.od',
    ZADOSTI_UP_DAVKY_FILTRATION_DATUM_ODESLANI_DO: 'zadosti.up.davky.filtration.datum.odeslani.do',
    ZADOSTI_UP_DAVKY_FILTRATION_STAV_ODESLANI: 'zadosti.up.davky.filtration.stav.odeslani',
    ZADOSTI_UP_DAVKY_FILTRATION_STAV_ODESLANI_VSE: 'zadosti.up.davky.filtration.stav.odeslani.vse',
    ZADOSTI_UP_DAVKY_FILTRATION_STAV_ODESLANI_CHYBNE: 'zadosti.up.davky.filtration.stav.odeslani.chybne',
    ZADOSTI_SOP_DAVKY_FILTRATION_DATUM_VYTVORENI_OD: 'zadosti.sop.davky.filtration.datum.vytvoreni.od',
    ZADOSTI_SOP_DAVKY_FILTRATION_DATUM_VYTVORENI_DO: 'zadosti.sop.davky.filtration.datum.vytvoreni.do',
    ZADOSTI_SOP_DAVKY_FILTRATION_DATUM_ODESLANI_OD: 'zadosti.sop.davky.filtration.datum.odeslani.od',
    ZADOSTI_SOP_DAVKY_FILTRATION_DATUM_ODESLANI_DO: 'zadosti.sop.davky.filtration.datum.odeslani.do',
    ZADOSTI_SOP_DAVKY_FILTRATION_STAV_ODESLANI: 'zadosti.sop.davky.filtration.stav.odeslani',
    ZADOSTI_SOP_DAVKY_FILTRATION_STAV_ODESLANI_VSE: 'zadosti.sop.davky.filtration.stav.odeslani.vse',
    ZADOSTI_SOP_DAVKY_FILTRATION_STAV_ODESLANI_CHYBNE: 'zadosti.sop.davky.filtration.stav.odeslani.chybne',
    ZADOSTI_SOP_PREPIS_OBCHODNI_PARTNER_DRUH_P_PO: 'zadosti.sop.prepis.obchodni.partner.druh.P_PO',
    ZADOSTI_SOP_PREPIS_OBCHODNI_PARTNER_DRUH_PODNIKATEL_PRAVNICKA_OSOBA: 'zadosti.sop.prepis.obchodni.partner.druh.PODNIKATEL_PRAVNICKA_OSOBA',
    ZADOSTI_SOP_PREPIS_OBCHODNI_PARTNER_DRUH_P_FO_RC: 'zadosti.sop.prepis.obchodni.partner.druh.P_FO_RC',
    ZADOSTI_SOP_PREPIS_OBCHODNI_PARTNER_DRUH_P_FO_DN: 'zadosti.sop.prepis.obchodni.partner.druh.P_FO_DN',
    ZADOSTI_SOP_PREPIS_OBCHODNI_PARTNER_DRUH_P_FO_IC: 'zadosti.sop.prepis.obchodni.partner.druh.P_FO_IC',
    ZADOSTI_SOP_PREPIS_OBCHODNI_PARTNER_DRUH_FO: 'zadosti.sop.prepis.obchodni.partner.druh.FO',
    ZADOSTI_SOP_PREPIS_OBCHODNI_PARTNER_DRUH_FYZICKA_OSOBA_FO: 'zadosti.sop.prepis.obchodni.partner.druh.FYZICKA_OSOBA_FO',
    ZADOSTI_SOP_HROMADNY_IMPORT: 'zadosti.sop.hromadny.import',
    ZADOSTI_SOP_HROMADNY_IMPORT_LOADING_DESCRIPTION: 'zadosti.sop.hromadny.import.loading.description',
    ZADOSTI_SOP_HROMADNY_IMPORT_EXAMPLE: 'zadosti.sop.hromadny.import.example',
    ZADOSTI_SOP_HROMADNY_IMPORT_ERROR: 'zadosti.sop.hromadny.import.error',
    ZADOSTI_SOP_HROMADNY_IMPORT_SUCCESS: 'zadosti.sop.hromadny.import.success',
    ZADOSTI_SOP_OSTATNI: 'zadosti.sop.ostatni',
    ZADOSTI_SOP_PODEKOVANI_TEXT_1: 'zadosti.sop.podekovani.text.1',
    ZADOSTI_SOP_PODEKOVANI_TEXT_2: 'zadosti.sop.podekovani.text.2',
    ZADOSTI_SOP_PODEKOVANI_TEXT_3: 'zadosti.sop.podekovani.text.3',
    ZADOSTI_SOP_EICEAN: 'zadosti.sop.eicean',
    ZADOSTI_SOP_OSTATNI_ZAKAZNICI: 'zadosti.sop.ostatni.zakaznici',
    ZADOSTI_SOP_OSTATNI_NASEPTAVAC: 'zadosti.sop.ostatni.naseptavac',
    ZADOSTI_SOP_OSTATNI_OBEC: 'zadosti.sop.ostatni.obec',
    ZADOSTI_SOP_OSTATNI_ADD_SOP: 'zadosti.sop.ostatni.add.sop',
    ZADOSTI_SOP_OSTATNI_ADD_SOP_TITLE_DIALOG: 'zadosti.sop.ostatni.add.sop.title.dialog',
    ZADOSTI_SOP_OSTATNI_STAV_VSE: 'zadosti.sop.ostatni.stav.VSE',
    ZADOSTI_SOP_OSTATNI_STAV_ODESLANO: 'zadosti.sop.ostatni.stav.ODESLANO',
    ZADOSTI_SOP_OSTATNI_STAV_SMLOUVA_ZAEVIDOVANA: 'zadosti.sop.ostatni.stav.SMLOUVA_ZAEVIDOVANA',
    ZADOSTI_SOP_OSTATNI_STAV_ZADOST_ZAMITNUTA: 'zadosti.sop.ostatni.stav.ZADOST_ZAMITNUTA',
    ZADOSTI_SOP_OSTATNI_STAV_SMLOUVA_ODESLANA: 'zadosti.sop.ostatni.stav.SMLOUVA_ODESLANA',
    ZADOSTI_SOP_OSTATNI_STAV_ZADOST_STORNO: 'zadosti.sop.ostatni.stav.ZADOST_STORNO',
    ZADOSTI_SOP_OSTATNI_STAV_SMLOUVA_NEVRACENA_V_TERMINU: 'zadosti.sop.ostatni.stav.SMLOUVA_NEVRACENA_V_TERMINU',
    ZADOSTI_SOP_OSTATNI_STAV_SMLOUVA_PLATNA: 'zadosti.sop.ostatni.stav.SMLOUVA_PLATNA',
    ZADOSTI_SOP_OSTATNI_STAV_SMLOUVA_UKONCENA: 'zadosti.sop.ostatni.stav.SMLOUVA_UKONCENA',
    ZADOSTI_SOP_OSTATNI_STAV_DILO_DOKONCENO: 'zadosti.sop.ostatni.stav.DILO_DOKONCENO',
    ZADOSTI_SOP_OSTATNI_STAV_OPM_PRIPRAVENO: 'zadosti.sop.ostatni.stav.OPM_PRIPRAVENO',
    ZADOSTI_SOP_OSTATNI_STAV_SMLOUVA_V_RESENI: 'zadosti.sop.ostatni.stav.SMLOUVA_V_RESENI',
    ZADOSTI_SOP_OSTATNI_STAV_NESPLNENY_PODMINKY_PPDS: 'zadosti.sop.ostatni.stav.NESPLNENY_PODMINKY_PPDS',
    ZADOSTI_SOP_OSTATNI_STAV_SMLOUVA_VRACENA_NEPODEPSANA: 'zadosti.sop.ostatni.stav.SMLOUVA_VRACENA_NEPODEPSANA',
    ZADOSTI_SOP_OSTATNI_STAV_PRIJATO: 'zadosti.sop.ostatni.stav.PRIJATO',
    ZADOSTI_SOP_OSTATNI_STAV_ZAMITNUTO: 'zadosti.sop.ostatni.stav.ZAMITNUTO',
    ZADOSTI_SOP_OSTATNI_STAV_REALIZOVANO: 'zadosti.sop.ostatni.stav.REALIZOVANO',
    ZADOSTI_SOP_OSTATNI_TYP_ELEKTRINA_NOVY_KRATKODOBY_ODBER_NN: 'zadosti.sop.ostatni.typ.ELEKTRINA_NOVY_KRATKODOBY_ODBER_NN',
    ZADOSTI_SOP_OSTATNI_TYP_ELEKTRINA_NOVY_KRATKODOBY_ODBER_VN: 'zadosti.sop.ostatni.typ.ELEKTRINA_NOVY_KRATKODOBY_ODBER_VN',
    ZADOSTI_SOP_OSTATNI_TYP_ELEKTRINA_NOVY_TRVALY_ODBER_NN: 'zadosti.sop.ostatni.typ.ELEKTRINA_NOVY_TRVALY_ODBER_NN',
    ZADOSTI_SOP_OSTATNI_TYP_ELEKTRINA_NOVY_TRVALY_ODBER_VN: 'zadosti.sop.ostatni.typ.ELEKTRINA_NOVY_TRVALY_ODBER_VN',
    ZADOSTI_SOP_OSTATNI_TYP_ELEKTRINA_NOVY_TRVALY_ODBER_PLANOVANY: 'zadosti.sop.ostatni.typ.ELEKTRINA_NOVY_TRVALY_ODBER_PLANOVANY',
    ZADOSTI_SOP_OSTATNI_TYP_ELEKTRINA_ZMENA_ODBERU_PRIPOJENI_ZDROJE: 'zadosti.sop.ostatni.typ.ELEKTRINA_ZMENA_ODBERU_PRIPOJENI_ZDROJE',
    ZADOSTI_SOP_OSTATNI_TYP_ELEKTRINA_ZMENA_ODBERU_ZMENA_CHARAKTERU: 'zadosti.sop.ostatni.typ.ELEKTRINA_ZMENA_ODBERU_ZMENA_CHARAKTERU',
    ZADOSTI_SOP_OSTATNI_TYP_ELEKTRINA_ZMENA_ODBERU_ZJEDNODUSENE_PRIPOJENI: 'zadosti.sop.ostatni.typ.ELEKTRINA_ZMENA_ODBERU_ZJEDNODUSENE_PRIPOJENI',
    ZADOSTI_SOP_OSTATNI_TYP_PLYN_NOVY_ODBER: 'zadosti.sop.ostatni.typ.PLYN_NOVY_ODBER',
    ZADOSTI_SOP_OSTATNI_TYP_NOVY_ODBER: 'zadosti.sop.ostatni.typ.NOVY_ODBER',
    ZADOSTI_SOP_OSTATNI_TYP_NOVA_VYROBA: 'zadosti.sop.ostatni.typ.NOVA_VYROBA',
    ZADOSTI_SOP_OSTATNI_TYP_ZMENA_ODBERATELE: 'zadosti.sop.ostatni.typ.ZMENA_ODBERATELE',
    ZADOSTI_SOP_OSTATNI_TYP_ZMENA_PRIKONU: 'zadosti.sop.ostatni.typ.ZMENA_PRIKONU',
    ZADOSTI_SOP_OSTATNI_TYP_ZMENA_SAZBY: 'zadosti.sop.ostatni.typ.ZMENA_SAZBY',
    ZADOSTI_SOP_OSTATNI_TYP_MIKROZDROJ: 'zadosti.sop.ostatni.typ.MIKROZDROJ',
    ZADOSTI_SOP_OSTATNI_TYP_VSE: 'zadosti.sop.ostatni.typ.VSE',
    ZADOSTI_SOP_OSTATNI_FILTER_EAN: 'zadosti.sop.ostatni.filter.ean',
    ZADOSTI_SOP_OSTATNI_FILTER_VYTVORENO_OD: 'zadosti.sop.ostatni.filter.vytvoreno.od',
    ZADOSTI_SOP_OSTATNI_FILTER_VYTVORENO_DO: 'zadosti.sop.ostatni.filter.vytvoreno.do',
    ZADOSTI_SOP_OSTATNI_FILTER_TYP: 'zadosti.sop.ostatni.filter.typ',
    ZADOSTI_SOP_OSTATNI_FILTER_CISLO_SOP: 'zadosti.sop.ostatni.filter.cislo.sop',
    ZADOSTI_SOP_OSTATNI_FILTER_EIC: 'zadosti.sop.ostatni.filter.eic',
    ZADOSTI_SOP_OSTATNI_FILTER_ODESLANO_OD: 'zadosti.sop.ostatni.filter.odeslano.od',
    ZADOSTI_SOP_OSTATNI_FILTER_ODESLANO_DO: 'zadosti.sop.ostatni.filter.odeslano.do',
    ZADOSTI_SOP_OSTATNI_TABLE_EAN_SPOTREBNI: 'zadosti.sop.ostatni.table.ean.spotrebni',
    ZADOSTI_SOP_OSTATNI_TABLE_EAN_VYROBNI: 'zadosti.sop.ostatni.table.ean.vyrobni',
    ZADOSTI_SOP_OSTATNI_TABLE_TYP: 'zadosti.sop.ostatni.table.typ',
    ZADOSTI_SOP_OSTATNI_TABLE_STAV: 'zadosti.sop.ostatni.table.stav',
    ZADOSTI_SOP_OSTATNI_TABLE_VYTVORENO: 'zadosti.sop.ostatni.table.vytvoreno',
    ZADOSTI_SOP_OSTATNI_TABLE_VYTVORIL: 'zadosti.sop.ostatni.table.vytvoril',
    ZADOSTI_SOP_OSTATNI_TABLE_ZAKAZNIK: 'zadosti.sop.ostatni.table.zakaznik',
    ZADOSTI_SOP_OSTATNI_TABLE_CISLO_SOP: 'zadosti.sop.ostatni.table.cislo.sop',
    ZADOSTI_SOP_OSTATNI_TABLE_EIC: 'zadosti.sop.ostatni.table.eic',
    ZADOSTI_SOP_OSTATNI_TABLE_ODKAZ: 'zadosti.sop.ostatni.table.odkaz',
    ZADOSTI_SOP_OSTATNI_TABLE_PARSEID: 'zadosti.sop.ostatni.table.parseid',
    ZADOSTI_SOP_OSTATNI_TABLE_DATUM_ZALOZENI: 'zadosti.sop.ostatni.table.datum.zalozeni',
    ZADOSTI_SOP_OSTATNI_MENU_ROZPRACOVANE: 'zadosti.sop.ostatni.menu.ROZPRACOVANE',
    ZADOSTI_SOP_OSTATNI_ROZPRACOVANE_SUBTITLE: 'zadosti.sop.ostatni.rozpracovane.subtitle',
    ZADOSTI_SOP_OSTATNI_MENU_ROZPRACOVANO: 'zadosti.sop.ostatni.menu.rozpracovano',
    ZADOSTI_SOP_OSTATNI_MENU_VE_ZPRACOVANI: 'zadosti.sop.ostatni.menu.VE_ZPRACOVANI',
    ZADOSTI_SOP_OSTATNI_INFO_TITLE: 'zadosti.sop.ostatni.info.title',
    ZADOSTI_SOP_OSTATNI_INFO: 'zadosti.sop.ostatni.info',
    ROZCESTNIK_KRATKODOBY_ODBER_DESCRIPTION: 'rozcestnik.KRATKODOBY_ODBER.description',
    ROZCESTNIK_KRATKODOBY_ODBER_LABEL: 'rozcestnik.KRATKODOBY_ODBER.label',
    ROZCESTNIK_NEZNAMY_KROK: 'rozcestnik.neznamy.krok',
    ROZCESTNIK_NN_DESCRIPTION: 'rozcestnik.NN.description',
    ROZCESTNIK_NN_LABEL: 'rozcestnik.NN.label',
    ROZCESTNIK_NOVY_ODBER_DESCRIPTION: 'rozcestnik.NOVY_ODBER.description',
    ROZCESTNIK_NOVY_ODBER_LABEL: 'rozcestnik.NOVY_ODBER.label',
    ROZCESTNIK_PLANOVANY_ZDROJ_DESCRIPTION: 'rozcestnik.PLANOVANY_ZDROJ.description',
    ROZCESTNIK_PLANOVANY_ZDROJ_LABEL: 'rozcestnik.PLANOVANY_ZDROJ.label',
    ROZCESTNIK_PRIPOJENI_ZDROJE_DESCRIPTION: 'rozcestnik.PRIPOJENI_ZDROJE.description',
    ROZCESTNIK_PRIPOJENI_ZDROJE_LABEL: 'rozcestnik.PRIPOJENI_ZDROJE.label',
    ROZCESTNIK_STEP_SIX_DESCRIPTION: 'rozcestnik.step.six.description',
    ROZCESTNIK_STEP_FIVE_DESCRIPTION: 'rozcestnik.step.five.description',
    ROZCESTNIK_STEP_SIX_TITLE: 'rozcestnik.step.six.title',
    ROZCESTNIK_STEP_FIVE_TITLE: 'rozcestnik.step.five.title',
    ROZCESTNIK_STEP_FOUR_DESCRIPTION: 'rozcestnik.step.four.description',
    ROZCESTNIK_STEP_FOUR_TITLE: 'rozcestnik.step.four.title',
    ROZCESTNIK_STEP_ONE_DESCRIPTION: 'rozcestnik.step.one.description',
    ROZCESTNIK_STEP_ONE_TITLE: 'rozcestnik.step.one.title',
    ROZCESTNIK_STEP_THREE_DESCRIPTION: 'rozcestnik.step.three.description',
    ROZCESTNIK_STEP_THREE_TITLE: 'rozcestnik.step.three.title',
    ROZCESTNIK_STEP_TWO_DESCRIPTION_PLYN: 'rozcestnik.step.two.description.plyn',
    ROZCESTNIK_STEP_TWO_DESCRIPTION: 'rozcestnik.step.two.description',
    ROZCESTNIK_STEP_TWO_TITLE: 'rozcestnik.step.two.title',
    ROZCESTNIK_TRVALY_ODBER_DESCRIPTION: 'rozcestnik.TRVALY_ODBER.description',
    ROZCESTNIK_TRVALY_ODBER_LABEL: 'rozcestnik.TRVALY_ODBER.label',
    ROZCESTNIK_VN_DESCRIPTION: 'rozcestnik.VN.description',
    ROZCESTNIK_VN_LABEL: 'rozcestnik.VN.label',
    ROZCESTNIK_VVN_LABEL: 'rozcestnik.VVN.label',
    ROZCESTNIK_ZJEDNODUSENE_PRIPOJENI_DESCRIPTION: 'rozcestnik.ZJEDNODUSENE_PRIPOJENI.description',
    ROZCESTNIK_ZJEDNODUSENE_PRIPOJENI_LABEL: 'rozcestnik.ZJEDNODUSENE_PRIPOJENI.label',
    ROZCESTNIK_ZMENA_CHARAKTERU_DESCRIPTION: 'rozcestnik.ZMENA_CHARAKTERU.description',
    ROZCESTNIK_ZMENA_CHARAKTERU_LABEL: 'rozcestnik.ZMENA_CHARAKTERU.label',
    ROZCESTNIK_ZMENA_STAVAJICIHO_ODBERU_DESCRIPTION: 'rozcestnik.ZMENA_STAVAJICIHO_ODBERU.description',
    ROZCESTNIK_ZMENA_STAVAJICIHO_ODBERU_LABEL: 'rozcestnik.ZMENA_STAVAJICIHO_ODBERU.label',
    ZADOSTI_RDK_DAVKY_FILTRATION_TYP_ZADOSTI: 'zadosti.rdk.davky.filtration.typ.zadosti',
    ZADOSTI_RDK_DAVKY_FILTRATION_DATUM_ZAHAJENI_DISTRIBUCE_OD: 'zadosti.rdk.davky.filtration.datum.zahajeni.distribuce.od',
    ZADOSTI_RDK_DAVKY_FILTRATION_DATUM_ZAHAJENI_DISTRIBUCE_DO: 'zadosti.rdk.davky.filtration.datum.zahajeni.distribuce.do',
    ZADOSTI_RDK_DAVKY_FILTRATION_DATUM_UKONCENI_DISTRIBUCE_OD: 'zadosti.rdk.davky.filtration.datum.ukonceni.distribuce.od',
    ZADOSTI_RDK_DAVKY_FILTRATION_DATUM_UKONCENI_DISTRIBUCE_DO: 'zadosti.rdk.davky.filtration.datum.ukonceni.distribuce.do',
    ZADOSTI_RDK_DAVKY_FILTRATION_DATUM_VYTVORENI_OD: 'zadosti.rdk.davky.filtration.datum.vytvoreni.od',
    ZADOSTI_RDK_DAVKY_FILTRATION_DATUM_VYTVORENI_DO: 'zadosti.rdk.davky.filtration.datum.vytvoreni.do',
    ZADOSTI_RDK_DAVKY_FILTRATION_DATUM_ODESLANI_OD: 'zadosti.rdk.davky.filtration.datum.odeslani.od',
    ZADOSTI_RDK_DAVKY_FILTRATION_DATUM_ODESLANI_DO: 'zadosti.rdk.davky.filtration.datum.odeslani.do',
    ZADOSTI_RDK_DAVKY_FILTRATION_STAV_ODESLANI: 'zadosti.rdk.davky.filtration.stav.odeslani',
    ZADOSTI_RDK_DAVKY_FILTRATION_STAV_ODESLANI_VSE: 'zadosti.rdk.davky.filtration.stav.odeslani.vse',
    ZADOSTI_RDK_DAVKY_FILTRATION_STAV_ODESLANI_CHYBNE: 'zadosti.rdk.davky.filtration.stav.odeslani.chybne',
    ZADOSTI_OBN_DAVKY_FILTRATION_TYP_ZADOSTI: 'zadosti.obn.davky.filtration.typ.zadosti',
    ZADOSTI_OBN_DAVKY_FILTRATION_DATUM_ZAHAJENI_DISTRIBUCE_OD: 'zadosti.obn.davky.filtration.datum.zahajeni.distribuce.od',
    ZADOSTI_OBN_DAVKY_FILTRATION_DATUM_ZAHAJENI_DISTRIBUCE_DO: 'zadosti.obn.davky.filtration.datum.zahajeni.distribuce.do',
    ZADOSTI_OBN_DAVKY_FILTRATION_DATUM_UKONCENI_DISTRIBUCE_OD: 'zadosti.obn.davky.filtration.datum.ukonceni.distribuce.od',
    ZADOSTI_OBN_DAVKY_FILTRATION_DATUM_UKONCENI_DISTRIBUCE_DO: 'zadosti.obn.davky.filtration.datum.ukonceni.distribuce.do',
    ZADOSTI_OBN_DAVKY_FILTRATION_DATUM_VYTVORENI_OD: 'zadosti.obn.davky.filtration.datum.vytvoreni.od',
    ZADOSTI_OBN_DAVKY_FILTRATION_DATUM_VYTVORENI_DO: 'zadosti.obn.davky.filtration.datum.vytvoreni.do',
    ZADOSTI_OBN_DAVKY_FILTRATION_DATUM_ODESLANI_OD: 'zadosti.obn.davky.filtration.datum.odeslani.od',
    ZADOSTI_OBN_DAVKY_FILTRATION_DATUM_ODESLANI_DO: 'zadosti.obn.davky.filtration.datum.odeslani.do',
    ZADOSTI_OBN_DAVKY_FILTRATION_STAV_ODESLANI: 'zadosti.obn.davky.filtration.stav.odeslani',
    ZADOSTI_OBN_DAVKY_FILTRATION_STAV_ODESLANI_VSE: 'zadosti.obn.davky.filtration.stav.odeslani.vse',
    ZADOSTI_OBN_DAVKY_FILTRATION_STAV_ODESLANI_CHYBNE: 'zadosti.obn.davky.filtration.stav.odeslani.chybne',
    ZADOSTI_FILTRATION_TYP_ZADOSTI: 'zadosti.filtration.typ.zadosti',
    ZADOSTI_FILTRATION_STAV_RESENI_ZADOSTI: 'zadosti.filtration.stav.reseni.zadosti',
    ZADOSTI_FILTRATION_POZADOVANY_TERMIN_OD: 'zadosti.filtration.pozadovany.termin.od',
    ZADOSTI_FILTRATION_POZADOVANY_TERMIN_DO: 'zadosti.filtration.pozadovany.termin.do',
    ZADOSTI_FILTRATION_ZAHAJENI_ODBERU_OD: 'zadosti.filtration.zahajeni.odberu.od',
    ZADOSTI_FILTRATION_ZAHAJENI_ODBERU_DO: 'zadosti.filtration.zahajeni.odberu.do',
    ZADOSTI_FILTRATION_DUVOD_ZADOSTI: 'zadosti.filtration.duvod.zadosti',
    ZADOSTI_FILTRATION_DUVOD_ZAMITNUTI: 'zadosti.filtration.duvod.zamitnuti',
    ZADOSTI_FILTRATION_DUVOD_STORNA: 'zadosti.filtration.duvod.storna',
    ZADOSTI_PO_ZADOSTI_FILTRATION_DATUM_PRERUSENI_OD: 'zadosti.po.zadosti.filtration.datum.preruseni.od',
    ZADOSTI_PO_ZADOSTI_FILTRATION_DATUM_PRERUSENI_DO: 'zadosti.po.zadosti.filtration.datum.preruseni.do',
    ZADOSTI_PO_ZADOSTI_FILTRATION_DATUM_OBNOVENI_OD: 'zadosti.po.zadosti.filtration.datum.obnoveni.od',
    ZADOSTI_PO_ZADOSTI_FILTRATION_DATUM_OBNOVENI_DO: 'zadosti.po.zadosti.filtration.datum.obnoveni.do',
    ZADOSTI_STAV_RESENI: 'zadosti.stav.reseni',
    ZADOSTI_STAV_RESENI_VSE_VSE: 'zadosti.stav.reseni.vse.vse',
    ZADOSTI_STAV_RESENI_RS_VSE: 'zadosti.stav.reseni.rs.vse',
    ZADOSTI_STAV_RESENI_RS_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.rs.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_RS_OPAKOVANI_PRIPRAVENOST: 'zadosti.stav.reseni.rs.OPAKOVANI_PRIPRAVENOST',
    ZADOSTI_STAV_RESENI_RS_ZADAN_POZADAVEK_NA_RDK: 'zadosti.stav.reseni.rs.ZADAN_POZADAVEK_NA_RDK',
    ZADOSTI_STAV_RESENI_RS_OPAKOVANI: 'zadosti.stav.reseni.rs.OPAKOVANI',
    ZADOSTI_STAV_RESENI_RS_ZADAN_POZADAVEK_NA_RS: 'zadosti.stav.reseni.rs.ZADAN_POZADAVEK_NA_RS',
    ZADOSTI_STAV_RESENI_RS_POZADAVEK_ZAMITNUT: 'zadosti.stav.reseni.rs.POZADAVEK_ZAMITNUT',
    ZADOSTI_STAV_RESENI_RS_POZADAVEK_NA_RS_ZAMITNUT: 'zadosti.stav.reseni.rs.POZADAVEK_NA_RS_ZAMITNUT',
    ZADOSTI_STAV_RESENI_RS_POZADAVEK_PRIJAT_MANUAL: 'zadosti.stav.reseni.rs.POZADAVEK_PRIJAT_MANUAL',
    ZADOSTI_STAV_RESENI_RS_PRIJATO: 'zadosti.stav.reseni.rs.PRIJATO',
    ZADOSTI_STAV_RESENI_RS_PRIJATO_HLEDANI_TERMINU_REALIZACE: 'zadosti.stav.reseni.rs.PRIJATO_HLEDANI_TERMINU_REALIZACE',
    ZADOSTI_STAV_RESENI_RS_PRIJATO_URCEN_TERMIN_REALIZACE: 'zadosti.stav.reseni.rs.PRIJATO_URCEN_TERMIN_REALIZACE',
    ZADOSTI_STAV_RESENI_RS_POZADAVEK_NEBYL_VYRIZEN: 'zadosti.stav.reseni.rs.POZADAVEK_NEBYL_VYRIZEN',
    ZADOSTI_STAV_RESENI_RS_POZADAVEK_VYRIZEN: 'zadosti.stav.reseni.rs.POZADAVEK_VYRIZEN',
    ZADOSTI_STAV_RESENI_RS_ZADOST_STORNOVANA: 'zadosti.stav.reseni.rs.ZADOST_STORNOVANA',
    ZADOSTI_STAV_RESENI_RDK_VSE: 'zadosti.stav.reseni.rdk.vse',
    ZADOSTI_STAV_RESENI_RDK_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.rdk.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_RDK_OPAKOVANI_PRIPRAVENOST: 'zadosti.stav.reseni.rdk.OPAKOVANI_PRIPRAVENOST',
    ZADOSTI_STAV_RESENI_RDK_ZADAN_POZADAVEK_NA_RDK: 'zadosti.stav.reseni.rdk.ZADAN_POZADAVEK_NA_RDK',
    ZADOSTI_STAV_RESENI_RDK_OPAKOVANI: 'zadosti.stav.reseni.rdk.OPAKOVANI',
    ZADOSTI_STAV_RESENI_RDK_ZADAN_POZADAVEK_NA_RS: 'zadosti.stav.reseni.rdk.ZADAN_POZADAVEK_NA_RS',
    ZADOSTI_STAV_RESENI_RDK_POZADAVEK_ZAMITNUT: 'zadosti.stav.reseni.rdk.POZADAVEK_ZAMITNUT',
    ZADOSTI_STAV_RESENI_RDK_POZADAVEK_NA_RS_ZAMITNUT: 'zadosti.stav.reseni.rdk.POZADAVEK_NA_RS_ZAMITNUT',
    ZADOSTI_STAV_RESENI_RDK_POZADAVEK_PRIJAT_MANUAL: 'zadosti.stav.reseni.rdk.POZADAVEK_PRIJAT_MANUAL',
    ZADOSTI_STAV_RESENI_RDK_PRIJATO: 'zadosti.stav.reseni.rdk.PRIJATO',
    ZADOSTI_STAV_RESENI_RDK_PRIJATO_HLEDANI_TERMINU_REALIZACE: 'zadosti.stav.reseni.rdk.PRIJATO_HLEDANI_TERMINU_REALIZACE',
    ZADOSTI_STAV_RESENI_RDK_PRIJATO_URCEN_TERMIN_REALIZACE: 'zadosti.stav.reseni.rdk.PRIJATO_URCEN_TERMIN_REALIZACE',
    ZADOSTI_STAV_RESENI_RDK_POZADAVEK_NEBYL_VYRIZEN: 'zadosti.stav.reseni.rdk.POZADAVEK_NEBYL_VYRIZEN',
    ZADOSTI_STAV_RESENI_RDK_POZADAVEK_VYRIZEN: 'zadosti.stav.reseni.rdk.POZADAVEK_VYRIZEN',
    ZADOSTI_STAV_RESENI_UP_VSE: 'zadosti.stav.reseni.up.vse',
    ZADOSTI_STAV_RESENI_UP_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.up.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_UP_POZADAVEK_ZAMITNUT: 'zadosti.stav.reseni.up.POZADAVEK_ZAMITNUT',
    ZADOSTI_STAV_RESENI_UP_POZADAVEK_ODESLAN: 'zadosti.stav.reseni.up.POZADAVEK_ODESLAN',
    ZADOSTI_STAV_RESENI_UP_VALIDACE: 'zadosti.stav.reseni.up.VALIDACE',
    ZADOSTI_STAV_RESENI_UP_PRIJATO: 'zadosti.stav.reseni.up.PRIJATO',
    ZADOSTI_STAV_RESENI_UP_PRIJATO_HLEDANI_TERMINU_REALIZACE: 'zadosti.stav.reseni.up.PRIJATO_HLEDANI_TERMINU_REALIZACE',
    ZADOSTI_STAV_RESENI_UP_PRIJATO_URCEN_TERMIN_REALIZACE: 'zadosti.stav.reseni.up.PRIJATO_URCEN_TERMIN_REALIZACE',
    ZADOSTI_STAV_RESENI_UP_NEPROVEDENO: 'zadosti.stav.reseni.up.NEPROVEDENO',
    ZADOSTI_STAV_RESENI_UP_PROVEDENO: 'zadosti.stav.reseni.up.PROVEDENO',
    ZADOSTI_STAV_RESENI_UP_ZADAN_POZADAVEK_NA_STORNO: 'zadosti.stav.reseni.up.ZADAN_POZADAVEK_NA_STORNO',
    ZADOSTI_STAV_RESENI_UP_POZADAVEK_STORNOVAN: 'zadosti.stav.reseni.up.POZADAVEK_STORNOVAN',
    ZADOSTI_STAV_RESENI_UP_POZADAVEK_NA_STORNO_ZAMITNUT: 'zadosti.stav.reseni.up.POZADAVEK_NA_STORNO_ZAMITNUT',
    ZADOSTI_STAV_RESENI_OBN_VSE: 'zadosti.stav.reseni.obn.VSE',
    ZADOSTI_STAV_RESENI_OBN_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.obn.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_OBN_POZADAVEK_ODESLAN: 'zadosti.stav.reseni.obn.POZADAVEK_ODESLAN',
    ZADOSTI_STAV_RESENI_OBN_POZADAVEK_ZAMITNUT: 'zadosti.stav.reseni.obn.POZADAVEK_ZAMITNUT',
    ZADOSTI_STAV_RESENI_OBN_PRIJATO_HLEDANI_TERMINU_REALIZACE: 'zadosti.stav.reseni.obn.PRIJATO_HLEDANI_TERMINU_REALIZACE',
    ZADOSTI_STAV_RESENI_OBN_NEPROVEDENO: 'zadosti.stav.reseni.obn.NEPROVEDENO',
    ZADOSTI_STAV_RESENI_OBN_PROVEDENO: 'zadosti.stav.reseni.obn.PROVEDENO',
    ZADOSTI_STAV_RESENI_OBN_POZADAVEK_STORNOVAN: 'zadosti.stav.reseni.obn.POZADAVEK_STORNOVAN',
    ZADOSTI_STAV_RESENI_OBN_PRIJATO_URCEN_TERMIN_REALIZACE: 'zadosti.stav.reseni.obn.PRIJATO_URCEN_TERMIN_REALIZACE',
    ZADOSTI_STAV_RESENI_SOP_VSE: 'zadosti.stav.reseni.sop.VSE',
    ZADOSTI_STAV_RESENI_SOP_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.sop.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_SOP_ZADAN_POZADAVEK_NA_SOP: 'zadosti.stav.reseni.sop.ZADAN_POZADAVEK_NA_SOP',
    ZADOSTI_STAV_RESENI_SOP_ZADOST_ZAMITNUTA: 'zadosti.stav.reseni.sop.ZADOST_ZAMITNUTA',
    ZADOSTI_STAV_RESENI_SOP_ZADOST_STORNOVANA: 'zadosti.stav.reseni.sop.ZADOST_STORNOVANA',
    ZADOSTI_STAV_RESENI_SOP_SMLOUVA_ZAEVIDOVANA: 'zadosti.stav.reseni.sop.SMLOUVA_ZAEVIDOVANA',
    ZADOSTI_STAV_RESENI_SOP_SMLOUVA_ODESLANA: 'zadosti.stav.reseni.sop.SMLOUVA_ODESLANA',
    ZADOSTI_STAV_RESENI_SOP_SMLOUVA_ODESLANA_POSTOU: 'zadosti.stav.reseni.sop.SMLOUVA_ODESLANA_POSTOU',
    ZADOSTI_STAV_RESENI_SOP_SMLOUVA_POTVRZENA: 'zadosti.stav.reseni.sop.SMLOUVA_POTVRZENA',
    ZADOSTI_STAV_RESENI_SOP_SMLOUVA_NEVRACENA_V_TERMINU: 'zadosti.stav.reseni.sop.SMLOUVA_NEVRACENA_V_TERMINU',
    ZADOSTI_STAV_RESENI_SOP_SMLOUVA_VRACENA_NEPODEPSANA: 'zadosti.stav.reseni.sop.SMLOUVA_VRACENA_NEPODEPSANA',
    ZADOSTI_STAV_RESENI_SOP_SMLOUVA_PLATNA: 'zadosti.stav.reseni.sop.SMLOUVA_PLATNA',
    ZADOSTI_STAV_RESENI_SOP_NESPLNENY_PODMINKY_PDS: 'zadosti.stav.reseni.sop.NESPLNENY_PODMINKY_PDS',
    ZADOSTI_STAV_RESENI_SOP_SMLOUVA_V_RESENI: 'zadosti.stav.reseni.sop.SMLOUVA_V_RESENI',
    ZADOSTI_STAV_RESENI_SOP_DILO_DOKONCENO: 'zadosti.stav.reseni.sop.DILO_DOKONCENO',
    ZADOSTI_STAV_RESENI_SOP_ODSTOUPENO_SMLOUVA_UKONCENA: 'zadosti.stav.reseni.sop.ODSTOUPENO_SMLOUVA_UKONCENA',
    ZADOSTI_STAV_RESENI_SOP_OPM_PRIPRAVENO: 'zadosti.stav.reseni.sop.OPM_PRIPRAVENO',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_VSE: 'zadosti.stav.reseni.sop.zmena.jistice.VSE',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.sop.zmena.jistice.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_ZADAN_POZADAVEK_NA_SOP: 'zadosti.stav.reseni.sop.zmena.jistice.ZADAN_POZADAVEK_NA_SOP',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_ZADOST_ZAMITNUTA: 'zadosti.stav.reseni.sop.zmena.jistice.ZADOST_ZAMITNUTA',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_ZADOST_STORNOVANA: 'zadosti.stav.reseni.sop.zmena.jistice.ZADOST_STORNOVANA',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_SMLOUVA_ZAEVIDOVANA: 'zadosti.stav.reseni.sop.zmena.jistice.SMLOUVA_ZAEVIDOVANA',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_SMLOUVA_ODESLANA: 'zadosti.stav.reseni.sop.zmena.jistice.SMLOUVA_ODESLANA',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_SMLOUVA_ODESLANA_POSTOU: 'zadosti.stav.reseni.sop.zmena.jistice.SMLOUVA_ODESLANA_POSTOU',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_SMLOUVA_POTVRZENA: 'zadosti.stav.reseni.sop.zmena.jistice.SMLOUVA_POTVRZENA',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_SMLOUVA_NEVRACENA_V_TERMINU: 'zadosti.stav.reseni.sop.zmena.jistice.SMLOUVA_NEVRACENA_V_TERMINU',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_SMLOUVA_PLATNA: 'zadosti.stav.reseni.sop.zmena.jistice.SMLOUVA_PLATNA',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_NESPLNENY_PODMINKY_PDS: 'zadosti.stav.reseni.sop.zmena.jistice.NESPLNENY_PODMINKY_PDS',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_SMLOUVA_V_RESENI: 'zadosti.stav.reseni.sop.zmena.jistice.SMLOUVA_V_RESENI',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_DILO_DOKONCENO: 'zadosti.stav.reseni.sop.zmena.jistice.DILO_DOKONCENO',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_ODSTOUPENO_SMLOUVA_UKONCENA: 'zadosti.stav.reseni.sop.zmena.jistice.ODSTOUPENO_SMLOUVA_UKONCENA',
    ZADOSTI_STAV_RESENI_SOP_ZMENA_JISTICE_OPM_PRIPRAVENO: 'zadosti.stav.reseni.sop.zmena.jistice.OPM_PRIPRAVENO',
    ZADOSTI_STAV_RESENI_SOP_PLYN_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.sop.plyn.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_SOP_PLYN_ZADAN_POZADAVEK_NA_SOP: 'zadosti.stav.reseni.sop.plyn.ZADAN_POZADAVEK_NA_SOP',
    ZADOSTI_STAV_RESENI_SOP_PLYN_ZADOST_ZAMITNUTA: 'zadosti.stav.reseni.sop.plyn.ZADOST_ZAMITNUTA',
    ZADOSTI_STAV_RESENI_SOP_PLYN_ZADOST_STORNOVANA: 'zadosti.stav.reseni.sop.plyn.ZADOST_STORNOVANA',
    ZADOSTI_STAV_RESENI_SOP_PLYN_SMLOUVA_ZAEVIDOVANA: 'zadosti.stav.reseni.sop.plyn.SMLOUVA_ZAEVIDOVANA',
    ZADOSTI_STAV_RESENI_SOP_PLYN_SMLOUVA_ODESLANA: 'zadosti.stav.reseni.sop.plyn.SMLOUVA_ODESLANA',
    ZADOSTI_STAV_RESENI_SOP_PLYN_SMLOUVA_POTVRZENA: 'zadosti.stav.reseni.sop.plyn.SMLOUVA_POTVRZENA',
    ZADOSTI_STAV_RESENI_SOP_PLYN_SMLOUVA_NEVRACENA_V_TERMINU: 'zadosti.stav.reseni.sop.plyn.SMLOUVA_NEVRACENA_V_TERMINU',
    ZADOSTI_STAV_RESENI_SOP_PLYN_SMLOUVA_PLATNA: 'zadosti.stav.reseni.sop.plyn.SMLOUVA_PLATNA',
    ZADOSTI_STAV_RESENI_SOP_PLYN_DILO_DOKONCENO: 'zadosti.stav.reseni.sop.plyn.DILO_DOKONCENO',
    ZADOSTI_STAV_RESENI_SOP_PLYN_ODSTOUPENO_SMLOUVA_UKONCENA: 'zadosti.stav.reseni.sop.plyn.ODSTOUPENO_SMLOUVA_UKONCENA',
    ZADOSTI_STAV_RESENI_SOP_PLYN_OPM_PRIPRAVENO: 'zadosti.stav.reseni.sop.plyn.OPM_PRIPRAVENO',
    ZADOSTI_STAV_RESENI_SOP_PLYN_VSE: 'zadosti.stav.reseni.sop.plyn.VSE',
    ZADOSTI_STAV_RESENI_PRERUSENI_VSE: 'zadosti.stav.reseni.preruseni.vse',
    ZADOSTI_STAV_RESENI_PRERUSENI_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.preruseni.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_PRERUSENI_ZADAN_POZADAVEK_NA_PRERUSENI: 'zadosti.stav.reseni.preruseni.ZADAN_POZADAVEK_NA_PRERUSENI',
    ZADOSTI_STAV_RESENI_PRERUSENI_POZADAVEK_NA_PRERUSENI_ZAMITNUT: 'zadosti.stav.reseni.preruseni.POZADAVEK_NA_PRERUSENI_ZAMITNUT',
    ZADOSTI_STAV_RESENI_PRERUSENI_PRIJATO: 'zadosti.stav.reseni.preruseni.PRIJATO',
    ZADOSTI_STAV_RESENI_PRERUSENI_PRERUSENI_NEPROVEDENO: 'zadosti.stav.reseni.preruseni.PRERUSENI_NEPROVEDENO',
    ZADOSTI_STAV_RESENI_PRERUSENI_PRERUSENI_PROVEDENO: 'zadosti.stav.reseni.preruseni.PRERUSENI_PROVEDENO',
    ZADOSTI_STAV_RESENI_PRERUSENI_ZADAN_POZADAVEK_NA_ZASTAVENI_PRERUSENI: 'zadosti.stav.reseni.preruseni.ZADAN_POZADAVEK_NA_ZASTAVENI_PRERUSENI',
    ZADOSTI_STAV_RESENI_PRERUSENI_POZADAVEK_NA_PRERUSENI_STORNOVAN: 'zadosti.stav.reseni.preruseni.POZADAVEK_NA_PRERUSENI_STORNOVAN',
    ZADOSTI_STAV_RESENI_PRERUSENI_POZADAVEK_NA_ZASTAVENI_ZAMITNUT: 'zadosti.stav.reseni.preruseni.POZADAVEK_NA_ZASTAVENI_ZAMITNUT',
    ZADOSTI_STAV_RESENI_PRERUSENI_PRIJATO_HLEDANI_TERMINU_REALIZACE: 'zadosti.stav.reseni.preruseni.PRIJATO_HLEDANI_TERMINU_REALIZACE',
    ZADOSTI_STAV_RESENI_PRERUSENI_PRIJATO_URCEN_TERMIN_REALIZACE: 'zadosti.stav.reseni.preruseni.PRIJATO_URCEN_TERMIN_REALIZACE',
    ZADOSTI_STAV_RESENI_PRERUSENI_POZADAVEK_NA_ZASTAVENI_PRERUSENI_BYL_ZAMITNUT: 'zadosti.stav.reseni.preruseni.POZADAVEK_NA_ZASTAVENI_PRERUSENI_BYL_ZAMITNUT',
    ZADOSTI_STAV_RESENI_PRERUSENI_PRIJATO_OPAKOVANI_SERVISNI_ZAKAZKY: 'zadosti.stav.reseni.preruseni.PRIJATO_OPAKOVANI_SERVISNI_ZAKAZKY',
    ZADOSTI_STAV_RESENI_OBNOVENI_VSE: 'zadosti.stav.reseni.obnoveni.vse',
    ZADOSTI_STAV_RESENI_OBNOVENI_PRERUSENI_ZRUSENO: 'zadosti.stav.reseni.obnoveni.PRERUSENI_ZRUSENO',
    ZADOSTI_STAV_RESENI_OBNOVENI_CEKA_NA_ODESLANI: 'zadosti.stav.reseni.obnoveni.CEKA_NA_ODESLANI',
    ZADOSTI_STAV_RESENI_OBNOVENI_ZADAN_POZADAVEK_NA_PRIPOJENI: 'zadosti.stav.reseni.obnoveni.ZADAN_POZADAVEK_NA_PRIPOJENI',
    ZADOSTI_STAV_RESENI_OBNOVENI_POZADAVEK_NA_PRIPOJENI_ZAMITNUT: 'zadosti.stav.reseni.obnoveni.POZADAVEK_NA_PRIPOJENI_ZAMITNUT',
    ZADOSTI_STAV_RESENI_OBNOVENI_PRIJATO: 'zadosti.stav.reseni.obnoveni.PRIJATO',
    ZADOSTI_STAV_RESENI_OBNOVENI_PRIPOJENI_NEPROVEDENO: 'zadosti.stav.reseni.obnoveni.PRIPOJENI_NEPROVEDENO',
    ZADOSTI_STAV_RESENI_OBNOVENI_PRIPOJENI_PROVEDENO: 'zadosti.stav.reseni.obnoveni.PRIPOJENI_PROVEDENO',
    ZADOSTI_STAV_RESENI_OBNOVENI_PRIJATO_HLEDANI_TERMINU_REALIZACE: 'zadosti.stav.reseni.obnoveni.PRIJATO_HLEDANI_TERMINU_REALIZACE',
    ZADOSTI_STAV_RESENI_OBNOVENI_PRIJATO_URCEN_TERMIN_REALIZACE: 'zadosti.stav.reseni.obnoveni.PRIJATO_URCEN_TERMIN_REALIZACE',
    ZADOSTI_FILTRATION_ROZSIRENE_ZOBRAZIT: 'zadosti.filtration.rozsirene.zobrazit',
    ZADOSTI_FILTRATION_ROZSIRENE_SKRYT: 'zadosti.filtration.rozsirene.skryt',
    ZADOSTI_FILTRATION_ROZSIRENE: 'zadosti.filtration.rozsirene',
    ZADOSTI_STAV: 'zadosti.stav',
    ZADOSTI_VYTVORIL: 'zadosti.vytvoril',
    ZADOSTI_VYTVORENO: 'zadosti.vytvoreno',
    ZADOSTI_ODESLANO: 'zadosti.odeslano',
    ZADOSTI_CHYBA: 'zadosti.chyba',
    ZADOSTI_ODESILAM: 'zadosti.odesilam',
    ZADOSTI_NACIST_DATA: 'zadosti.nacist.data',
    ZADOSTI_CONFIRM_STORNO_ZADOST: 'zadosti.confirm.storno.zadost',
    ZADOSTI_CONFIRM_DELETE_ZADOST: 'zadosti.confirm.delete.zadost',
    ZADOSTI_CONFIRM_DELETE_DAVKA: 'zadosti.confirm.delete.davka',
    ZADOSTI_CONFIRM_DELETE_LAST_ZADOST: 'zadosti.confirm.delete.last.zadost',
    ZADOSTI_IMPORT_SAVED_BEFORE: 'zadosti.import.saved.before',
    ZADOSTI_IMPORT_SAVED_AFTER: 'zadosti.import.saved.after',
    ZADOSTI_IMPORT_SENT_BEFORE: 'zadosti.import.sent.before',
    ZADOSTI_IMPORT_SENT_AFTER: 'zadosti.import.sent.after',
    ZADOSTI_IMPORT_MENU_DETAIL: 'zadosti.import.menu.detail',
    ZADOSTI_IMPORT_MENU_DELETE: 'zadosti.import.menu.delete',
    ZADOSTI_IMPORT_MENU_PRILOHY: 'zadosti.import.menu.prilohy',
    ZADOSTI_IMPORT_STAV: 'zadosti.import.stav',
    ZADOSTI_IMPORT_FIELD_ZAHAJENI_ODBER: 'zadosti.import.field.zahajeni.odber',
    ZADOSTI_IMPORT_FIELD_NAPETOVA_HLADINA: 'zadosti.import.field.napetova.hladina',
    ZADOSTI_IMPORT_CONFIRM_UPDATE_ROW: 'zadosti.import.confirm.update.row',
    ZADOSTI_IMPORT_FIELD_TERMINZAHAJENIDISTRIBUCE: 'zadosti.import.field.terminZahajeniDistribuce',
    ZADOSTI_IMPORT_FIELD_TERMINUKONCENIDISTRIBUCE: 'zadosti.import.field.terminUkonceniDistribuce',
    ZADOSTI_IMPORT_FIELD_POZNAMKA: 'zadosti.import.field.poznamka',
    ZADOSTI_IMPORT_FIELD_PRILOHY: 'zadosti.import.field.prilohy',
    ZADOSTI_IMPORT_FIELD_JMENO: 'zadosti.import.field.jmeno',
    ZADOSTI_IMPORT_FIELD_DRUH: 'zadosti.import.field.druh',
    ZADOSTI_IMPORT_FIELD_PRIJMENI: 'zadosti.import.field.prijmeni',
    ZADOSTI_IMPORT_FIELD_NAZEVFIRMY: 'zadosti.import.field.nazevFirmy',
    ZADOSTI_IMPORT_FIELD_IC: 'zadosti.import.field.ic',
    ZADOSTI_IMPORT_FIELD_DIC: 'zadosti.import.field.dic',
    ZADOSTI_IMPORT_FIELD_DATUMNAROZENI: 'zadosti.import.field.datumNarozeni',
    ZADOSTI_IMPORT_FIELD_FAMESTO: 'zadosti.import.field.faMesto',
    ZADOSTI_IMPORT_FIELD_FAMISTNICAST: 'zadosti.import.field.faMistniCast',
    ZADOSTI_IMPORT_FIELD_FAULICE: 'zadosti.import.field.faUlice',
    ZADOSTI_IMPORT_FIELD_FACISLOPOPISNE: 'zadosti.import.field.faCisloPopisne',
    ZADOSTI_IMPORT_FIELD_FACISLOORIENTACNI: 'zadosti.import.field.faCisloOrientacni',
    ZADOSTI_IMPORT_FIELD_FAPSC: 'zadosti.import.field.faPsc',
    ZADOSTI_IMPORT_FIELD_KONTAKTJMENO: 'zadosti.import.field.kontaktJmeno',
    ZADOSTI_IMPORT_FIELD_KONTAKTPRIJMENI: 'zadosti.import.field.kontaktPrijmeni',
    ZADOSTI_IMPORT_FIELD_KONTAKTTELEFON: 'zadosti.import.field.kontaktTelefon',
    ZADOSTI_IMPORT_FIELD_KONTAKTEMAIL: 'zadosti.import.field.kontaktEmail',
    ZADOSTI_IMPORT_FIELD_OMMESTO: 'zadosti.import.field.omMesto',
    ZADOSTI_IMPORT_FIELD_OMMISTNICAST: 'zadosti.import.field.omMistniCast',
    ZADOSTI_IMPORT_FIELD_OMULICE: 'zadosti.import.field.omUlice',
    ZADOSTI_IMPORT_FIELD_OMCISLOPOPISNE: 'zadosti.import.field.omCisloPopisne',
    ZADOSTI_IMPORT_FIELD_OMCISLOORIENTACNI: 'zadosti.import.field.omCisloOrientacni',
    ZADOSTI_IMPORT_FIELD_OMPSC: 'zadosti.import.field.omPsc',
    ZADOSTI_IMPORT_FIELD_ZAKMISTNICAST: 'zadosti.import.field.zakMistniCast',
    ZADOSTI_IMPORT_FIELD_ZAKULICE: 'zadosti.import.field.zakUlice',
    ZADOSTI_IMPORT_FIELD_ZAKCISLOPOPISNE: 'zadosti.import.field.zakCisloPopisne',
    ZADOSTI_IMPORT_FIELD_ZAKCISLOORIENTACNI: 'zadosti.import.field.zakCisloOrientacni',
    ZADOSTI_IMPORT_FIELD_ZAKPSC: 'zadosti.import.field.zakPsc',
    ZADOSTI_IMPORT_FIELD_ZAKCISLOEVIDENCNI: 'zadosti.import.field.zakCisloEvidencni',
    ZADOSTI_IMPORT_FIELD_ZAKSTAT: 'zadosti.import.field.zakStat',
    ZADOSTI_IMPORT_FIELD_ZAKOBEC: 'zadosti.import.field.zakObec',
    ZADOSTI_IMPORT_FIELD_ZAKKORMISTNICAST: 'zadosti.import.field.zakKorMistniCast',
    ZADOSTI_IMPORT_FIELD_ZAKKORULICE: 'zadosti.import.field.zakKorUlice',
    ZADOSTI_IMPORT_FIELD_ZAKKORCISLOPOPISNE: 'zadosti.import.field.zakKorCisloPopisne',
    ZADOSTI_IMPORT_FIELD_ZAKKORCISLOORIENTACNI: 'zadosti.import.field.zakKorCisloOrientacni',
    ZADOSTI_IMPORT_FIELD_ZAKKORPSC: 'zadosti.import.field.zakKorPsc',
    ZADOSTI_IMPORT_FIELD_ZAKKORCISLOEVIDENCNI: 'zadosti.import.field.zakKorCisloEvidencni',
    ZADOSTI_IMPORT_FIELD_ZAKKORSTAT: 'zadosti.import.field.zakKorStat',
    ZADOSTI_IMPORT_FIELD_ZAKKOROBEC: 'zadosti.import.field.zakKorObec',
    ZADOSTI_IMPORT_FIELD_ZAKKORPOBOX: 'zadosti.import.field.zakKorPoBox',
    ZADOSTI_IMPORT_FIELD_EAN: 'zadosti.import.field.ean',
    ZADOSTI_IMPORT_FIELD_TELEFON: 'zadosti.import.field.telefon',
    ZADOSTI_IMPORT_FIELD_TYPMERENI: 'zadosti.import.field.typMereni',
    ZADOSTI_IMPORT_FIELD_TYPPOZADAVKU: 'zadosti.import.field.typPozadavku',
    ZADOSTI_IMPORT_FIELD_DATATYPUNN_REZERVOVANYVYKONKW: 'zadosti.import.field.dataTypuNn.rezervovanyVykonKw',
    ZADOSTI_IMPORT_FIELD_DATATYPUNN_HODNOTAJISTICE: 'zadosti.import.field.dataTypuNn.hodnotaJistice',
    ZADOSTI_IMPORT_FIELD_DATATYPUNN_POCETFAZI: 'zadosti.import.field.dataTypuNn.pocetFazi',
    ZADOSTI_IMPORT_FIELD_DATATYPUNN_DISTRIBUCNISAZBA: 'zadosti.import.field.dataTypuNn.distribucniSazba',
    ZADOSTI_IMPORT_FIELD_DATATYPUNN_TDD: 'zadosti.import.field.dataTypuNn.tdd',
    ZADOSTI_IMPORT_FIELD_DATATYPUNN_FREKVENCEODECTU: 'zadosti.import.field.dataTypuNn.frekvenceOdectu',
    ZADOSTI_IMPORT_FIELD_DATATYPUNN_TERMINODECTU: 'zadosti.import.field.dataTypuNn.terminOdectu',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_REZERVOVANYVYKONMW: 'zadosti.import.field.dataTypuVn.rezervovanyVykonMw',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_REZERVOVANYPRIKONMW: 'zadosti.import.field.dataTypuVn.rezervovanyPrikonMw',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_UMISTENIMERENI: 'zadosti.import.field.dataTypuVn.umisteniMereni',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_TRANSFORMATOR: 'zadosti.import.field.dataTypuVn.transformator',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKROCNI: 'zadosti.import.field.dataTypuVn.rkRocni',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKLEDEN: 'zadosti.import.field.dataTypuVn.rkLeden',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKUNOR: 'zadosti.import.field.dataTypuVn.rkUnor',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKBREZEN: 'zadosti.import.field.dataTypuVn.rkBrezen',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKDUBEN: 'zadosti.import.field.dataTypuVn.rkDuben',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKKVETEN: 'zadosti.import.field.dataTypuVn.rkKveten',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKCERVEN: 'zadosti.import.field.dataTypuVn.rkCerven',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKCERVENEC: 'zadosti.import.field.dataTypuVn.rkCervenec',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKSRPEN: 'zadosti.import.field.dataTypuVn.rkSrpen',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKZARI: 'zadosti.import.field.dataTypuVn.rkZari',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKRIJEN: 'zadosti.import.field.dataTypuVn.rkRijen',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKLISTOPAD: 'zadosti.import.field.dataTypuVn.rkListopad',
    ZADOSTI_IMPORT_FIELD_DATATYPUVN_RKPROSINEC: 'zadosti.import.field.dataTypuVn.rkProsinec',
    ZADOSTI_IMPORT_FIELD_RS3: 'zadosti.import.field.rs3',
    ZADOSTI_IMPORT_FIELD_RS4: 'zadosti.import.field.rs4',
    ZADOSTI_IMPORT_FIELD_RS5: 'zadosti.import.field.rs5',
    ZADOSTI_IMPORT_FIELD_RS6: 'zadosti.import.field.rs6',
    ZADOSTI_IMPORT_FIELD_RS7KW: 'zadosti.import.field.rs7Kw',
    ZADOSTI_IMPORT_FIELD_RS7HOD: 'zadosti.import.field.rs7Hod',
    ZADOSTI_IMPORT_FIELD_RSJMENO: 'zadosti.import.field.rsJmeno',
    ZADOSTI_IMPORT_FIELD_RSPRIJMENI: 'zadosti.import.field.rsPrijmeni',
    ZADOSTI_IMPORT_FIELD_RSTELEFON: 'zadosti.import.field.rsTelefon',
    ZADOSTI_IMPORT_FIELD_RSEMAIL: 'zadosti.import.field.rsEmail',
    ZADOSTI_IMPORT_FIELD_NAPETOVAHLADINA: 'zadosti.import.field.napetovaHladina',
    ZADOSTI_IMPORT_FIELD_POZADOVANYTERMIN: 'zadosti.import.field.pozadovanyTermin',
    ZADOSTI_IMPORT_FIELD_ICDATUMNAROZENI: 'zadosti.import.field.icDatumNarozeni',
    ZADOSTI_IMPORT_FIELD_EICUZIVATELE: 'zadosti.import.field.eicUzivatele',
    ZADOSTI_IMPORT_FIELD_EICOMPM: 'zadosti.import.field.eicOmPm',
    ZADOSTI_IMPORT_FIELD_EIC: 'zadosti.import.field.eic',
    ZADOSTI_IMPORT_FIELD_TYPPRIPOJENI: 'zadosti.import.field.typPripojeni',
    ZADOSTI_IMPORT_FIELD_JMENONAZEVZAKAZNIKA: 'zadosti.import.field.jmenoNazevZakaznika',
    ZADOSTI_IMPORT_FIELD_PRIJMENINAZEVZAKAZNIKA: 'zadosti.import.field.prijmeniNazevZakaznika',
    ZADOSTI_IMPORT_FIELD_OMOBEC: 'zadosti.import.field.omObec',
    ZADOSTI_IMPORT_FIELD_OMCASTOBCE: 'zadosti.import.field.omCastObce',
    ZADOSTI_IMPORT_FIELD_EMAIL: 'zadosti.import.field.email',
    ZADOSTI_IMPORT_FIELD_ODBERROCNI: 'zadosti.import.field.odberRocni',
    ZADOSTI_IMPORT_FIELD_MAXDENNIODBERAB: 'zadosti.import.field.maxDenniOdberAb',
    ZADOSTI_IMPORT_FIELD_MAXDENNIODBERC: 'zadosti.import.field.maxDenniOdberC',
    ZADOSTI_IMPORT_FIELD_TYP_RK: 'zadosti.import.field.typ.rk',
    ZADOSTI_IMPORT_FIELD_TYP: 'zadosti.import.field.typ',
    ZADOSTI_IMPORT_FIELD_ZALOHY: 'zadosti.import.field.zalohy',
    ZADOSTI_IMPORT_FIELD_TYPZMENYDODAVATELE: 'zadosti.import.field.typZmenyDodavatele',
    ZADOSTI_IMPORT_FIELD_DUVOD: 'zadosti.import.field.duvod',
    ZADOSTI_IMPORT_FIELD_OPRAVNENIODSTOUPITODSMLOUVY: 'zadosti.import.field.opravneniOdstoupitOdSmlouvy',
    ZADOSTI_IMPORT_FIELD_ZALOHA: 'zadosti.import.field.zaloha',
    ZADOSTI_IMPORT_FIELD_ODBERLEDEN: 'zadosti.import.field.odberLeden',
    ZADOSTI_IMPORT_FIELD_ODBERUNOR: 'zadosti.import.field.odberUnor',
    ZADOSTI_IMPORT_FIELD_ODBERBREZEN: 'zadosti.import.field.odberBrezen',
    ZADOSTI_IMPORT_FIELD_ODBERDUBEN: 'zadosti.import.field.odberDuben',
    ZADOSTI_IMPORT_FIELD_ODBERKVETEN: 'zadosti.import.field.odberKveten',
    ZADOSTI_IMPORT_FIELD_ODBERCERVEN: 'zadosti.import.field.odberCerven',
    ZADOSTI_IMPORT_FIELD_ODBERCERVENEC: 'zadosti.import.field.odberCervenec',
    ZADOSTI_IMPORT_FIELD_ODBERSRPEN: 'zadosti.import.field.odberSrpen',
    ZADOSTI_IMPORT_FIELD_ODBERZARI: 'zadosti.import.field.odberZari',
    ZADOSTI_IMPORT_FIELD_ODBERRIJEN: 'zadosti.import.field.odberRijen',
    ZADOSTI_IMPORT_FIELD_ODBERLISTOPAD: 'zadosti.import.field.odberListopad',
    ZADOSTI_IMPORT_FIELD_ODBERPROSINEC: 'zadosti.import.field.odberProsinec',
    ZADOSTI_IMPORT_FIELD_SMLOUVAZPUSOBUZAVRENI: 'zadosti.import.field.smlouvaZpusobUzavreni',
    ZADOSTI_IMPORT_FIELD_ZAHAJENIODBERU: 'zadosti.import.field.zahajeniOdberu',
    ZADOSTI_IMPORT_FIELD_OMKODKATASTRALNIHOUZEMI: 'zadosti.import.field.omKodKatastralnihoUzemi',
    ZADOSTI_IMPORT_FIELD_OMKATASTRALNIUZEMI: 'zadosti.import.field.omKatastralniUzemi',
    ZADOSTI_IMPORT_FIELD_OMCISLOPARCELNI: 'zadosti.import.field.omCisloParcelni',
    ZADOSTI_IMPORT_FIELD_OMCISLOPARCELNIPODCISLO: 'zadosti.import.field.omCisloParcelniPodcislo',
    ZADOSTI_IMPORT_FIELD_PREDPOKLADANYROCNIODBER: 'zadosti.import.field.predpokladanyRocniOdber',
    ZADOSTI_IMPORT_FIELD_OMCHARAKTERODBERU: 'zadosti.import.field.omCharakterOdberu',
    ZADOSTI_IMPORT_FIELD_CHARTECHNOLOGIE: 'zadosti.import.field.charTechnologie',
    ZADOSTI_IMPORT_FIELD_OMVYUZITI: 'zadosti.import.field.omVyuziti',
    ZADOSTI_IMPORT_FIELD_OKRESNISOUD: 'zadosti.import.field.okresniSoud',
    ZADOSTI_IMPORT_FIELD_ODDIL: 'zadosti.import.field.oddil',
    ZADOSTI_IMPORT_FIELD_VLOZKACISLO: 'zadosti.import.field.vlozkaCislo',
    ZADOSTI_IMPORT_FIELD_TITULPRED: 'zadosti.import.field.titulPred',
    ZADOSTI_IMPORT_FIELD_TITULZA: 'zadosti.import.field.titulZa',
    ZADOSTI_IMPORT_FIELD_RODNECISLO: 'zadosti.import.field.rodneCislo',
    ZADOSTI_KOD_KATASTRALNI_UZEMI: 'zadosti.kod.katastralni.uzemi',
    ZADOSTI_CISLO_PARCELY_PODCISLO: 'zadosti.cislo.parcely.podcislo',
    ZADOSTI_PREDPOKLADANY_ROCNI_ODBER: 'zadosti.predpokladany.rocni.odber',
    ZADOSTI_SMLOUVA_PODEPSANA_ZA_ECD: 'zadosti.smlouva.podepsana.za.ecd',
    ZADOSTI_SMLOUVA_POTVRZENA: 'zadosti.smlouva.potvrzena',
    ZADOSTI_SMLOUVA_PODEPSANA_ZA_ECD_OBCH: 'zadosti.smlouva.podepsana.za.ecd.obch',
    ZADOSTI_PODEPSAT: 'zadosti.podepsat',
    ZADOSTI_IMPORT_FIELD_OMCISLOEVIDENCNI: 'zadosti.import.field.omCisloEvidencni',
    ZADOSTI_IMPORT_FIELD_CHARAKTER_ODBERU_VARENI: 'zadosti.import.field.charakter.odberu.vareni',
    ZADOSTI_IMPORT_FIELD_CHARAKTER_ODBERU_VYTAPENI: 'zadosti.import.field.charakter.odberu.vytapeni',
    ZADOSTI_IMPORT_FIELD_CHARAKTER_ODBERU_TUV: 'zadosti.import.field.charakter.odberu.tuv',
    ZADOSTI_IMPORT_FIELD_CHARAKTER_ODBERU_TECHNOLOGIE: 'zadosti.import.field.charakter.odberu.technologie',
    ZADOSTI_IMPORT_FIELD_SPOTREBIC_TYP: 'zadosti.import.field.spotrebic.typ',
    ZADOSTI_IMPORT_FIELD_SPOTREBIC_PRIKON: 'zadosti.import.field.spotrebic.prikon',
    ZADOSTI_IMPORT_FIELD_SPOTREBIC_POCET: 'zadosti.import.field.spotrebic.pocet',
    ZADOSTI_IMPORT_FIELD_SPOTREBIC_STAV: 'zadosti.import.field.spotrebic.stav',
    ZADOSTI_IMPORT_FIELD_SPOTREBIC_DENNI_ODBER: 'zadosti.import.field.spotrebic.denni.odber',
    ZADOSTI_IMPORT_FIELD_SPOTREBIC_MIN_HOD_ODBER: 'zadosti.import.field.spotrebic.min.hod.odber',
    ZADOSTI_IMPORT_FIELD_SPOTREBIC_MAX_HOD_ODBER: 'zadosti.import.field.spotrebic.max.hod.odber',
    ZADOSTI_IMPORT_FIELD_SPOTREBIC_FIELD_CHYBA: 'zadosti.import.field.spotrebic.field.chyba',
    ZADOSTI_IMPORT_FIELD_ERROR_ZADVAL052: 'zadosti.import.field.error.ZADVAL052',
    ZADOSTI_IMPORT_ERROR_DUPLICITA_HROMADNE_PODANI: 'zadosti.import.error.duplicita.hromadne.podani',
    ZADOSTI_ERROR_ADRESA_MESTO: 'zadosti.error.adresa.mesto',
    ZADOSTI_ERROR_ADRESA_MISTNICAST: 'zadosti.error.adresa.mistniCast',
    ZADOSTI_ERROR_ADRESA_ULICE: 'zadosti.error.adresa.ulice',
    ZADOSTI_ERROR_ADRESA_OBEC: 'zadosti.error.adresa.obec',
    ZADOSTI_ERROR_ADRESA_CISLOPOPISNE: 'zadosti.error.adresa.cisloPopisne',
    ZADOSTI_ERROR_ADRESA_CISLOORIENTACNI: 'zadosti.error.adresa.cisloOrientacni',
    ZADOSTI_ERROR_ADRESA_PSC: 'zadosti.error.adresa.psc',
    ZADOSTI_ERROR_ADRESA_STAT: 'zadosti.error.adresa.stat',
    ZADOSTI_ERROR_KATASTR_KODKATASTRALNIHOUZEMI: 'zadosti.error.katastr.kodkatastralnihouzemi',
    ZADOSTI_ERROR_KATASTR_KATASTRALNIUZEMI: 'zadosti.error.katastr.katastralniuzemi',
    ZADOSTI_ERROR_KATASTR_CISLOPARCELNI: 'zadosti.error.katastr.cisloparcelni',
    ZADOSTI_ERROR_KATASTR_CISLOPARCELNIPODCISLO: 'zadosti.error.katastr.cisloparcelnipodcislo',
    ZADOSTI_DASHBOARD_WIDGET_RDK_BODY: 'zadosti.dashboard.widget.rdk.body',
    ZADOSTI_DASHBOARD_WIDGET_RDK_SUBTITLE: 'zadosti.dashboard.widget.rdk.subtitle',
    ZADOSTI_DASHBOARD_WIDGET_ELEKTRINA_SUBTITLE: 'zadosti.dashboard.widget.elektrina.subtitle',
    ZADOSTI_DASHBOARD_WIDGET_BODY: 'zadosti.dashboard.widget.body',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_ITEMS_BEFORE: 'zadosti.dashboard.widget.content.items.before',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_RESENI_AFTER_0: 'zadosti.dashboard.widget.content.v.reseni.after.0',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_RESENI_AFTER_1: 'zadosti.dashboard.widget.content.v.reseni.after.1',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_RESENI_AFTER_2: 'zadosti.dashboard.widget.content.v.reseni.after.2',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_RESENI_AFTER_3: 'zadosti.dashboard.widget.content.v.reseni.after.3',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_CEKA_NA_OPRAVU_AFTER_0: 'zadosti.dashboard.widget.content.v.ceka.na.opravu.after.0',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_CEKA_NA_OPRAVU_AFTER_1: 'zadosti.dashboard.widget.content.v.ceka.na.opravu.after.1',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_CEKA_NA_OPRAVU_AFTER_2: 'zadosti.dashboard.widget.content.v.ceka.na.opravu.after.2',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_CEKA_NA_OPRAVU_AFTER_3: 'zadosti.dashboard.widget.content.v.ceka.na.opravu.after.3',
    ZADOSTI_DASHBOARD_WIDGET_CONTENT_V_CEKA_NA_OPRAVU_AFTER_4: 'zadosti.dashboard.widget.content.v.ceka.na.opravu.after.4',
    ZADOSTI_DASHBOARD_WIDGET_BUTTON: 'zadosti.dashboard.widget.button',
    ZADOSTI_DETAIL_DAVKY: 'zadosti.detail.davky',
    ZADOSTI_DAVKA_SMAZANA: 'zadosti.davka.smazana',
    ZADOSTI_ZADOST_SMAZANA: 'zadosti.zadost.smazana',
    ZADOSTI_ZADOST_STORNOVANA: 'zadosti.zadost.stornovana',
    ZADOSTI_ZADOST_STORNO_ODESLANO: 'zadosti.zadost.storno.odeslano',
    ZADOSTI_DETAIL_ZADOSTI_CISLO: 'zadosti.detail.zadosti.cislo',
    ZADOSTI_DUVOD_ZAMITNUTI: 'zadosti.duvod.zamitnuti',
    ZADOSTI_PLYN: 'zadosti.plyn',
    ZADOSTI_NOT_IMPLEMENTED: 'zadosti.not.implemented',
    ZADOSTI_PREHLED_STAVU: 'zadosti.prehled.stavu',
    ZADOSTI_POMER_JEDNOTLIVE_STAVY: 'zadosti.pomer.jednotlive.stavy',
    ZADOSTI_TYDENNI_PREHLED: 'zadosti.tydenni.prehled',
    ZADOSTI_CELKOVY_POCET_PODANYCH_ZADOSTI_TYDEN: 'zadosti.celkovy.pocet.podanych.zadosti.tyden',
    ZADOSTI_MESICNI_PREHLED: 'zadosti.mesicni.prehled',
    ZADOSTI_CELKOVY_POCET_PODANYCH_ZADOSTI_MESIC: 'zadosti.celkovy.pocet.podanych.zadosti.mesic',
    ZADOSTI_ROCNI_PREHLED: 'zadosti.rocni.prehled',
    ZADOSTI_CELKOVY_POCET_PODANYCH_ZADOSTI_ROK: 'zadosti.celkovy.pocet.podanych.zadosti.rok',
    ZADOSTI_IMPORT_DIALOG: 'zadosti.import.dialog',
    ZADOSTI_IMPORT_PRAZDNY: 'zadosti.import.prazdny',
    ZADOSTI_NOTIFICATION_ERROR_HEAD_TEXT: 'zadosti.notification.error.head.text',
    ZADOSTI_NOTIFICATION_ERROR_TAIL_TEXT: 'zadosti.notification.error.tail.text',
    ZADOSTI_NOTIFICATION_INFO_SEND: 'zadosti.notification.info.send',
    ZADOSTI_NOTIFICATION_INFO_DRAFT: 'zadosti.notification.info.draft',
    ZADOSTI_NOTIFICATION_SENT_BEFORE: 'zadosti.notification.sent.before',
    ZADOSTI_NOTIFICATION_SENT_AFTER: 'zadosti.notification.sent.after',
    ZADOSTI_NOTIFICATION_DELETED: 'zadosti.notification.deleted',
    ZADOSTI_UP_RESENI_STAV_TOOLTIP: 'zadosti.up.reseni.stav.tooltip',
    ZADOSTI_OBN_RESENI_STAV_TOOLTIP: 'zadosti.obn.reseni.stav.tooltip',
    ZADOSTI_NASTENKA_WIDGET_RSD: 'zadosti.nastenka.widget.rsd',
    ZADOSTI_NASTENKA_WIDGET_RDK: 'zadosti.nastenka.widget.rdk',
    ZADOSTI_NASTENKA_WIDGET_U: 'zadosti.nastenka.widget.u',
    ZADOSTI_NASTENKA_WIDGET_P: 'zadosti.nastenka.widget.p',
    ZADOSTI_NASTENKA_WIDGET_OBN: 'zadosti.nastenka.widget.obn',
    ZADOSTI_NASTENKA_WIDGET_SOP_PREPIS: 'zadosti.nastenka.widget.sop.prepis',
    ZADOSTI_NASTENKA_WIDGET_SOP_TITLE: 'zadosti.nastenka.widget.sop.title',
    ZADOSTI_NASTENKA_WIDGET_SOP_PREPIS_ZMENA_JISTICE: 'zadosti.nastenka.widget.sop.prepis.zmena.jistice',
    ZADOSTI_NASTENKA_WIDGET_PU: 'zadosti.nastenka.widget.pu',
    ZADOSTI_TAB_RDK: 'zadosti.tab.rdk',
    ZADOSTI_TAB_UP: 'zadosti.tab.up',
    ZADOSTI_U: 'zadosti.u',
    ZADOSTI_SOP_PREPIS: 'zadosti.sop.prepis',
    ZADOSTI_RDK: 'zadosti.rdk',
    ZADOSTI_RS: 'zadosti.rs',
    ZADOSTI_P: 'zadosti.p',
    ZADOSTI_O: 'zadosti.o',
    ZADOSTI_TAB_OBNOVENI: 'zadosti.tab.obnoveni',
    ZADOSTI_TAB_RS: 'zadosti.tab.rs',
    ZADOSTI_TAB_PO: 'zadosti.tab.po',
    ZADOSTI_TAB_SOP_PREPIS: 'zadosti.tab.sop.prepis',
    ZADOSTI_TAB_SOP_PREPIS_ZMENA_JISTICE: 'zadosti.tab.sop.prepis.zmena.jistice',
    ZADOSTI_RDK_DUVOD_VSE: 'zadosti.rdk.duvod.vse',
    ZADOSTI_PRIDAT_ODECET: 'zadosti.pridat.odecet',
    ZADOSTI_RDK_DUVOD_S1: 'zadosti.rdk.duvod.S1',
    ZADOSTI_RDK_DUVOD_S2: 'zadosti.rdk.duvod.S2',
    ZADOSTI_RDK_DUVOD_S3: 'zadosti.rdk.duvod.S3',
    ZADOSTI_RDK_DUVOD_R1: 'zadosti.rdk.duvod.R1',
    ZADOSTI_RDK_DUVOD_R2: 'zadosti.rdk.duvod.R2',
    ZADOSTI_RDK_DUVOD_R3: 'zadosti.rdk.duvod.R3',
    ZADOSTI_RDK_DUVOD_D1: 'zadosti.rdk.duvod.D1',
    ZADOSTI_RDK_DUVOD_D2: 'zadosti.rdk.duvod.D2',
    ZADOSTI_RDK_DUVOD_D3: 'zadosti.rdk.duvod.D3',
    ZADOSTI_RDK_DUVOD_D4: 'zadosti.rdk.duvod.D4',
    ZADOSTI_RDK_DUVOD_D5: 'zadosti.rdk.duvod.D5',
    ZADOSTI_RDK_DUVOD_D6: 'zadosti.rdk.duvod.D6',
    ZADOSTI_RDK_DUVOD_D7: 'zadosti.rdk.duvod.D7',
    ZADOSTI_RDK_DUVOD_D9: 'zadosti.rdk.duvod.D9',
    ZADOSTI_RDK_DUVOD_DA: 'zadosti.rdk.duvod.DA',
    ZADOSTI_UP_DUVOD_UO: 'zadosti.up.duvod.uo',
    ZADOSTI_UP_DUVOD_NE: 'zadosti.up.duvod.ne',
    ZADOSTI_UP_DUVOD_RE: 'zadosti.up.duvod.re',
    ZADOSTI_TYP_ZMENY_DODAVATELE: 'zadosti.typ.zmeny.dodavatele',
    ZADOSTI_CEKA_NA_OPRAVU: 'zadosti.ceka.na.opravu',
    ZADOSTI_CEKA_NA_PODPIS: 'zadosti.ceka.na.podpis',
    ZADOSTI_RDK_ADDMENU_IMPORT: 'zadosti.rdk.addMenu.import',
    ZADOSTI_TYP_REZERVOVANE_KAPACITY: 'zadosti.typ.rezervovane.kapacity',
    ZADOSTI_ODSTOUPENI_OD_SMLOUVY: 'zadosti.odstoupeni.od.smlouvy',
    ZADOSTI_VAZBA_NA_ZADOST: 'zadosti.vazba.na.zadost',
    ZADOSTI_OPRAVA: 'zadosti.oprava',
    ZADOSTI_ODSTOUPENI_OD_SMLOUVY_OPRAVNENI: 'zadosti.odstoupeni.od.smlouvy.opravneni',
    ZADOSTI_PLACENI_ZALOH: 'zadosti.placeni.zaloh',
    ZADOSTI_OPRAVNA_ZADOST: 'zadosti.opravna.zadost',
    ZADOSTI_RDK_CISLO_SAP: 'zadosti.rdk.cislo.sap',
    ZADOSTI_UP_CISLO_SAP: 'zadosti.up.cislo.sap',
    ZADOSTI_OBN_CISLO_SAP: 'zadosti.obn.cislo.sap',
    ZADOSTI_RS_CISLO_SAP: 'zadosti.rs.cislo.sap',
    ZADOSTI_PO_CISLO_SAP: 'zadosti.po.cislo.sap',
    ZADOSTI_RDK_OPRAVNA_ZADOST_VSE: 'zadosti.rdk.opravna.zadost.vse',
    ZADOSTI_RDK_OPRAVNA_ZADOST_TRUE: 'zadosti.rdk.opravna.zadost.true',
    ZADOSTI_RDK_OPRAVNA_ZADOST_FALSE: 'zadosti.rdk.opravna.zadost.false',
    ZADOSTI_OPRAVNA_ZADOST_ZADOSTI: 'zadosti.opravna.zadost.zadosti',
    ZADOSTI_OPRAVNA_ZADOST_DAVKA: 'zadosti.opravna.zadost.davka',
    ZADOSTI_RDK_PREDPOKLAD_ROCNI_ODBER: 'zadosti.rdk.predpoklad.rocni.odber',
    ZADOSTI_RDK_MAXIMALNI_DENNI_AB: 'zadosti.rdk.maximalni.denni.ab',
    ZADOSTI_RDK_MAXIMALNI_DENNI_C: 'zadosti.rdk.maximalni.denni.c',
    ZADOSTI_UPRAVIT: 'zadosti.upravit',
    ZADOSTI_OPRAVIT: 'zadosti.opravit',
    ZADOSTI_TECHNICKE_UDAJE: 'zadosti.technicke.udaje',
    ZADOSTI_OPRAVNA_ZADOST_VICE: 'zadosti.opravna.zadost.vice',
    ZADOSTI_OPRAVNA_ZADOST_JEDNA: 'zadosti.opravna.zadost.jedna',
    ZADOSTI_OPRAVNA_ZADOST_PODANI: 'zadosti.opravna.zadost.podani',
    ZADOSTI_ZAMITNUTA_ZADOST: 'zadosti.zamitnuta.zadost',
    ZADOSTI_TERMIN_PODANI_OPRAVY: 'zadosti.termin.podani.opravy',
    ZADOSTI_RDK_PLACENI_ZALOH_TRUE: 'zadosti.rdk.placeni.zaloh.true',
    ZADOSTI_RDK_PLACENI_ZALOH_FALSE: 'zadosti.rdk.placeni.zaloh.false',
    ZADOSTI_MENU_DETAIL: 'zadosti.menu.detail',
    ZADOSTI_MENU_SMAZAT: 'zadosti.menu.smazat',
    ZADOSTI_MENU_STORNOVAT: 'zadosti.menu.stornovat',
    ZADOSTI_DETAIL_PUVODNI_ZADOSTI: 'zadosti.detail.puvodni.zadosti',
    ZADOSTI_DETAIL_OPRAVNE_ZADOSTI: 'zadosti.detail.opravne.zadosti',
    ZADOSTI_TYP_PRIPOJENI_DOM: 'zadosti.typ.pripojeni.DOM',
    ZADOSTI_TYP_PRIPOJENI_MO: 'zadosti.typ.pripojeni.MO',
    ZADOSTI_TYP_PRIPOJENI_SO: 'zadosti.typ.pripojeni.SO',
    ZADOSTI_TYP_PRIPOJENI_VO: 'zadosti.typ.pripojeni.VO',
    ZADOSTI_TYP_PRIPOJENI_VSE: 'zadosti.typ.pripojeni.VSE',
    ZADOSTI_TYP_PRIPOJENI: 'zadosti.typ.pripojeni',
    ZADOSTI_SOP_OM_VYUZITI_BYT_DUM_REKREACE: 'zadosti.sop.om.vyuziti.BYT_DUM_REKREACE',
    ZADOSTI_SOP_OM_VYUZITI_ADMINISTRATIVNI_PROSTOR: 'zadosti.sop.om.vyuziti.ADMINISTRATIVNI_PROSTOR',
    ZADOSTI_SOP_OM_VYUZITI_UBYTOVACI_STRAVOVACI_ZARIZENI: 'zadosti.sop.om.vyuziti.UBYTOVACI_STRAVOVACI_ZARIZENI',
    ZADOSTI_SOP_OM_VYUZITI_VYROBNI_PROSTOR: 'zadosti.sop.om.vyuziti.VYROBNI_PROSTOR',
    ZADOSTI_SOP_OM_VYUZITI_SKOLA: 'zadosti.sop.om.vyuziti.SKOLA',
    ZADOSTI_SOP_OM_VYUZITI_PRODEJNA: 'zadosti.sop.om.vyuziti.PRODEJNA',
    ZADOSTI_SOP_OM_VYUZITI_NEMOCNICE: 'zadosti.sop.om.vyuziti.NEMOCNICE',
    ZADOSTI_SOP_OM_VYUZITI_TECHNOLOGICKE_ODBERY_ZIMA: 'zadosti.sop.om.vyuziti.TECHNOLOGICKE_ODBERY_ZIMA',
    ZADOSTI_SOP_OM_VYUZITI_TECHNOLOGICKE_ODBERY_LETO: 'zadosti.sop.om.vyuziti.TECHNOLOGICKE_ODBERY_LETO',
    ZADOSTI_SOP_OM_VYUZITI_KOTELNY: 'zadosti.sop.om.vyuziti.KOTELNY',
    ZADOSTI_SOP_OM_VYUZITI_OSTATNI: 'zadosti.sop.om.vyuziti.OSTATNI',
    ZADOSTI_SOP_OM_VYUZITI_TECHNOLOGICKE_ODBERY_CELOROCNI: 'zadosti.sop.om.vyuziti.TECHNOLOGICKE_ODBERY_CELOROCNI',
    ZADOSTI_SOP_TYP_SPOTREBICE_TOPIDLO: 'zadosti.sop.typ.spotrebice.TOPIDLO',
    ZADOSTI_SOP_TYP_SPOTREBICE_KOTEL: 'zadosti.sop.typ.spotrebice.KOTEL',
    ZADOSTI_SOP_TYP_SPOTREBICE_KONDENZACNI_KOTEL: 'zadosti.sop.typ.spotrebice.KONDENZACNI_KOTEL',
    ZADOSTI_SOP_TYP_SPOTREBICE_PARNI_KOTEL: 'zadosti.sop.typ.spotrebice.PARNI_KOTEL',
    ZADOSTI_SOP_TYP_SPOTREBICE_SPORAK: 'zadosti.sop.typ.spotrebice.SPORAK',
    ZADOSTI_SOP_TYP_SPOTREBICE_BOILER: 'zadosti.sop.typ.spotrebice.BOILER',
    ZADOSTI_SOP_TYP_SPOTREBICE_PRUTOKOVY_OHRIVAC_VODY: 'zadosti.sop.typ.spotrebice.PRUTOKOVY_OHRIVAC_VODY',
    ZADOSTI_SOP_TYP_SPOTREBICE_AGREGAT: 'zadosti.sop.typ.spotrebice.AGREGAT',
    ZADOSTI_SOP_TYP_SPOTREBICE_HORAK: 'zadosti.sop.typ.spotrebice.HORAK',
    ZADOSTI_SOP_TYP_SPOTREBICE_ZARIC: 'zadosti.sop.typ.spotrebice.ZARIC',
    ZADOSTI_SOP_TYP_SPOTREBICE_INFRAZARIC: 'zadosti.sop.typ.spotrebice.INFRAZARIC',
    ZADOSTI_SOP_TYP_SPOTREBICE_SUSARNA: 'zadosti.sop.typ.spotrebice.SUSARNA',
    ZADOSTI_SOP_TYP_SPOTREBICE_VYMENIK: 'zadosti.sop.typ.spotrebice.VYMENIK',
    ZADOSTI_SOP_TYP_SPOTREBICE_KOMPRESOR: 'zadosti.sop.typ.spotrebice.KOMPRESOR',
    ZADOSTI_SOP_TYP_SPOTREBICE_CNG_STANICE: 'zadosti.sop.typ.spotrebice.CNG_STANICe',
    ZADOSTI_SOP_TYP_SPOTREBICE_PEC: 'zadosti.sop.typ.spotrebice.PEC',
    ZADOSTI_SOP_TYP_SPOTREBICE_VETRACI_JEDNOTKA: 'zadosti.sop.typ.spotrebice.VETRACI_JEDNOTKA',
    ZADOSTI_SOP_TYP_SPOTREBICE_TEPLOVZDUSNA_JEDNOTKA: 'zadosti.sop.typ.spotrebice.TEPLOVZDUSNA_JEDNOTKA',
    ZADOSTI_SOP_TYP_SPOTREBICE_SUSICI_KABINA: 'zadosti.sop.typ.spotrebice.SUSICI_KABINA',
    ZADOSTI_SOP_TYP_SPOTREBICE_KOGENERACNI_JEDNOTKA: 'zadosti.sop.typ.spotrebice.KOGENERACNI_JEDNOTKA',
    ZADOSTI_SOP_TYP_SPOTREBICE_OSTATNI_TECHNOLOGIE: 'zadosti.sop.typ.spotrebice.OSTATNI_TECHNOLOGIE',
    ZADOSTI_SOP_TYP_SPOTREBICE_OSTATNI_SPOTREBICE: 'zadosti.sop.typ.spotrebice.OSTATNI_SPOTREBICE',
    ZADOSTI_SOP_SPOTREBIC_NOVY: 'zadosti.sop.spotrebic.NOVY',
    ZADOSTI_SOP_SPOTREBIC_STAVAJICI: 'zadosti.sop.spotrebic.STAVAJICI',
    ZADOSTI_MESICNI_ROZPIS: 'zadosti.mesicni.rozpis',
    ZADOSTI_MESICNI_ROZPIS_ROCNI: 'zadosti.mesicni.rozpis.rocni',
    ZADOSTI_MESICNI_ROZPIS_LABEL: 'zadosti.mesicni.rozpis.label',
    ZADOSTI_MESICNI_ROZPIS_LEDEN: 'zadosti.mesicni.rozpis.leden',
    ZADOSTI_MESICNI_ROZPIS_UNOR: 'zadosti.mesicni.rozpis.unor',
    ZADOSTI_MESICNI_ROZPIS_BREZEN: 'zadosti.mesicni.rozpis.brezen',
    ZADOSTI_MESICNI_ROZPIS_DUBEN: 'zadosti.mesicni.rozpis.duben',
    ZADOSTI_MESICNI_ROZPIS_KVETEN: 'zadosti.mesicni.rozpis.kveten',
    ZADOSTI_MESICNI_ROZPIS_CERVEN: 'zadosti.mesicni.rozpis.cerven',
    ZADOSTI_MESICNI_ROZPIS_CERVENEC: 'zadosti.mesicni.rozpis.cervenec',
    ZADOSTI_MESICNI_ROZPIS_SRPEN: 'zadosti.mesicni.rozpis.srpen',
    ZADOSTI_MESICNI_ROZPIS_ZARI: 'zadosti.mesicni.rozpis.zari',
    ZADOSTI_MESICNI_ROZPIS_RIJEN: 'zadosti.mesicni.rozpis.rijen',
    ZADOSTI_MESICNI_ROZPIS_LISTOPAD: 'zadosti.mesicni.rozpis.listopad',
    ZADOSTI_MESICNI_ROZPIS_PROSINEC: 'zadosti.mesicni.rozpis.prosinec',
    ZADOSTI_RDK_TYP_ZMENY_DODAVATELE_VSE: 'zadosti.rdk.typ.zmeny.dodavatele.vse',
    ZADOSTI_RDK_TYP_ZMENY_DODAVATELE_SZ: 'zadosti.rdk.typ.zmeny.dodavatele.sz',
    ZADOSTI_RDK_TYP_ZMENY_DODAVATELE_RZ: 'zadosti.rdk.typ.zmeny.dodavatele.rz',
    ZADOSTI_RDK_TYP_ZMENY_DODAVATELE_NE: 'zadosti.rdk.typ.zmeny.dodavatele.ne',
    ZADOSTI_UP_TYP_VSE: 'zadosti.up.typ.vse',
    ZADOSTI_UP_TYP_U: 'zadosti.up.typ.u',
    ZADOSTI_UP_TYP_P: 'zadosti.up.typ.p',
    ZADOSTI_UP_TYP_U_SHORT: 'zadosti.up.typ.u.short',
    ZADOSTI_UP_TYP_P_SHORT: 'zadosti.up.typ.p.short',
    ZADOSTI_RDK_SMLOUVA_ODSTOUPENI_VSE: 'zadosti.rdk.smlouva.odstoupeni.vse',
    ZADOSTI_RDK_SMLOUVA_ODSTOUPENI_M: 'zadosti.rdk.smlouva.odstoupeni.m',
    ZADOSTI_RDK_SMLOUVA_ODSTOUPENI_P: 'zadosti.rdk.smlouva.odstoupeni.p',
    ZADOSTI_RDK_SMLOUVA_ODSTOUPENI_D: 'zadosti.rdk.smlouva.odstoupeni.d',
    ZADOSTI_RDK_TYP_VSE: 'zadosti.rdk.typ.vse',
    ZADOSTI_RDK_TYP_N: 'zadosti.rdk.typ.n',
    ZADOSTI_RDK_TYP_M: 'zadosti.rdk.typ.m',
    ZADOSTI_RDK_TYP_K: 'zadosti.rdk.typ.k',
    ZADOSTI_RDK_TYP_H: 'zadosti.rdk.typ.h',
    ZADOSTI_UP_ADDMENU_IMPORT: 'zadosti.up.addMenu.import',
    ZADOSTI_OBN_ADDMENU_IMPORT: 'zadosti.obn.addMenu.import',
    ZADOSTI_SOP_PREPIS_ADDMENU_IMPORT: 'zadosti.sop.prepis.addMenu.import',
    ZADOSTI_SOP_ZMENA_JISTICE_ADDMENU_IMPORT: 'zadosti.sop.zmena.jistice.addMenu.import',
    ZADOSTI_SOP_CISLO_SAP: 'zadosti.sop.cislo.sap',
    ZADOSTI_SOP_ZMENA_JISTICE_STP: 'zadosti.sop.zmena.jistice.stp',
    ZADOSTI_SOP_ZMENA_JISTICE_WARNING_PRILOHY: 'zadosti.sop.zmena.jistice.warning.prilohy',
    ZADOSTI_SOP_ZMENA_JISTICE_SMLOUVA_NAD_LIMIT: 'zadosti.sop.zmena.jistice.smlouva.nad.limit',
    ZADOSTI_SOP_ZMENA_JISTICE_PRIDAT: 'zadosti.sop.zmena.jistice.pridat',
    ZADOSTI_SOP_ZMENA_JISTICE_PRIDAT_PODMINKA: 'zadosti.sop.zmena.jistice.pridat.podminka',
    ZADOSTI_SOP_ZMENA_JISTICE_ZVYSENI_PODMINKA: 'zadosti.sop.zmena.jistice.zvyseni.podminka',
    ZADOSTI_SOP_ZMENA_JISTICE_DETAIL: 'zadosti.sop.zmena.jistice.detail',
    ZADOSTI_SOP_ZMENA_JISTICE_POVINNOST: 'zadosti.sop.zmena.jistice.povinnost',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_FINANCOVANI: 'zadosti.sop.zmena.jistice.stav.financovani',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_FINANCOVANI_NEUHRAZENO: 'zadosti.sop.zmena.jistice.stav.financovani.NEUHRAZENO',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_FINANCOVANI_NERELEVANTNI: 'zadosti.sop.zmena.jistice.stav.financovani.NERELEVANTNI',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_FINANCOVANI_UHRAZENO: 'zadosti.sop.zmena.jistice.stav.financovani.UHRAZENO',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_FINANCOVANI_UHRAZENA_1_SPLATKA: 'zadosti.sop.zmena.jistice.stav.financovani.UHRAZENA_1_SPLATKA',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_REALIZACE_DILA: 'zadosti.sop.zmena.jistice.stav.realizace.dila',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_REALIZACE_DILA_NERELEVANTNI: 'zadosti.sop.zmena.jistice.stav.realizace.dila.NERELEVANTNI',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_REALIZACE_DILA_S_INVESTICI: 'zadosti.sop.zmena.jistice.stav.realizace.dila.S_INVESTICI',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_REALIZACE_DILA_PRIPOJENI_PRELOZKA_HOTOVO: 'zadosti.sop.zmena.jistice.stav.realizace.dila.PRIPOJENI_PRELOZKA_HOTOVO',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_REALIZACE_DILA_PD_OBJEDNANA: 'zadosti.sop.zmena.jistice.stav.realizace.dila.PD_OBJEDNANA',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_REALIZACE_DILA_REALIZACE_OBJEDNANA: 'zadosti.sop.zmena.jistice.stav.realizace.dila.REALIZACE_OBJEDNANA',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_SPLNENI_TP: 'zadosti.sop.zmena.jistice.stav.splneni.tp',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_SPLNENI_TP_TECHNICKE_PODMINKY_NESPLNENY: 'zadosti.sop.zmena.jistice.stav.splneni.tp.TECHNICKE_PODMINKY_NESPLNENY',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_SPLNENI_TP_NERELEVANTNI: 'zadosti.sop.zmena.jistice.stav.splneni.tp.NERELEVANTNI',
    ZADOSTI_SOP_ZMENA_JISTICE_STAV_SPLNENI_TP_TECHNICKE_PODMINKY_SPLNENY: 'zadosti.sop.zmena.jistice.stav.splneni.tp.TECHNICKE_PODMINKY_SPLNENY',
    ZADOSTI_SOP_ZMENA_JISTICE_POZADOVANE_HODNOTY: 'zadosti.sop.zmena.jistice.pozadovane.hodnoty',
    ZADOSTI_SOP_ZMENA_JISTICE_CHARAKTER_ODBERU: 'zadosti.sop.zmena.jistice.charakter.odberu',
    ZADOSTI_SOP_ZMENA_JISTICE_POCET_FAZI: 'zadosti.sop.zmena.jistice.pocet.fazi',
    ZADOSTI_SOP_ZMENA_JISTICE_POCET_FAZI_JEDNOFAZE: 'zadosti.sop.zmena.jistice.pocet.fazi.JEDNOFAZE',
    ZADOSTI_SOP_ZMENA_JISTICE_POCET_FAZI_TRIFAZE: 'zadosti.sop.zmena.jistice.pocet.fazi.TRIFAZE',
    ZADOSTI_SOP_ZMENA_JISTICE_HONOTA_JISTICE: 'zadosti.sop.zmena.jistice.honota.jistice',
    ZADOSTI_SOP_ZMENA_JISTICE_TYP_MERENI: 'zadosti.sop.zmena.jistice.typ.mereni',
    ZADOSTI_SOP_ZMENA_JISTICE_NAPETOVA_UROVEN: 'zadosti.sop.zmena.jistice.napetova.uroven',
    ZADOSTI_SOP_ZMENA_JISTICE_SNIZENI_HODNOTY_HJ: 'zadosti.sop.zmena.jistice.snizeni.hodnoty.hj',
    ZADOSTI_SOP_ZMENA_JISTICE_ZVYSENI_HODNOTY_HJ: 'zadosti.sop.zmena.jistice.zvyseni.hodnoty.hj',
    ZADOSTI_SOP_ZMENA_JISTICE_ZVYSENI_SNIZENI_HODNOTY_HJ: 'zadosti.sop.zmena.jistice.zvyseni.snizeni.hodnoty.hj',
    ZADOSTI_SOP_ZMENA_JISTICE_VYPINACI_CHARAKTERISTIKA_JISTICE: 'zadosti.sop.zmena.jistice.vypinaci.charakteristika.jistice',
    ZADOSTI_SOP_ZMENA_JISTICE_VYPINACI_CHARAKTERISTIKA_B: 'zadosti.sop.zmena.jistice.vypinaci.charakteristika.B',
    ZADOSTI_SOP_ZMENA_JISTICE_VYPINACI_CHARAKTERISTIKA_C: 'zadosti.sop.zmena.jistice.vypinaci.charakteristika.C',
    ZADOSTI_SOP_ZMENA_JISTICE_VYPINACI_CHARAKTERISTIKA_D: 'zadosti.sop.zmena.jistice.vypinaci.charakteristika.D',
    ZADOSTI_SOP_ZMENA_JISTICE_DUVOD_ZMENA_CHARAKTERISTIKA: 'zadosti.sop.zmena.jistice.duvod.zmena.charakteristika',
    ZADOSTI_SOP_ZMENA_JISTICE_NAZEV_TYPU_SPOTREBICE: 'zadosti.sop.zmena.jistice.nazev.typu.spotrebice',
    ZADOSTI_SOP_ZMENA_JISTICE_PRIKON: 'zadosti.sop.zmena.jistice.prikon',
    ZADOSTI_SOP_ZMENA_JISTICE_NAPETI: 'zadosti.sop.zmena.jistice.napeti',
    ZADOSTI_SOP_ZMENA_JISTICE_USTALENY_PROUD: 'zadosti.sop.zmena.jistice.ustaleny.proud',
    ZADOSTI_SOP_ZMENA_JISTICE_ROZBEHOVY_PROUD: 'zadosti.sop.zmena.jistice.rozbehovy.proud',
    ZADOSTI_SOP_ZMENA_JISTICE_FREKVENCE_MENIC: 'zadosti.sop.zmena.jistice.frekvence.menic',
    ZADOSTI_SOP_ZMENA_JISTICE_FREKVENCE_MENIC_ANO: 'zadosti.sop.zmena.jistice.frekvence.menic.ano',
    ZADOSTI_SOP_ZMENA_JISTICE_FREKVENCE_MENIC_NE: 'zadosti.sop.zmena.jistice.frekvence.menic.ne',
    ZADOSTI_SOP_ZMENA_JISTICE_CELKOVY_PRIKON: 'zadosti.sop.zmena.jistice.celkovy.prikon',
    ZADOSTI_SOP_ZMENA_JISTICE_KORESPONDECNI_ADRESA_STEJNA: 'zadosti.sop.zmena.jistice.korespondecni.adresa.stejna',
    ZADOSTI_SOP_ZMENA_JISTICE_PODPIS_NELZE_ELEKTRONICKY: 'zadosti.sop.zmena.jistice.podpis.nelze.elektronicky',
    ZADOSTI_SOP_ZMENA_JISTICE_PODPIS_NELZE_ELEKTRONICKY_TOOLTIP: 'zadosti.sop.zmena.jistice.podpis.nelze.elektronicky.tooltip',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_TYP: 'zadosti.sop.zmena.jistice.spotrebic.typ',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_WARNING_DOBIJECI_STANICE: 'zadosti.sop.zmena.jistice.spotrebic.warning.dobijeci.stanice',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_ODKAZ_DOBIJECI_STANICE: 'zadosti.sop.zmena.jistice.spotrebic.odkaz.dobijeci.stanice',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_OSVETLENI: 'zadosti.sop.zmena.jistice.spotrebic.OSVETLENI',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_OHREV_VODY_AKUMULACNI: 'zadosti.sop.zmena.jistice.spotrebic.OHREV_VODY_AKUMULACNI',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_ELEKTRICKE_VYTAPENI_AKUMULACNI: 'zadosti.sop.zmena.jistice.spotrebic.ELEKTRICKE_VYTAPENI_AKUMULACNI',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_ELEKTRICKE_VYTAPENI_PRIMOTOPNE: 'zadosti.sop.zmena.jistice.spotrebic.ELEKTRICKE_VYTAPENI_PRIMOTOPNE',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_TEPELNE_CERPADLO: 'zadosti.sop.zmena.jistice.spotrebic.TEPELNE_CERPADLO',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_ELEKTRICE_VARENI: 'zadosti.sop.zmena.jistice.spotrebic.ELEKTRICE_VARENI',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_KLIMATIZACE: 'zadosti.sop.zmena.jistice.spotrebic.KLIMATIZACE',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_DOBIJECI_STANICE_ELEKTROMOBIL: 'zadosti.sop.zmena.jistice.spotrebic.DOBIJECI_STANICE_ELEKTROMOBIL',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_MOTORY_POHONY_SVARECKY_APOD: 'zadosti.sop.zmena.jistice.spotrebic.MOTORY_POHONY_SVARECKY_APOD',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_OHREV_VODY_PRIMOTOPNY: 'zadosti.sop.zmena.jistice.spotrebic.OHREV_VODY_PRIMOTOPNY',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_STANDARDNI_SPOTREBICE: 'zadosti.sop.zmena.jistice.spotrebic.STANDARDNI_SPOTREBICE',
    ZADOSTI_SOP_ZMENA_JISTICE_SPOTREBIC_OSTATNI_SPOTREBICE: 'zadosti.sop.zmena.jistice.spotrebic.OSTATNI_SPOTREBICE',
    ZADOSTI_SOP_ZMENA_JISTICE_NAPETI_JMENOVITE_NAPETI_230_V: 'zadosti.sop.zmena.jistice.napeti.JMENOVITE_NAPETI_230_V',
    ZADOSTI_SOP_ZMENA_JISTICE_NAPETI_JMENOVITE_NAPETI_400_V: 'zadosti.sop.zmena.jistice.napeti.JMENOVITE_NAPETI_400_V',
    ZADOSTI_SOP_ZMENA_JISTICE_CHYBA_ODESLANI_TEXT: 'zadosti.sop.zmena.jistice.chyba.odeslani.text',
    ZADOSTI_SOP_ZMENA_JISTICE_ULOZENI_TEXT: 'zadosti.sop.zmena.jistice.ulozeni.text',
    ZADOSTI_SOP_ZMENA_JISTICE_ULOZENI_EDITACE_TEXT: 'zadosti.sop.zmena.jistice.ulozeni.editace.text',
    ZADOSTI_SOP_ZMENA_JISTICE_ODESLANI_TEXT: 'zadosti.sop.zmena.jistice.odeslani.text',
    ZADOSTI_SOP_ZMENA_JISTICE_ODESLANI_CHYBA_TEXT: 'zadosti.sop.zmena.jistice.odeslani.chyba.text',
    ZADOSTI_SOP_ZMENA_JISTICE_ODESLANI_EDITACE_TEXT: 'zadosti.sop.zmena.jistice.odeslani.editace.text',
    ZADOSTI_SOP_PREPIS_IMPORT_ELEKTRINA_HEADLINE: 'zadosti.sop.prepis.import.elektrina.headline',
    ZADOSTI_SOP_VYUZITI_ANO: 'zadosti.sop.vyuziti.ano',
    ZADOSTI_ZADOST_PODEPSAN_OK: 'zadosti.zadost.podepsan.ok',
    ZADOSTI_ZADOST_ZMENA_JISTICE_PODEPSAN_OK: 'zadosti.zadost.zmena.jistice.podepsan.ok',
    ZADOSTI_ZADOST_PODEPSAN_KO: 'zadosti.zadost.podepsan.ko',
    ZADOSTI_ZADOST_ZMENA_JISTICE_PODEPSAN_KO: 'zadosti.zadost.zmena.jistice.podepsan.ko',
    ZADOSTI_POTVRZUJI_ZADOST: 'zadosti.potvrzuji.zadost',
    ZADOSTI_POTVRZUJI_ZMENA_JISTICE_ZADOST: 'zadosti.potvrzuji.zmena.jistice.zadost',
    ZADOSTI_POTVRZUJI_TITLE_POTVRZUJI: 'zadosti.potvrzuji.title.potvrzuji',
    ZADOSTI_POTVRZUJI_TITLE_POTVRZENO: 'zadosti.potvrzuji.title.potvrzeno',
    ZADOSTI_NE_OBCHODNIK_PRISPEVEK: 'zadosti.ne.obchodnik.prispevek',
    ZADOSTI_NE_OBCHODNIK_PRISPEVEK_UPOZORNENI: 'zadosti.ne.obchodnik.prispevek.upozorneni',
    ZADOSTI_NE_OBCHODNIK_PRISPEVEK_UPRESNENI: 'zadosti.ne.obchodnik.prispevek.upresneni',
    ZADOSTI_NE_OBCHODNIK_PRISPEVEK_PREHLED_TITLE: 'zadosti.ne.obchodnik.prispevek.prehled.title',
    ZADOSTI_NE_OBCHODNIK_PRISPEVEK_ODESLAN: 'zadosti.ne.obchodnik.prispevek.odeslan',
    ZADOSTI_NE_OBCHODNIK_PRISPEVEK_NEODESLAN: 'zadosti.ne.obchodnik.prispevek.neodeslan',
    ZADOSTI_NE_OBCHODNIK_ADRESA: 'zadosti.ne.obchodnik.adresa',
    ZADOSTI_NE_OBCHODNIK_ZAKAZNIK: 'zadosti.ne.obchodnik.zakaznik',
    ZADOSTI_NE_OBCHODNIK_OM: 'zadosti.ne.obchodnik.om',
    ZADOSTI_NE_OBCHODNIK_IC: 'zadosti.ne.obchodnik.ic',
    ZADOSTI_NE_OBCHODNIK_DATUM_NAROZENI: 'zadosti.ne.obchodnik.datum.narozeni',
    ZADOSTI_NE_OBCHODNIK_IC_DATUM_NAROZENI: 'zadosti.ne.obchodnik.ic.datum.narozeni',
    ZADOSTI_NE_OBCHODNIK_EAN_EIC: 'zadosti.ne.obchodnik.ean.eic',
    ZADOSTI_NE_OBCHODNIK_JMENO_PRIJMENI_DOHROMADY: 'zadosti.ne.obchodnik.jmeno.prijmeni.dohromady',
    ZADOSTI_NE_OBCHODNIK_DATUM_PODANI: 'zadosti.ne.obchodnik.datum.podani',
    ZADOSTI_NE_OBCHODNIK_JMENO_PRIJMENI: 'zadosti.ne.obchodnik.jmeno.prijmeni',
    ZADOSTI_NE_OBCHODNIK_JMENO_PRIJMENI_TOOLTIP: 'zadosti.ne.obchodnik.jmeno.prijmeni.tooltip',
    ZADOSTI_NE_OBCHODNIK_POCET_JEDNOTEK: 'zadosti.ne.obchodnik.pocet.jednotek',
    ZADOSTI_NE_OBCHODNIK_POCET_JEDNOTEK_PLACEHOLDER: 'zadosti.ne.obchodnik.pocet.jednotek.placeholder',
    ZADOSTI_NE_OBCHODNIK_VYBRAT_KOD: 'zadosti.ne.obchodnik.vybrat.kod',
    ZADOSTI_NE_OBCHODNIK_VYBRAT_KOD_TOOLTIP: 'zadosti.ne.obchodnik.vybrat.kod.tooltip',
    ZADOSTI_NE_OBCHODNIK_VYBRAT_KOD_PLACEHOLDER: 'zadosti.ne.obchodnik.vybrat.kod.placeholder',
    ZADOSTI_ZAKLADNI_SMLOUVY: 'zadosti.zakladni.smlouvy',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_TEXTACE: 'zadosti.zakladni.smlouvy.prehled.textace',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_TEXTACE_POZADAVEK: 'zadosti.zakladni.smlouvy.prehled.textace.pozadavek',
    ZADOSTI_ZAKLADNI_SMLOUVY_DESCRIPTION: 'zadosti.zakladni.smlouvy.description',
    ZADOSTI_ZAKLADNI_SMLOUVY_DODATKY: 'zadosti.zakladni.smlouvy.dodatky',
    ZADOSTI_ZAKLADNI_SMLOUVY_BUTTON: 'zadosti.zakladni.smlouvy.button',
    ZADOSTI_ZAKLADNI_SMLOUVY_BUTTON_VLOZIT: 'zadosti.zakladni.smlouvy.button.vlozit',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_POPIS: 'zadosti.zakladni.smlouvy.prehled.popis',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_SEGMENT: 'zadosti.zakladni.smlouvy.prehled.segment',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_CISLO_SMLOUVY: 'zadosti.zakladni.smlouvy.prehled.cislo.smlouvy',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_PLATNOST_OD: 'zadosti.zakladni.smlouvy.prehled.platnost.od',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_PLATNOST_DO: 'zadosti.zakladni.smlouvy.prehled.platnost.do',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_STATUS: 'zadosti.zakladni.smlouvy.prehled.status',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_PRILOHA: 'zadosti.zakladni.smlouvy.prehled.priloha',
    ZADOSTI_ZAKLADNI_SMLOUVY_PREHLED_AKCE: 'zadosti.zakladni.smlouvy.prehled.akce',
    ZADOSTI_ZAKLADNI_SMLOUVY_DIALOG_POZNAMKA: 'zadosti.zakladni.smlouvy.dialog.poznamka',
    ZADOSTI_ZAKLADNI_SMLOUVY_DIALOG_PRILOHY: 'zadosti.zakladni.smlouvy.dialog.prilohy',
    ZADOSTI_ODPLOMBOVANI: 'zadosti.odplombovani',
    ZADOSTI_ODPLOMBOVANI_WIDGET_TITLE: 'zadosti.odplombovani.widget.title',
    ZADOSTI_ODPLOMBOVANI_WIDGET_DESCRIPTION: 'zadosti.odplombovani.widget.description',
    ZADOSTI_ODPLOMBOVANI_FOTOAPARAT: 'zadosti.odplombovani.fotoaparat',
    ZADOSTI_ODPLOMBOVANI_NACIST: 'zadosti.odplombovani.nacist',
    ZADOSTI_ODPLOMBOVANI_SMAZAT: 'zadosti.odplombovani.smazat',
    ZADOSTI_ODPLOMBOVANI_PROFIL: 'zadosti.odplombovani.profil',
    ZADOSTI_ODPLOMBOVANI_PROFIL_TOOLTIP: 'zadosti.odplombovani.profil.tooltip',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO: 'zadosti.odplombovani.profil.info',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TITLE: 'zadosti.odplombovani.profil.info.title',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TITLE_1: 'zadosti.odplombovani.profil.info.title.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TITLE_2: 'zadosti.odplombovani.profil.info.title.2',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TITLE_3: 'zadosti.odplombovani.profil.info.title.3',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TITLE_4: 'zadosti.odplombovani.profil.info.title.4',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TITLE_5: 'zadosti.odplombovani.profil.info.title.5',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TITLE_6: 'zadosti.odplombovani.profil.info.title.6',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TITLE_7: 'zadosti.odplombovani.profil.info.title.7',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_1_1: 'zadosti.odplombovani.profil.info.text.1.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_2_1: 'zadosti.odplombovani.profil.info.text.2.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_3_1: 'zadosti.odplombovani.profil.info.text.3.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_4_1: 'zadosti.odplombovani.profil.info.text.4.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_5_1: 'zadosti.odplombovani.profil.info.text.5.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_5_2: 'zadosti.odplombovani.profil.info.text.5.2',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_5_3: 'zadosti.odplombovani.profil.info.text.5.3',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_6_1: 'zadosti.odplombovani.profil.info.text.6.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_7_1: 'zadosti.odplombovani.profil.info.text.7.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_INFO_TEXT_8_1: 'zadosti.odplombovani.profil.info.text.8.1',
    ZADOSTI_ODPLOMBOVANI_PROFIL_SMLUVNI_PARTNER: 'zadosti.odplombovani.profil.smluvni.partner',
    ZADOSTI_ODPLOMBOVANI_PROFIL_IC: 'zadosti.odplombovani.profil.ic',
    ZADOSTI_ODPLOMBOVANI_PROFIL_NAZEV: 'zadosti.odplombovani.profil.nazev',
    ZADOSTI_ODPLOMBOVANI_PROFIL_TELEFONNI_CISLO: 'zadosti.odplombovani.profil.telefonni.cislo',
    ZADOSTI_ODPLOMBOVANI_PROFIL_DATUM_NAROZENI: 'zadosti.odplombovani.profil.datum.narozeni',
    ZADOSTI_ODPLOMBOVANI_FORM_TITLE: 'zadosti.odplombovani.form.title',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_TITLE: 'zadosti.odplombovani.form.krok.1.title',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_INFO_OM: 'zadosti.odplombovani.form.krok.1.info.om',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_VYBERTE_OM: 'zadosti.odplombovani.form.krok.1.vyberte.om',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_DESCRIPTION: 'zadosti.odplombovani.form.krok.1.description',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_TITLE: 'zadosti.odplombovani.form.krok.2.title',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_DESCRIPTION: 'zadosti.odplombovani.form.krok.2.description',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_TITLE: 'zadosti.odplombovani.form.krok.3.title',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DESCRIPTION: 'zadosti.odplombovani.form.krok.3.description',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_4_TITLE: 'zadosti.odplombovani.form.krok.4.title',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_4_DESCRIPTION: 'zadosti.odplombovani.form.krok.4.description',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_5_TITLE: 'zadosti.odplombovani.form.krok.5.title',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_5_DESCRIPTION: 'zadosti.odplombovani.form.krok.5.description',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_6_TITLE: 'zadosti.odplombovani.form.krok.6.title',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_6_DESCRIPTION: 'zadosti.odplombovani.form.krok.6.description',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_CISLOPRISTROJE: 'zadosti.odplombovani.form.krok.1.cisloPristroje',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_OR: 'zadosti.odplombovani.form.krok.1.or',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_OR_ZADEJTE: 'zadosti.odplombovani.form.krok.1.or.zadejte',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_CISLOPRISTROJE_TOOLTIP: 'zadosti.odplombovani.form.krok.1.cisloPristroje.tooltip',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_CAROVYKOD: 'zadosti.odplombovani.form.krok.1.carovyKod',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_OBEC: 'zadosti.odplombovani.form.krok.1.obec',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_PARTNER: 'zadosti.odplombovani.form.krok.1.partner',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_PARTNER_NE: 'zadosti.odplombovani.form.krok.1.partner.ne',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_PRISTROJ: 'zadosti.odplombovani.form.krok.1.pristroj',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_PRISTROJ_CISLO: 'zadosti.odplombovani.form.krok.1.pristroj.cislo',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_PRISTROJ_ERROR_MESSAGE_1: 'zadosti.odplombovani.form.krok.1.pristroj.error.message.1',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_PRISTROJ_ERROR_MESSAGE_2: 'zadosti.odplombovani.form.krok.1.pristroj.error.message.2',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_PRISTROJ_ERROR_MESSAGE_3: 'zadosti.odplombovani.form.krok.1.pristroj.error.message.3',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_PRISTROJ_HODNOTA_JISTICE: 'zadosti.odplombovani.form.krok.1.pristroj.hodnota.jistice',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA: 'zadosti.odplombovani.form.krok.1.adresa',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_CISLO_POPISNE: 'zadosti.odplombovani.form.krok.1.adresa.cislo.popisne',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_CISLO_ORIENTACNI: 'zadosti.odplombovani.form.krok.1.adresa.cislo.orientacni',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_CISLO_EVIDENCNI: 'zadosti.odplombovani.form.krok.1.adresa.cislo.evidencni',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_OBEC: 'zadosti.odplombovani.form.krok.1.adresa.obec',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_ULICE: 'zadosti.odplombovani.form.krok.1.adresa.ulice',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_PSC: 'zadosti.odplombovani.form.krok.1.adresa.psc',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_MISTNI_CAST: 'zadosti.odplombovani.form.krok.1.adresa.mistni.cast',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_CISLO_PARCELY: 'zadosti.odplombovani.form.krok.1.adresa.cislo.parcely',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ADRESA_KATASTRALNI_UZEMI: 'zadosti.odplombovani.form.krok.1.adresa.katastralni.uzemi',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_ULOZIT_DATA: 'zadosti.odplombovani.form.krok.1.ulozit.data',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_1_CAMERA_ERROR: 'zadosti.odplombovani.form.krok.1.camera.error',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_SMLUVNI_PARTNER_ID: 'zadosti.odplombovani.form.krok.2.smluvni.partner.id',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_SMLUVNI_PARTNER_IC: 'zadosti.odplombovani.form.krok.2.smluvni.partner.ic',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_POTVRZENI: 'zadosti.odplombovani.form.krok.2.potvrzeni',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_POTVRZENI_SOUHRN: 'zadosti.odplombovani.form.krok.2.potvrzeni.souhrn',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_JMENO_PRIJMENI: 'zadosti.odplombovani.form.krok.2.jmeno.prijmeni',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_TELEFON: 'zadosti.odplombovani.form.krok.2.telefon',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_TELEFON_TOOLTIP: 'zadosti.odplombovani.form.krok.2.telefon.tooltip',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_DATUM_NAROZENI: 'zadosti.odplombovani.form.krok.2.datum.narozeni',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_DATUM_NAROZENI_IC_TOOLTIP: 'zadosti.odplombovani.form.krok.2.datum.narozeni.ic.tooltip',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_IC: 'zadosti.odplombovani.form.krok.2.ic',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_ELEKTRIKAR_OVERUJI: 'zadosti.odplombovani.form.krok.2.elektrikar.overuji',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_ELEKTRIKAR_OVERENO: 'zadosti.odplombovani.form.krok.2.elektrikar.overeno',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_ELEKTRIKAR_OVERENO_CHYBA: 'zadosti.odplombovani.form.krok.2.elektrikar.overeno.chyba',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_2_SMLUVNI_PARTNER: 'zadosti.odplombovani.form.krok.2.smluvni.partner',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DUVOD: 'zadosti.odplombovani.form.krok.3.duvod',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_OCHRANA_SCHVALENA: 'zadosti.odplombovani.form.krok.3.ochrana.schvalena',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_OCHRANA_SCHVALENA_TOOLTIP: 'zadosti.odplombovani.form.krok.3.ochrana.schvalena.tooltip',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DATUM_ODPLOMBOVANI_DUVOD_XXXXX: 'zadosti.odplombovani.form.krok.3.datum.odplombovani.duvod.xxxxx',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DATUM_ODPLOMBOVANI: 'zadosti.odplombovani.form.krok.3.datum.odplombovani',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DATUM_ODPLOMBOVANI_ERROR: 'zadosti.odplombovani.form.krok.3.datum.odplombovani.error',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DATUM_ODPLOMBOVANI_ERROR_MENSI: 'zadosti.odplombovani.form.krok.3.datum.odplombovani.error.mensi',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_ZUSTANE_ODPLOMBOVANO: 'zadosti.odplombovani.form.krok.3.zustane.odplombovano',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DATUM_ZAPLOMBOVANI: 'zadosti.odplombovani.form.krok.3.datum.zaplombovani',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DATUM_ZAPLOMBOVANI_ERROR: 'zadosti.odplombovani.form.krok.3.datum.zaplombovani.error',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DATUM_ZAPLOMBOVANI_ERROR_MENSI: 'zadosti.odplombovani.form.krok.3.datum.zaplombovani.error.mensi',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_DATUM_ZAPLOMBOVANI_TOOLTIP: 'zadosti.odplombovani.form.krok.3.datum.zaplombovani.tooltip',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_OM_PRISTUPNE: 'zadosti.odplombovani.form.krok.3.om.pristupne',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_OM_PRISTUPNE_TOOLTIP_ANO: 'zadosti.odplombovani.form.krok.3.om.pristupne.tooltip.ano',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_3_OM_PRISTUPNE_TOOLTIP_NE: 'zadosti.odplombovani.form.krok.3.om.pristupne.tooltip.ne',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_4_TEXT: 'zadosti.odplombovani.form.krok.4.text',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_4_TOOLTIP: 'zadosti.odplombovani.form.krok.4.tooltip',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_4_EMAIL: 'zadosti.odplombovani.form.krok.4.email',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_4_TELEFON: 'zadosti.odplombovani.form.krok.4.telefon',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_4_POZNAMKA: 'zadosti.odplombovani.form.krok.4.poznamka',
    ZADOSTI_ODPLOMBOVANI_FORM_KROK_4_POZNAMKA_TOOLTIP: 'zadosti.odplombovani.form.krok.4.poznamka.tooltip',
    ZADOSTI_ODPLOMBOVANI_TITLE: 'zadosti.odplombovani.title',
    ZADOSTI_ODPLOMBOVANI_ADD_MENU: 'zadosti.odplombovani.add.menu',
    ZADOSTI_ODPLOMBOVANI_INFO: 'zadosti.odplombovani.info',
    LEFT_MENU_VSE: 'left.menu.vse',
    LEFT_MENU_CEKA_NA_OPRAVU: 'left.menu.ceka.na.opravu',
    LEFT_MENU_CEKA_NA_PODPIS: 'left.menu.ceka.na.podpis',
    LEFT_MENU_KONCEPT: 'left.menu.koncept',
    LEFT_MENU_ODESLANO: 'left.menu.odeslano',
    LEFT_MENU_V_RESENI: 'left.menu.v.reseni',
    LEFT_MENU_SCHVALENO: 'left.menu.schvaleno',
    LEFT_MENU_VYRESENO: 'left.menu.vyreseno',
    LEFT_MENU_STORNO: 'left.menu.storno',
    LEFT_MENU_ZAMITNUTO: 'left.menu.zamitnuto',
    LEFT_MENU_STATISTIKY: 'left.menu.statistiky',
    ZADOSTI_IMPORT_RESULT_HEADLINE: 'zadosti.import.result.headline',
    ZADOSTI_IMPORT_RESULT_ERRORS: 'zadosti.import.result.errors',
    ZADOSTI_IMPORT_RESULT_WARNINGS: 'zadosti.import.result.warnings',
    ZADOSTI_IMPORT_RESULT_SUCCESS: 'zadosti.import.result.success',
    ZADOSTI_RS_ADDMENU_NN: 'zadosti.rs.addMenu.nn',
    ZADOSTI_RS_ADDMENU_VN: 'zadosti.rs.addMenu.vn',
    ZADOSTI_RS_ADDMENU_IMPORT_NN: 'zadosti.rs.addMenu.import.nn',
    ZADOSTI_RS_ADDMENU_IMPORT_VN: 'zadosti.rs.addMenu.import.vn',
    ZADOSTI_RS_ADDMENU_IMPORT_PRERUSENI: 'zadosti.rs.addMenu.import.preruseni',
    ZADOSTI_RS_ADDMENU_IMPORT_OBNOVENI: 'zadosti.rs.addMenu.import.obnoveni',
    ZADOSTI_RS_IMPORT_ROW_ERRORS_TITLE: 'zadosti.rs.import.row.errors.title',
    ZADOSTI_RS_IMPORT_ROW_WARNINGS_TITLE: 'zadosti.rs.import.row.warnings.title',
    ZADOSTI_RS_IMPORT_ELEKTRINA_NN_DIALOGTITLE: 'zadosti.rs.import.elektrina.nn.dialogTitle',
    ZADOSTI_RS_IMPORT_ELEKTRINA_VN_DIALOGTITLE: 'zadosti.rs.import.elektrina.vn.dialogTitle',
    ZADOSTI_RS_IMPORT_ELEKTRINA_NN_HEADLINE: 'zadosti.rs.import.elektrina.nn.headline',
    ZADOSTI_RS_IMPORT_ELEKTRINA_VN_HEADLINE: 'zadosti.rs.import.elektrina.vn.headline',
    ZADOSTI_PO_IMPORT_ELEKTRINA_PRERUSENI_DIALOGTITLE: 'zadosti.po.import.elektrina.preruseni.dialogTitle',
    ZADOSTI_PO_IMPORT_ELEKTRINA_OBNOVENI_DIALOGTITLE: 'zadosti.po.import.elektrina.obnoveni.dialogTitle',
    ZADOSTI_PO_IMPORT_ELEKTRINA_PRERUSENI_HEADLINE: 'zadosti.po.import.elektrina.preruseni.headline',
    ZADOSTI_PO_IMPORT_ELEKTRINA_OBNOVENI_HEADLINE: 'zadosti.po.import.elektrina.obnoveni.headline',
    ZADOSTI_RDK_IMPORT_PLYN_HEADLINE: 'zadosti.rdk.import.plyn.headline',
    ZADOSTI_RDK_IMPORT_PLYN_DIALOGTITLE: 'zadosti.rdk.import.plyn.dialogTitle',
    ZADOSTI_UP_IMPORT_PLYN_HEADLINE: 'zadosti.up.import.plyn.headline',
    ZADOSTI_UP_IMPORT_PLYN_DIALOGTITLE: 'zadosti.up.import.plyn.dialogTitle',
    ZADOSTI_OBN_IMPORT_PLYN_HEADLINE: 'zadosti.obn.import.plyn.headline',
    ZADOSTI_OBN_IMPORT_PLYN_DIALOGTITLE: 'zadosti.obn.import.plyn.dialogTitle',
    ZADOSTI_SOP_PREPIS_IMPORT_ELEKTRINA_DIALOGTITLE: 'zadosti.sop.prepis.import.elektrina.dialogTitle',
    ZADOSTI_SOP_PREPIS_IMPORT_PLYN_DIALOGTITLE: 'zadosti.sop.prepis.import.plyn.dialogTitle',
    ZADOSTI_MENU_VSE: 'zadosti.menu.vse',
    ZADOSTI_MENU_NEZNAME: 'zadosti.menu.nezname',
    ZADOSTI_MENU_KONCEPT: 'zadosti.menu.koncept',
    ZADOSTI_MENU_ODESLANO: 'zadosti.menu.odeslano',
    ZADOSTI_MENU_V_RESENI: 'zadosti.menu.v.reseni',
    ZADOSTI_MENU_SCHVALENO: 'zadosti.menu.schvaleno',
    ZADOSTI_MENU_VYRESENO: 'zadosti.menu.vyreseno',
    ZADOSTI_MENU_STORNO: 'zadosti.menu.storno',
    ZADOSTI_MENU_ZAMITNUTO: 'zadosti.menu.zamitnuto',
    ZADOSTI_MENU_STATISTIKY: 'zadosti.menu.statistiky',
    REVIZNI_ZPRAVY: 'revizni.zpravy',
    REVIZNI_ZPRAVY_MENU_VSE: 'revizni.zpravy.menu.vse',
    REVIZNI_ZPRAVY_MENU_NEZNAME: 'revizni.zpravy.menu.nezname',
    REVIZNI_ZPRAVY_MENU_KONCEPT: 'revizni.zpravy.menu.koncept',
    REVIZNI_ZPRAVY_MENU_V_RESENI: 'revizni.zpravy.menu.v.reseni',
    REVIZNI_ZPRAVY_MENU_VYRESENO: 'revizni.zpravy.menu.vyreseno',
    REVIZNI_ZPRAVY_MENU_ZAMITNUTO: 'revizni.zpravy.menu.zamitnuto',
    REVIZNI_ZPRAVY_CISLO_REVIZNI_ZPRAVY: 'revizni.zpravy.cislo.revizni.zpravy',
    REVIZNI_ZPRAVY_CISLO_SOP: 'revizni.zpravy.cislo.sop',
    REVIZNI_ZPRAVY_NAPETOVA_HLADINA: 'revizni.zpravy.napetova.hladina',
    REVIZNI_ZPRAVY_STAV: 'revizni.zpravy.stav',
    REVIZNI_ZPRAVY_FILTRATION_STAV_RESENI: 'revizni.zpravy.filtration.stav.reseni',
    REVIZNI_ZPRAVY_FILTRATION_DATUM_VYTVORENI_OD: 'revizni.zpravy.filtration.datum.vytvoreni.od',
    REVIZNI_ZPRAVY_FILTRATION_DATUM_VYTVORENI_DO: 'revizni.zpravy.filtration.datum.vytvoreni.do',
    REVIZNI_ZPRAVY_FILTRATION_DATUM_ODESLANI_OD: 'revizni.zpravy.filtration.datum.odeslani.od',
    REVIZNI_ZPRAVY_FILTRATION_DATUM_ODESLANI_DO: 'revizni.zpravy.filtration.datum.odeslani.do',
    REVIZNI_ZPRAVY_FILTRATION_ROZSIRENE_SKRYT: 'revizni.zpravy.filtration.rozsirene.skryt',
    REVIZNI_ZPRAVY_FILTRATION_ROZSIRENE_ZOBRAZIT: 'revizni.zpravy.filtration.rozsirene.zobrazit',
    REVIZNI_ZPRAVY_FILTRATION_ROZSIRENE: 'revizni.zpravy.filtration.rozsirene',
    REVIZNI_ZPRAVY_EAN: 'revizni.zpravy.ean',
    REVIZNI_ZPRAVY_ZAKAZNIK: 'revizni.zpravy.zakaznik',
    REVIZNI_ZPRAVY_CHYBA: 'revizni.zpravy.chyba',
    REVIZNI_ZPRAVY_VYTVORENO: 'revizni.zpravy.vytvoreno',
    REVIZNI_ZPRAVY_VYSTAVENO: 'revizni.zpravy.vystaveno',
    REVIZNI_ZPRAVY_ODESLANO: 'revizni.zpravy.odeslano',
    REVIZNI_ZPRAVY_VYTVORIL: 'revizni.zpravy.vytvoril',
    REVIZNI_ZPRAVY_STAV_RESENI: 'revizni.zpravy.stav.reseni',
    REVIZNI_ZPRAVY_PRILOHY: 'revizni.zpravy.prilohy',
    REVIZNI_ZPRAVY_ODBERNE_MISTO: 'revizni.zpravy.odberne.misto',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_TYP_TARIFU: 'revizni.zpravy.odberne.misto.typ.tarifu',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_TRANSFORMATORY_PROUDU: 'revizni.zpravy.odberne.misto.transformatory.proudu',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_TRANSFORMATORY_NAPETI: 'revizni.zpravy.odberne.misto.transformatory.napeti',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_HODNOTA_JISTICE: 'revizni.zpravy.odberne.misto.hodnota.jistice',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_POCET_FAZI: 'revizni.zpravy.odberne.misto.pocet.fazi',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_POCET_FAZI_JEDNOFAZE: 'revizni.zpravy.odberne.misto.pocet.fazi.JEDNOFAZE',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_POCET_FAZI_TRIFAZE: 'revizni.zpravy.odberne.misto.pocet.fazi.TRIFAZE',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_DISTRIBUCNI_SAZBA: 'revizni.zpravy.odberne.misto.distribucni.sazba',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_VYPINACI_CHARAKTERISTIKA: 'revizni.zpravy.odberne.misto.vypinaci.charakteristika',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_VYPINACI_CHARAKTERISTIKA_B: 'revizni.zpravy.odberne.misto.vypinaci.charakteristika.B',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_VYPINACI_CHARAKTERISTIKA_C: 'revizni.zpravy.odberne.misto.vypinaci.charakteristika.C',
    REVIZNI_ZPRAVY_ODBERNE_MISTO_VYPINACI_CHARAKTERISTIKA_D: 'revizni.zpravy.odberne.misto.vypinaci.charakteristika.D',
    REVIZNI_ZPRAVY_STAV_RESENI_CEKA_NA_ODESLANI: 'revizni.zpravy.stav.reseni.CEKA_NA_ODESLANI',
    REVIZNI_ZPRAVY_STAV_RESENI_VSE: 'revizni.zpravy.stav.reseni.VSE',
    REVIZNI_ZPRAVY_STAV_RESENI_ODESLANO: 'revizni.zpravy.stav.reseni.ODESLANO',
    REVIZNI_ZPRAVY_STAV_RESENI_RZ_ZAMITNUTA: 'revizni.zpravy.stav.reseni.RZ_ZAMITNUTA',
    REVIZNI_ZPRAVY_STAV_RESENI_PODMINKY_SPLNENY: 'revizni.zpravy.stav.reseni.PODMINKY_SPLNENY',
    REVIZNI_ZPRAVY_STAV_RESENI_PODMINKY_NESPLNENY: 'revizni.zpravy.stav.reseni.PODMINKY_NESPLNENY',
    REVIZNI_ZPRAVY_STAV_RESENI_OPM_PRIPRAVENO: 'revizni.zpravy.stav.reseni.OPM_PRIPRAVENO',
    REVIZNI_ZPRAVY_ODSTRANIT: 'revizni.zpravy.odstranit',
    REVIZNI_ZPRAVY_ZADNA_PRILOHA: 'revizni.zpravy.zadna.priloha',
    REVIZNI_ZPRAVY_ZPRAVA_SMAZANA: 'revizni.zpravy.zprava.smazana',
    REVIZNI_ZPRAVY_DETAIL: 'revizni.zpravy.detail',
    REVIZNI_ZPRAVY_CISLOSOP_TOOLTIP: 'revizni.zpravy.cislosop.tooltip',
    REVIZNI_ZPRAVY_DATUM_VYSTAVENO_TOOLTIP: 'revizni.zpravy.datum.vystaveno.tooltip',
    REVIZNI_ZPRAVY_PRISTROJE: 'revizni.zpravy.pristroje',
    REVIZNI_ZPRAVY_PRISTROJE_NAPETI: 'revizni.zpravy.pristroje.napeti',
    REVIZNI_ZPRAVY_PREVODY_PROUDU: 'revizni.zpravy.prevody.proudu',
    REVIZNI_ZPRAVY_PREVODY_NAPETI: 'revizni.zpravy.prevody.napeti',
    REVIZNI_ZPRAVY_TRIDY_PRESNOSTI: 'revizni.zpravy.tridy.presnosti',
    REVIZNI_ZPRAVY_TRIDY_PRESNOSTI_NAPETI: 'revizni.zpravy.tridy.presnosti.napeti',
    REVIZNI_ZPRAVY_PRISTROJE_CISLO: 'revizni.zpravy.pristroje.cislo',
    REVIZNI_ZPRAVY_PREVODY_PREVOD: 'revizni.zpravy.prevody.prevod',
    REVIZNI_ZPRAVY_TRIDA_PRESNOSTI: 'revizni.zpravy.trida.presnosti',
    REVIZNI_ZPRAVY_SPOTREBICE: 'revizni.zpravy.spotrebice',
    REVIZNI_ZPRAVY_SPOTREBIC: 'revizni.zpravy.spotrebic',
    REVIZNI_ZPRAVY_SPOTREBIC_PRIKON: 'revizni.zpravy.spotrebic.prikon',
    REVIZNI_ZPRAVY_SPOTREBICE_TYP_OHREV_VODY_AKUMULACNI: 'revizni.zpravy.spotrebice.typ.OHREV_VODY_AKUMULACNI',
    REVIZNI_ZPRAVY_SPOTREBICE_TYP_ELEKTRICKE_VYTAPENI_AKUMULACNI: 'revizni.zpravy.spotrebice.typ.ELEKTRICKE_VYTAPENI_AKUMULACNI',
    REVIZNI_ZPRAVY_SPOTREBICE_TYP_ELEKTRICKE_VYTAPENI_PRIMOTOPNE: 'revizni.zpravy.spotrebice.typ.ELEKTRICKE_VYTAPENI_PRIMOTOPNE',
    REVIZNI_ZPRAVY_SPOTREBICE_TYP_TEPELNE_CERPADLO: 'revizni.zpravy.spotrebice.typ.TEPELNE_CERPADLO',
    REVIZNI_ZPRAVY_SCHOPNOST_BEZPECNEHO_PROVOZU: 'revizni.zpravy.schopnost.bezpecneho.provozu',
    REVIZNI_ZPRAVY_SCHOPNOST_BEZPECNEHO_PROVOZU_A: 'revizni.zpravy.schopnost.bezpecneho.provozu.A',
    REVIZNI_ZPRAVY_SCHOPNOST_BEZPECNEHO_PROVOZU_N: 'revizni.zpravy.schopnost.bezpecneho.provozu.N',
    REVIZNI_ZPRAVY_PREVOD_PP_0_0: 'revizni.zpravy.prevod.PP_0_0',
    REVIZNI_ZPRAVY_PREVOD_PP_10_5: 'revizni.zpravy.prevod.PP_10_5',
    REVIZNI_ZPRAVY_PREVOD_PP_15_5: 'revizni.zpravy.prevod.PP_15_5',
    REVIZNI_ZPRAVY_PREVOD_PP_20_5: 'revizni.zpravy.prevod.PP_20_5',
    REVIZNI_ZPRAVY_PREVOD_PP_25_5: 'revizni.zpravy.prevod.PP_25_5',
    REVIZNI_ZPRAVY_PREVOD_PP_30_5: 'revizni.zpravy.prevod.PP_30_5',
    REVIZNI_ZPRAVY_PREVOD_PP_40_5: 'revizni.zpravy.prevod.PP_40_5',
    REVIZNI_ZPRAVY_PREVOD_PP_50_5: 'revizni.zpravy.prevod.PP_50_5',
    REVIZNI_ZPRAVY_PREVOD_PP_60_5: 'revizni.zpravy.prevod.PP_60_5',
    REVIZNI_ZPRAVY_PREVOD_PP_75_1: 'revizni.zpravy.prevod.PP_75_1',
    REVIZNI_ZPRAVY_PREVOD_PP_75_5: 'revizni.zpravy.prevod.PP_75_5',
    REVIZNI_ZPRAVY_PREVOD_PP_80_1: 'revizni.zpravy.prevod.PP_80_1',
    REVIZNI_ZPRAVY_PREVOD_PP_80_5: 'revizni.zpravy.prevod.PP_80_5',
    REVIZNI_ZPRAVY_PREVOD_PP_100_1: 'revizni.zpravy.prevod.PP_100_1',
    REVIZNI_ZPRAVY_PREVOD_PP_100_5: 'revizni.zpravy.prevod.PP_100_5',
    REVIZNI_ZPRAVY_PREVOD_PP_125_5: 'revizni.zpravy.prevod.PP_125_5',
    REVIZNI_ZPRAVY_PREVOD_PP_150_1: 'revizni.zpravy.prevod.PP_150_1',
    REVIZNI_ZPRAVY_PREVOD_PP_150_5: 'revizni.zpravy.prevod.PP_150_5',
    REVIZNI_ZPRAVY_PREVOD_PP_200_1: 'revizni.zpravy.prevod.PP_200_1',
    REVIZNI_ZPRAVY_PREVOD_PP_200_5: 'revizni.zpravy.prevod.PP_200_5',
    REVIZNI_ZPRAVY_PREVOD_PP_250_5: 'revizni.zpravy.prevod.PP_250_5',
    REVIZNI_ZPRAVY_PREVOD_PP_300_1: 'revizni.zpravy.prevod.PP_300_1',
    REVIZNI_ZPRAVY_PREVOD_PP_300_5: 'revizni.zpravy.prevod.PP_300_5',
    REVIZNI_ZPRAVY_PREVOD_PP_400_1: 'revizni.zpravy.prevod.PP_400_1',
    REVIZNI_ZPRAVY_PREVOD_PP_400_5: 'revizni.zpravy.prevod.PP_400_5',
    REVIZNI_ZPRAVY_PREVOD_PP_500_1: 'revizni.zpravy.prevod.PP_500_1',
    REVIZNI_ZPRAVY_PREVOD_PP_500_5: 'revizni.zpravy.prevod.PP_500_5',
    REVIZNI_ZPRAVY_PREVOD_PP_600_5: 'revizni.zpravy.prevod.PP_600_5',
    REVIZNI_ZPRAVY_PREVOD_PP_750_5: 'revizni.zpravy.prevod.PP_750_5',
    REVIZNI_ZPRAVY_PREVOD_PP_1000_5: 'revizni.zpravy.prevod.PP_1000_5',
    REVIZNI_ZPRAVY_CHYBA_ODESLANI_TEXT: 'revizni.zpravy.chyba.odeslani.text',
    REVIZNI_ZPRAVY_ULOZENI_TEXT: 'revizni.zpravy.ulozeni.text',
    REVIZNI_ZPRAVY_ULOZENI_EDITACE_TEXT: 'revizni.zpravy.ulozeni.editace.text',
    REVIZNI_ZPRAVY_ODESLANI_TEXT: 'revizni.zpravy.odeslani.text',
    REVIZNI_ZPRAVY_ODESLANI_CHYBA_TEXT: 'revizni.zpravy.odeslani.chyba.text',
    REVIZNI_ZPRAVY_ODESLANI_EDITACE_TEXT: 'revizni.zpravy.odeslani.editace.text',
    REVIZNI_ZPRAVY_ODESLANI_INFO: 'revizni.zpravy.odeslani.info',
    REVIZNI_ZPRAVY_PODAT: 'revizni.zpravy.podat',
    REVIZNI_ZPRAVY_UPRAVIT: 'revizni.zpravy.upravit',
    REVIZNI_ZPRAVY_POZNAMKA: 'revizni.zpravy.poznamka',
    REVIZNI_ZPRAVY_TRIDA_PRESNOSTI_MT_02: 'revizni.zpravy.trida.presnosti.MT_02',
    REVIZNI_ZPRAVY_TRIDA_PRESNOSTI_MT_05: 'revizni.zpravy.trida.presnosti.MT_05',
    REVIZNI_ZPRAVY_ROZSIRENE: 'revizni.zpravy.rozsirene',
    REVIZNI_ZPRAVY_ROZSIRENE_SKRYT: 'revizni.zpravy.rozsirene.skryt',
    REVIZNI_ZPRAVY_ROZSIRENE_ZOBRAZIT: 'revizni.zpravy.rozsirene.zobrazit',
    REVIZNI_ZPRAVY_PREVOD_NAPETI_PN_10000_100: 'revizni.zpravy.prevod.napeti.PN_10000_100',
    REVIZNI_ZPRAVY_PREVOD_NAPETI_PN_11000_100: 'revizni.zpravy.prevod.napeti.PN_11000_100',
    REVIZNI_ZPRAVY_PREVOD_NAPETI_PN_11000_110: 'revizni.zpravy.prevod.napeti.PN_11000_110',
    REVIZNI_ZPRAVY_PREVOD_NAPETI_PN_22000_100: 'revizni.zpravy.prevod.napeti.PN_22000_100',
    REVIZNI_ZPRAVY_PREVOD_NAPETI_PN_22000_110: 'revizni.zpravy.prevod.napeti.PN_22000_110',
    REVIZNI_ZPRAVY_PREVOD_NAPETI_PN_35000_100: 'revizni.zpravy.prevod.napeti.PN_35000_100',
    REVIZNI_ZPRAVY_PREVOD_NAPETI_PN_6000_100: 'revizni.zpravy.prevod.napeti.PN_6000_100',
    REVIZNI_ZPRAVY_TYP_POTVRZENI: 'revizni.zpravy.typ.potvrzeni',
    REVIZNI_ZPRAVY_TYP_POTVRZENI_REVIZNI_ZPRAVA: 'revizni.zpravy.typ.potvrzeni.REVIZNI_ZPRAVA',
    REVIZNI_ZPRAVY_TYP_POTVRZENI_PROHLASENI_ENERGETIKA: 'revizni.zpravy.typ.potvrzeni.PROHLASENI_ENERGETIKA',
    REVIZNI_ZPRAVY_ADDMENU: 'revizni.zpravy.addmenu',
    REVIZNI_ZPRAVY_ADD_LEFTMENU: 'revizni.zpravy.add.leftmenu',
    REVIZNI_ZPRAVY_WARNING_PRILOHY: 'revizni.zpravy.warning.prilohy',
    ZADOSTI_ULOZIT_KONCEPT: 'zadosti.ulozit.koncept',
    ZADOSTI_ODSTRANIT_CHYBNE: 'zadosti.odstranit.chybne',
    ZADOSTI_IMPORT_ROW_DETAIL_TITLE: 'zadosti.import.row.detail.title',
    ZADOSTI_IMPORT_ROW_DETAIL_COMMON: 'zadosti.import.row.detail.common',
    ZADOSTI_IMPORT_ROW_DETAIL_ODBERNE_MISTO: 'zadosti.import.row.detail.odberne.misto',
    ZADOSTI_IMPORT_ROW_DETAIL_REGULACNI_STUPNE: 'zadosti.import.row.detail.regulacni.stupne',
    ZADOSTI_IMPORT_ROW_DETAIL_ZAKAZNIK: 'zadosti.import.row.detail.zakaznik',
    ZADOSTI_IMPORT_ROW_DETAIL_RS_KONTAKT: 'zadosti.import.row.detail.rs.kontakt',
    ZADOSTI_IMPORT_ROW_DETAIL_VN: 'zadosti.import.row.detail.vn',
    ZADOSTI_IMPORT_ROW_DETAIL_NN: 'zadosti.import.row.detail.nn',
    ZADOSTI_IMPORT_ROW_DETAIL_DODAVKA: 'zadosti.import.row.detail.dodavka',
    ZADOSTI_IMPORT_ROW_DETAIL_TYPY: 'zadosti.import.row.detail.typy',
    ZADOSTI_IMPORT_ROW_DETAIL_PRILOHY: 'zadosti.import.row.detail.prilohy',
    ZADOSTI_IMPORT_ROW_DETAIL_MESICNI_ROZPIS: 'zadosti.import.row.detail.mesicni.rozpis',
    ZADOSTI_IMPORT_GLOBAL_ERRORS: 'zadosti.import.global.errors',
    ZADOSTI_IMPORT_CONFIRM_DELETE_ERRORS: 'zadosti.import.confirm.delete.errors',
    ZADOSTI_IMPORT_CONFIRM_ROW: 'zadosti.import.confirm.row',
    ZADOSTI_IMPORT_SHOW_ONLY_ERRORS: 'zadosti.import.show.only.errors',
    ZADOSTI_IMPORT_SHOW_ONLY_WARNINGS: 'zadosti.import.show.only.warnings',
    KONTAKT_INT: 'kontakt.INT',
    KONTAKT_MOB: 'kontakt.MOB',
    KONTAKT_DAT: 'kontakt.DAT',
    KONTAKT_LTR: 'kontakt.LTR',
    KONTAKT_OBCHODNI_PARTNER: 'kontakt.obchodni.partner',
    KONTAKT_EAN_ADRESA: 'kontakt.ean.adresa',
    KONTAKT_EIC_ADRESA: 'kontakt.eic.adresa',
    KONTAKT_EAN_EIC_ADRESA: 'kontakt.ean.eic.adresa',
    KONTAKT_CHIP_EAN: 'kontakt.chip.ean',
    KONTAKT_CHIP_EIC: 'kontakt.chip.eic',
    KONTAKT_CHIP_EAN_EIC: 'kontakt.chip.ean.eic',
    PROFIL: 'profil',
    NASTAVENI: 'nastaveni',
    ODHLASIT_SE: 'odhlasit.se',
    ODHLASOVANI: 'odhlasovani',
    VICE_INFORMACI: 'vice.informaci',
    VYBRAT: 'vybrat',
    NASTENKA: 'nastenka',
    NASTENKA_DESCRIPTION: 'nastenka.description',
    NASTENKA_WELCOME: 'nastenka.welcome',
    NASTENKA_ROZCESTNIKY_TITLE: 'nastenka.rozcestniky.title',
    NASTENKA_ROZCESTNIKY_TEXT: 'nastenka.rozcestniky.text',
    SPP: 'spp',
    SPP_DESCRIPTION: 'spp.description',
    ODECTY: 'odecty',
    ODECTY_DESCRIPTION: 'odecty.description',
    ZADOSTI: 'zadosti',
    OSTATNI_ZADOSTI: 'ostatni.zadosti',
    PREHLED_FAKTUR: 'prehled.faktur',
    EVIDENCE_DODATKU: 'evidence.dodatku',
    INFORMACE: 'informace',
    SMLOUVY_ODBERNYCH_MIST: 'smlouvy.odbernych.mist',
    SMLOUVY_ODBERNYCH_MIST_DESCRIPTION: 'smlouvy.odbernych.mist.description',
    DEMO_SPOTREB: 'demo.spotreb',
    LOG_ODESLAN: 'log.odeslan',
    LOG_NEDOSTUPNY: 'log.nedostupny',
    BUTTON_PRIDAT_ODECET: 'button.pridat.odecet',
    BUTTON_ODSTRANIT_CHYBNE: 'button.odstranit.chybne',
    BUTTON_PRIHLASIT_SE: 'button.prihlasit.se',
    BUTTON_NAHRAT_ZNOVU: 'button.nahrat.znovu',
    BUTTON_OVERIT: 'button.overit',
    BUTTON_ZMENA_HESLA: 'button.zmena.hesla',
    BUTTON_OBNOVA_HESLA: 'button.obnova.hesla',
    BUTTON_OBNOVIT: 'button.obnovit',
    BUTTON_REGISTRACE: 'button.registrace',
    BUTTON_RESET: 'button.reset',
    BUTTON_ROZUMIM: 'button.rozumim',
    BUTTON_POZADAT: 'button.pozadat',
    BUTTON_ULOZIT_NASTAVENI: 'button.ulozit.nastaveni',
    BUTTON_EDITOVAT_NASTAVENI: 'button.editovat.nastaveni',
    BUTTON_EDITOVAT_ZRUSIT: 'button.editovat.zrusit',
    BUTTON_VZAD: 'button.vzad',
    BUTTON_VPRED: 'button.vpred',
    BUTTON_NATIVE_CAMERA: 'button.native.camera',
    BUTTON_LOAD_ADDRESS: 'button.load.address',
    BUTTON_NEXT: 'button.next',
    BUTTON_SEND: 'button.send',
    BUTTON_BACK: 'button.back',
    BUTTON_ZMENIT: 'button.zmenit',
    BUTTON_MORE: 'button.more',
    BUTTON_MORE_AKTUALITY: 'button.more.aktuality',
    FORM_FILEINPUT_SAMPLE_FILE_TEXT: 'form.fileInput.sample.file.text',
    VSE: 'vse',
    SKUPINA: 'skupina',
    UCET_PRIHLASIT: 'ucet.prihlasit',
    CHYBA: 'chyba',
    VYTVORIL: 'vytvoril',
    ZMENIL: 'zmenil',
    ODESLAL: 'odeslal',
    VYTVORENO: 'vytvoreno',
    ZMENENO: 'zmeneno',
    ODESLANO: 'odeslano',
    AKTIVNI: 'aktivni',
    NEAKTIVNI: 'neaktivni',
    NENI_IMPLEMENTOVANO: 'neni.implementovano',
    FUNKCIONALITA_BUDOUCNOST: 'funkcionalita.budoucnost',
    EKONOMICKY_SUBJEKT: 'ekonomicky.subjekt',
    USER_PROFILE_IC: 'user.profile.ic',
    USER_PROFILE_DIC: 'user.profile.dic',
    USER_PROFILE_UZIVATEL_SPATNE_PRIHLASEN: 'user.profile.uzivatel.spatne.prihlasen',
    USER_PROFILE_ZAREGISTROVAT_SE: 'user.profile.zaregistrovat.se',
    USER_PROFILE_POPRVE: 'user.profile.poprve',
    USER_PROFILE_ZAREGISTRUJTE_SE: 'user.profile.zaregistrujte.se',
    USER_PROFILE_ZISKEJTE_PRISTUP_APLIKACE: 'user.profile.ziskejte.pristup.aplikace',
    USER_PROFILE_JEDNODUCHA_REGISTRACE: 'user.profile.jednoducha.registrace',
    USER_PROFILE_REGISTRACE_NOVA: 'user.profile.registrace.nova',
    MESSAGE_NAZEV: 'message.nazev',
    USER_PROFILE_NAZEV: 'user.profile.nazev',
    MESSAGE_TYP: 'message.typ',
    MESSAGE_POCET_VYSKYTU: 'message.pocet.vyskytu',
    MESSAGE_PUVOD: 'message.puvod',
    MESSAGE_KLIENT: 'message.klient',
    MESSAGE_GRAPHQL_ERROR_DOTAZ: 'message.graphql.error.dotaz',
    MESSAGE_GRAPHQL_ERROR_KOD_CHYBY: 'message.graphql.error.kod.chyby',
    MESSAGE_GRAPHQL_ERROR_POPIS: 'message.graphql.error.popis',
    MESSAGE_ZADNE_ZPRAVY: 'message.zadne.zpravy',
    MESSAGE_DRAWER_ZPRAVY: 'message.drawer.zpravy',
    MESSAGE_DRAWER_OZNACIT_PRECTENE: 'message.drawer.oznacit.prectene',
    MESSAGE_DRAWER_ZAVRIT: 'message.drawer.zavrit',
    MESSAGE_DETAIL: 'message.detail',
    MESSAGE_ZMENENO: 'message.zmeneno',
    MESSAGE_VYTVORENO: 'message.vytvoreno',
    DETAIL: 'detail',
    DETAIL_LIDE: 'detail.lide',
    DETAIL_POPIS: 'detail.popis',
    DETAIL_DATUMY: 'detail.datumy',
    DETAIL_POZNAMKA: 'detail.poznamka',
    DETAIL_STAV: 'detail.stav',
    DETAIL_ZVALIDOVANO: 'detail.zvalidovano',
    DETAIL_CEKA_STORNO: 'detail.ceka.storno',
    DETAIL_STAV_VSE: 'detail.stav.VSE',
    DETAIL_STAV_KONCEPT: 'detail.stav.KONCEPT',
    DETAIL_STAV_V_RESENI: 'detail.stav.V_RESENI',
    DETAIL_STAV_CEKA_NA_DOPLNENI: 'detail.stav.CEKA_NA_DOPLNENI',
    DETAIL_STAV_CEKA_NA_OPRAVU: 'detail.stav.CEKA_NA_OPRAVU',
    DETAIL_STAV_CEKA_NA_PODPIS: 'detail.stav.CEKA_NA_PODPIS',
    DETAIL_STAV_CEKA_NA_PREDCHOZI_KROK: 'detail.stav.CEKA_NA_PREDCHOZI_KROK',
    DETAIL_STAV_SCHVALENO: 'detail.stav.SCHVALENO',
    DETAIL_STAV_VYRESENO: 'detail.stav.VYRESENO',
    DETAIL_STAV_ZAMITNUTO: 'detail.stav.ZAMITNUTO',
    DETAIL_STAV_STORNO: 'detail.stav.STORNO',
    DETAIL_STAV_NEODESLANO: 'detail.stav.neodeslano',
    DETAIL_STAV_ODESILANI: 'detail.stav.odesilani',
    DETAIL_STAV_CHYBA_ODESLANI: 'detail.stav.chyba.odeslani',
    DETAIL_STAV_ODESLANO: 'detail.stav.odeslano',
    DETAIL_STAV_APPERAK: 'detail.stav.apperak',
    DETAIL_STAV_UTILMD: 'detail.stav.utilmd',
    DETAIL_STAV_VYTVORIT_DAVKU: 'detail.stav.vytvorit.davku',
    DETAIL_STAV_VYTVORIT_REKLAMACI: 'detail.stav.vytvorit.reklamaci',
    DETAIL_STAV_VYTVORIT_ZADOST: 'detail.stav.vytvorit.zadost',
    DETAIL_STAV_PRIJATO_PODEPSANI: 'detail.stav.prijato.podepsani',
    DETAIL_HISTORIE_STAVU: 'detail.historie.stavu',
    DETAIL_HISTORIE_STAVU_DATUM_CAS: 'detail.historie.stavu.datum.cas',
    DETAIL_HISTORIE_STAVU_STAV: 'detail.historie.stavu.stav',
    DETAIL_HISTORIE_STAVU_UDALOST: 'detail.historie.stavu.udalost',
    DETAIL_HISTORIE_STAVU_VYTVORIL: 'detail.historie.stavu.vytvoril',
    DETAIL_HISTORIE_STAVU_DATUM_DATA: 'detail.historie.stavu.datum.data',
    DETAIL_KOMENTARE_TITLE: 'detail.komentare.title',
    DETAIL_KOMENTARE_NOCOMMENTS: 'detail.komentare.noComments',
    DETAIL_KOMENTARE_NOVY_TITLE: 'detail.komentare.novy.title',
    DETAIL_KOMENTARE_NOVY_TEXT: 'detail.komentare.novy.text',
    DETAIL_VAROVANI_CHYBY_SAP: 'detail.varovani.chyby.sap',
    DETAIL_VAROVANI_CHYBY_SAP_ODECTY: 'detail.varovani.chyby.sap.odecty',
    DETAIL_VAROVANI_CHYBY_SAP_WARNING: 'detail.varovani.chyby.sap.warning',
    DETAIL_VAROVANI_CHYBY_SAP_ERROR: 'detail.varovani.chyby.sap.error',
    DETAIL_TECHNICKE_UDAJE: 'detail.technicke.udaje',
    DETAIL_UMISTENI_OM: 'detail.umisteni.om',
    CHYBA_SITE: 'chyba.site',
    UCET_DEAKTIVOVAN: 'ucet.deaktivovan',
    FORM_KONTROLA: 'form.kontrola',
    IMPORT_ZOBRAZIT_VAROVANI: 'import.zobrazit.varovani',
    IMPORT_ZOBRAZIT_CHYBY: 'import.zobrazit.chyby',
    IMPORT_ZOBRAZIT_PRILOHY: 'import.zobrazit.prilohy',
    TABLE_RADKU_STRANKA: 'table.radku.stranka',
    ERROR_POLE_POVINNE: 'error.pole.povinne',
    ERROR_ONLY_STRING: 'error.only.string',
    ERROR_POTVRZENI_MUNICIPALITA: 'error.potvrzeni.municipalita',
    ERROR_INVALID_TOKEN: 'error.invalid.token',
    ERROR_INVALID_LOGIN: 'error.invalid.login',
    ERROR_REVOKED_TOKEN: 'error.revoked.token',
    ERROR_INACTIVITY_TOKEN: 'error.inactivity.token',
    ERROR_EMAIL_POVINNY: 'error.email.povinny',
    ERROR_HODNOTA_CISLO: 'error.hodnota.cislo',
    ERROR_HODNOTA_ROVNO_VETSI: 'error.hodnota.rovno.vetsi',
    ERROR_HODNOTA_CISLO_INTEGER: 'error.hodnota.cislo.integer',
    ERROR_HODNOTA_CISLOSOP: 'error.hodnota.cisloSop',
    ERROR_HODNOTA_CISLO_MAX: 'error.hodnota.cislo.max',
    INVESTICNI_STAVBY_TITLE: 'investicni.stavby.title',
    LOGIN_TITLE: 'login.title',
    LOGIN_SUBTITLE: 'login.subtitle',
    LOGIN_BUTTON: 'login.button',
    LOGIN_COOKIES_SETTING: 'login.cookies.setting',
    LOGIN_ZPET_NA_PRIHLASENI: 'login.zpet.na.prihlaseni',
    RESET_HESLA_POZADAVEK_TITLE: 'reset.hesla.pozadavek.title',
    RESET_HESLA_TITLE: 'reset.hesla.title',
    RESET_HESLA_SUBTITLE: 'reset.hesla.subtitle',
    RESET_HESLA_ERROR_TITLE: 'reset.hesla.error.title',
    RESET_HESLA_ERROR_TEXT: 'reset.hesla.error.text',
    RESET_HESLA_ERROR_NAPOVEDA: 'reset.hesla.error.napoveda',
    RESET_HESLA_ODESLAT: 'reset.hesla.odeslat',
    RESET_HESLA_PROFIL_RESETOVAT: 'reset.hesla.profil.resetovat',
    RESET_HESLA_POZADAVEK_ODESLANO: 'reset.hesla.pozadavek.odeslano',
    RESET_HESLA_POZADAVEK_ODESLANO_ERROR: 'reset.hesla.pozadavek.odeslano.error',
    RESET_HESLA_POZADAVEK_ODESLANO_TEXT: 'reset.hesla.pozadavek.odeslano.text',
    RESET_HESLA_POZADAVEK_ODESLANO_UPOZORNENI: 'reset.hesla.pozadavek.odeslano.upozorneni',
    RESET_HESLA_POZADAVEK_NAPOVEDA: 'reset.hesla.pozadavek.napoveda',
    RESET_HESLA_PLATNOST_VYPRSELA_TITLE: 'reset.hesla.platnost.vyprsela.title',
    RESET_HESLA_PLATNOST_VYPRSELA_TEXT: 'reset.hesla.platnost.vyprsela.text',
    RESET_HESLA_PLATNOST_VYPRSELA_NAPOVEDA: 'reset.hesla.platnost.vyprsela.napoveda',
    RESET_HESLA_PLATNOST_NOVY_POZADAVEK: 'reset.hesla.platnost.novy.pozadavek',
    RESET_HESLA_SUCCESS_TEXT1: 'reset.hesla.success.text1',
    RESET_HESLA_SUCCESS_TEXT2: 'reset.hesla.success.text2',
    RESET_HESLA_ZPET: 'reset.hesla.zpet',
    RESET_HESLA_PRIHLASENI: 'reset.hesla.prihlaseni',
    RESET_HESLA_PRIHLASENI_VYJADROVACKY: 'reset.hesla.prihlaseni.vyjadrovacky',
    RESET_HESLA_LOADING_DESCRIPTION: 'reset.hesla.loading.description',
    POTVRZENI_MUNICIPALITY_TITLE: 'potvrzeni.municipality.title',
    POTVRZENI_MUNICIPALITY_ODESLAT: 'potvrzeni.municipality.odeslat',
    POTVRZENI_MUNICIPALITY_SUBTITLE: 'potvrzeni.municipality.subtitle',
    POTVRZENI_MUNICIPALITY_SUCCESS: 'potvrzeni.municipality.success',
    POTVRZENI_PRISTUPU_TITLE: 'potvrzeni.pristupu.title',
    POTVRZENI_PRISTUPU_ODESLAT: 'potvrzeni.pristupu.odeslat',
    POTVRZENI_PRISTUPU_SUBTITLE: 'potvrzeni.pristupu.subtitle',
    POTVRZENI_SUCCESS: 'potvrzeni.success',
    HISTORIE_DATUM: 'historie.datum',
    HISTORIE_CAS: 'historie.cas',
    HISTORIE_EICEAN: 'historie.eicean',
    HISTORIE_DATUM_OD: 'historie.datum.od',
    HISTORIE_DATUM_DO: 'historie.datum.do',
    HISTORIE_ROLE: 'historie.role',
    HISTORIE_TABULKA: 'historie.tabulka',
    HISTORIE_GRAF: 'historie.graf',
    HISTORIE_SAP_KOMUNIKACE: 'historie.sap.komunikace',
    HISTORIE_DEMO: 'historie.demo',
    HISTORIE_REQUEST_SAP: 'historie.request.sap',
    HISTORIE_RESPONSE_SAP: 'historie.response.sap',
    HISTORIE_RADKU_STRANKA: 'historie.radku.stranka',
    HISTORIE_CTVRTHODINOVY: 'historie.ctvrthodinovy',
    HISTORIE_KW: 'historie.kw',
    HISTORIE_KVAR: 'historie.kVAr',
    HISTORIE_DENNI: 'historie.denni',
    HISTORIE_M3: 'historie.m3',
    HISTORIE_KWH: 'historie.kWh',
    HISTORIE_HODINOVY: 'historie.hodinovy',
    HISTORIE_CINNA_SPOTREBA: 'historie.cinna.spotreba',
    HISTORIE_CINNA_SPOTREBA_FAKTURACE: 'historie.cinna.spotreba.fakturace',
    HISTORIE_CINNA_DODAVKA: 'historie.cinna.dodavka',
    HISTORIE_CINNA_DODAVKA_FAKTURACE: 'historie.cinna.dodavka.fakturace',
    HISTORIE_JALOVA_SPOTREBA_SPOTREBA: 'historie.jalova.spotreba.spotreba',
    HISTORIE_JALOVA_SPOTREBA_DODAVKA: 'historie.jalova.spotreba.dodavka',
    HISTORIE_SPOTREBA_PREPOCITAVAC: 'historie.spotreba.prepocitavac',
    HISTORIE_SPOTREBA_PLYNOMER_ZALOZNI: 'historie.spotreba.plynomer.zalozni',
    HISTORIE_SPOTREBA_PLYNOMER_DOPLNEK: 'historie.spotreba.plynomer.doplnek',
    HISTORIE_STAV_PREPOCTENY: 'historie.stav.prepocteny',
    HISTORIE_STAV_NEPREPOCTENY: 'historie.stav.neprepocteny',
    HISTORIE_NAHRAVAM: 'historie.nahravam',
    HISTORIE_VYHLEDAT: 'historie.vyhledat',
    UCETCERTIFIKATTYP_OVERENI_KLIENTA: 'UcetCertifikatTyp.OVERENI_KLIENTA',
    UCETCERTIFIKATTYP_DIGITALNI_PODPIS: 'UcetCertifikatTyp.DIGITALNI_PODPIS',
    UCETCERTIFIKATTYP_TWIN: 'UcetCertifikatTyp.TWIN',
    SAP_ERROR_GET_CONTACT: 'sap.error.get.contact',
    SAP_ERROR_SET_CONTACT: 'sap.error.set.contact',
    SAP_ERROR_GET_CONTRACT_PERIOD: 'sap.error.get.contract.period',
    SAP_ERROR_GET_CONTRACT_PERIOD_EAN: 'sap.error.get.contract.period.ean',
    SAP_ERROR_GET_POD_DETAIL: 'sap.error.get.pod.detail',
    SAP_ERROR_GET_EDM: 'sap.error.get.edm',
    SAP_ERROR_VALIDATE_USER: 'sap.error.validate.user',
    SAP_WARN_GET_EDM: 'sap.warn.get.edm',
    WIDGET_STATUS_V_RESENI: 'widget.status.v.reseni',
    WIDGET_STATUS_CEKA_NA_OPRAVU: 'widget.status.ceka.na.opravu',
    WIDGET_STATUS_CEKA_NA_PODPIS: 'widget.status.ceka.na.podpis',
    WIDGET_STATUS_CEKA_NA_DOPLNENI: 'widget.status.ceka.na.doplneni',
    WIDGET_STATUS_CEKA_NA_POTVRZENI_OBCHODNIKEM: 'widget.status.ceka.na.potvrzeni.obchodnikem',
    REGISTRACE_VICE_INFO: 'registrace.vice.info',
    REGISTRACE_KOMODITA_CHECKED: 'registrace.komodita.checked',
    REGISTRACE_ONLY_PLYN: 'registrace.only.plyn',
    REGISTRACE_ONLY_ELEKTRINA: 'registrace.only.elektrina',
    REGISTRACE_JSEM_REGISTROVANY: 'registrace.jsem.registrovany',
    REGISTRACE_JSEM_REGISTROVANY_1: 'registrace.jsem.registrovany.1',
    REGISTRACE_JSEM_REGISTROVANY_2: 'registrace.jsem.registrovany.2',
    REGISTRACE_JSEM_REGISTROVANY_3: 'registrace.jsem.registrovany.3',
    REGISTRACE_JSEM_REGISTROVANY_4: 'registrace.jsem.registrovany.4',
    REGISTRACE_JSEM_REGISTROVANY_5: 'registrace.jsem.registrovany.5',
    REGISTRACE_DOKONCENI_TITLE: 'registrace.dokonceni.title',
    REGISTRACE_DOKONCENI_SUBTITLE1: 'registrace.dokonceni.subtitle1',
    REGISTRACE_DOKONCENI_SUBTITLE2: 'registrace.dokonceni.subtitle2',
    REGISTRACE_DOKONCENI_SOUHLAS: 'registrace.dokonceni.souhlas',
    REGISTRACE_DOKONCENI_TYP_SUBJEKTU: 'registrace.dokonceni.typ.subjektu',
    REGISTRACE_DOKONCENI_DATUM_NAROZENI: 'registrace.dokonceni.datum.narozeni',
    REGISTRACE_DOKONCENI_DATUM_NAROZENI_TOOLTIP: 'registrace.dokonceni.datum.narozeni.tooltip',
    REGISTRACE_DOKONCENI_SUBJEKT_PRAVNICKA_OSOBA: 'registrace.dokonceni.subjekt.pravnicka.osoba',
    REGISTRACE_DOKONCENI_SUBJEKT_FYZICKA_OSOBA: 'registrace.dokonceni.subjekt.fyzicka.osoba',
    REGISTRACE_DOKONCENI_IC: 'registrace.dokonceni.ic',
    REGISTRACE_DOKONCENI_CESTNE_PROHLASENI: 'registrace.dokonceni.cestne.prohlaseni',
    REGISTRACE_DOKONCENI_27ZG900: 'registrace.dokonceni.27ZG900',
    REGISTRACE_DOKONCENI_859182400: 'registrace.dokonceni.859182400',
    REGISTRACE_DOKONCENI_DOPLNIT_EAN: 'registrace.dokonceni.doplnit.ean',
    REGISTRACE_DOKONCENI_DOPLNIT_EIC: 'registrace.dokonceni.doplnit.eic',
    REGISTRACE_DOKONCENI_CISLO_ELEKTROMERU: 'registrace.dokonceni.cislo.elektromeru',
    REGISTRACE_DOKONCENI_CISLO_PLYNOMERU: 'registrace.dokonceni.cislo.plynomeru',
    REGISTRACE_DOKONCENI_NOTIFIKACE_TITLE: 'registrace.dokonceni.notifikace.title',
    REGISTRACE_DOKONCENI_NOTIFIKACE_CONTENT: 'registrace.dokonceni.notifikace.content',
    REGISTRACE_DOKONCENI_NOTIFIKACE_CONTENT_PRISTUP: 'registrace.dokonceni.notifikace.content.pristup',
    REGISTRACE_DOKONCENI_NOTIFIKACE_CONTENT_PRISTUP_VICE: 'registrace.dokonceni.notifikace.content.pristup.vice',
    REGISTRACE_DOKONCENI_NOTIFIKACE_BUTTON: 'registrace.dokonceni.notifikace.button',
    REGISTRACE_VERIFIKACE_SUCCESS: 'registrace.verifikace.success',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_SERVIS: 'globalni.kontakty.distributora.tooltip.servis',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_OSTATNI: 'globalni.kontakty.distributora.tooltip.ostatni',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_ELEKTRINA_NN: 'globalni.kontakty.distributora.tooltip.elektrina.nn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_ELEKTRINA_VN: 'globalni.kontakty.distributora.tooltip.elektrina.vn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_PLYN: 'globalni.kontakty.distributora.tooltip.plyn',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_ELEKTRINA_PORUCHA: 'globalni.kontakty.distributora.tooltip.elektrina.porucha',
    GLOBALNI_KONTAKTY_DISTRIBUTORA_TOOLTIP_PLYN_PORUCHA: 'globalni.kontakty.distributora.tooltip.plyn.porucha',
    GLOBALNI_KONTAKTY_DISTRIBUTORA: 'globalni.kontakty.distributora',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP_OBLASTNI_MANAZER: 'skupinove.kontakty.distributora.typ.oblastni.manazer',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP_VEDOUCI_SPRAVY_ELEKTRINA: 'skupinove.kontakty.distributora.typ.vedouci.spravy.elektrina',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TYP_VEDOUCI_SPRAVY_PLYN: 'skupinove.kontakty.distributora.typ.vedouci.spravy.plyn',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_PRACOVNIK_DISTRIBUCE: 'skupinove.kontakty.distributora.pracovnik.distribuce',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TOOLTIP_MANAZER: 'skupinove.kontakty.distributora.tooltip.manazer',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TOOLTIP_VEDOUCI_SPRAVY_ELEKTRINA: 'skupinove.kontakty.distributora.tooltip.vedouci.spravy.elektrina',
    SKUPINOVE_KONTAKTY_DISTRIBUTORA_TOOLTIP_VEDOUCI_SPRAVY_PLYN: 'skupinove.kontakty.distributora.tooltip.vedouci.spravy.plyn',
    ELEKTRIKARI: 'elektrikari',
    BEZPROUDI: 'bezproudi',
    BEZPROUDI_WIDGET_KONTAKTY: 'bezproudi.widget.kontakty',
    BEZPROUDI_WIDGET_VICE_INFORMACI: 'bezproudi.widget.vice.informaci',
    BEZPROUDI_ZKOPIROVAT_SCHRANKA: 'bezproudi.zkopirovat.schranka',
    BEZPROUDI_BANERY: 'bezproudi.banery',
    BEZPROUDI_ZADNE_ODSTAVKY: 'bezproudi.zadne.odstavky',
    BEZPROUDI_ZADNE_ODSTAVKY_WARNING: 'bezproudi.zadne.odstavky.warning',
    BEZPROUDI_ZADNE_ODSTAVKY_OBCE: 'bezproudi.zadne.odstavky.obce',
    BEZPROUDI_ZADNE_PORUCHY: 'bezproudi.zadne.poruchy',
    BEZPROUDI_BANERY_TEXTACE: 'bezproudi.banery.textace',
    BEZPROUDI_POCET_OM: 'bezproudi.pocet.om',
    BEZPROUDI_ZDE: 'bezproudi.zde',
    BEZPROUDI_NAZEV: 'bezproudi.nazev',
    BEZPROUDI_STAV_VSE: 'bezproudi.stav.VSE',
    BEZPROUDI_STAV_AKTIVNI: 'bezproudi.stav.AKTIVNI',
    BEZPROUDI_STAV_STORNOVANA: 'bezproudi.stav.STORNOVANA',
    BEZPROUDI_OBEC: 'bezproudi.obec',
    BEZPROUDI_ULICE: 'bezproudi.ulice',
    BEZPROUDI_CISLO_POPISNE: 'bezproudi.cislo.popisne',
    BEZPROUDI_CISLO_POPISNE_SHORT: 'bezproudi.cislo.popisne.short',
    BEZPROUDI_CISLO_ORIENTACNI: 'bezproudi.cislo.orientacni',
    BEZPROUDI_CISLO_ORIENTACNI_SHORT: 'bezproudi.cislo.orientacni.short',
    BEZPROUDI_CISLO_EVIDENCNI: 'bezproudi.cislo.evidencni',
    BEZPROUDI_CISLO_EVIDENCNI_SHORT: 'bezproudi.cislo.evidencni.short',
    BEZPROUDI_PSC: 'bezproudi.psc',
    BEZPROUDI_CAST_OBCE: 'bezproudi.cast.obce',
    BEZPROUDI_XLSX_NADPIS1: 'bezproudi.xlsx.nadpis1',
    BEZPROUDI_XLSX_NADPIS2: 'bezproudi.xlsx.nadpis2',
    BEZPROUDI_DATUM_CAS_OD: 'bezproudi.datum.cas.od',
    BEZPROUDI_DATUM_CAS_DO: 'bezproudi.datum.cas.do',
    BEZPROUDI_PREHLED_TERMINU: 'bezproudi.prehled.terminu',
    BEZPROUDI_SMLOUVY_OM: 'bezproudi.smlouvy.om',
    BEZPROUDI_DATUM_OD: 'bezproudi.datum.od',
    BEZPROUDI_DATUM_DO: 'bezproudi.datum.do',
    BEZPROUDI_DATUM_SKUTECNY_OD: 'bezproudi.datum.skutecny.od',
    BEZPROUDI_DATUM_SKUTECNY_DO: 'bezproudi.datum.skutecny.do',
    BEZPROUDI_STAV: 'bezproudi.stav',
    BEZPROUDI_CISLO: 'bezproudi.cislo',
    BEZPROUDI_POPIS: 'bezproudi.popis',
    BEZPROUDI_ADRESA: 'bezproudi.adresa',
    BEZPROUDI_ODSTAVKY: 'bezproudi.odstavky',
    BEZPROUDI_ODSTAVKY_FILTER_DATUM_TOOLTIP: 'bezproudi.odstavky.filter.datum.tooltip',
    BEZPROUDI_ODSTAVKY_ZAKAZNIK: 'bezproudi.odstavky.zakaznik',
    BEZPROUDI_ODSTAVKA_DETAIL: 'bezproudi.odstavka.detail',
    BEZPROUDI_ODSTAVKA_DETAIL_CISLO: 'bezproudi.odstavka.detail.cislo',
    BEZPROUDI_ODSTAVKA_NASTENKA_ZADNA: 'bezproudi.odstavka.nastenka.zadna',
    BEZPROUDI_ODSTAVKA_NASTENKA_ZADNA_ZAKAZNIK: 'bezproudi.odstavka.nastenka.zadna.zakaznik',
    BEZPROUDI_ODSTAVKA_NASTENKA: 'bezproudi.odstavka.nastenka',
    BEZPROUDI_ODSTAVKA_NASTENKA_ZAKAZNIK: 'bezproudi.odstavka.nastenka.zakaznik',
    BEZPROUDI_PORUCHY_NASTENKA_ZADNA: 'bezproudi.poruchy.nastenka.zadna',
    BEZPROUDI_PORUCHY_NASTENKA_ZADNA_ZAKAZNIK: 'bezproudi.poruchy.nastenka.zadna.zakaznik',
    BEZPROUDI_PORUCHY_NASTENKA: 'bezproudi.poruchy.nastenka',
    BEZPROUDI_PORUCHY_NASTENKA_ZAKAZNIK: 'bezproudi.poruchy.nastenka.zakaznik',
    BEZPROUDI_PORUCHY_V_OBCI: 'bezproudi.poruchy.v.obci',
    BEZPROUDI_PORUCHY: 'bezproudi.poruchy',
    BEZPROUDI_PORUCHY_XLSX_NADPIS1: 'bezproudi.poruchy.xlsx.nadpis1',
    BEZPROUDI_PORUCHY_NEEXISTUJE: 'bezproudi.poruchy.neexistuje',
    BEZPROUDI_PORUCHY_ZDE: 'bezproudi.poruchy.zde',
    BEZPROUDI_PORUCHY_DETAIL_CISLO: 'bezproudi.poruchy.detail.cislo',
    BEZPROUDI_PORUCHY_KALAMITA: 'bezproudi.poruchy.kalamita',
    BEZPROUDI_PORUCHY_KALAMITA_CHECKBOX: 'bezproudi.poruchy.kalamita.checkbox',
    BEZPROUDI_PORUCHY_CISLO: 'bezproudi.poruchy.cislo',
    BEZPROUDI_PORUCHY_CAS_VZNIKU: 'bezproudi.poruchy.cas.vzniku',
    BEZPROUDI_PORUCHY_PREDPOKLADANY_CAS: 'bezproudi.poruchy.predpokladany.cas',
    BEZPROUDI_PORUCHY_EAN: 'bezproudi.poruchy.ean',
    BEZPROUDI_PORUCHY_OM: 'bezproudi.poruchy.om',
    BEZPROUDI_PORUCHY_DATUM: 'bezproudi.poruchy.datum',
    BEZPROUDI_PORUCHY_PREDPOKLADANY_TERMIN: 'bezproudi.poruchy.predpokladany.termin',
    BEZPROUDI_PORUCHY_CISLO_PORUCHY: 'bezproudi.poruchy.cislo.poruchy',
    BEZPROUDI_PORUCHY_KOMENTAR: 'bezproudi.poruchy.komentar',
    SERVISNI_ZAKAZKY_TITLE: 'servisni.zakazky.title',
    SERVISNI_ZAKAZKY_PLANOVANI_TERMINU: 'servisni.zakazky.planovani.terminu',
    SERVISNI_ZAKAZKY_DETAIL: 'servisni.zakazky.detail',
    SERVISNI_ZAKAZKY_ZAKLADNI_INFORMACE: 'servisni.zakazky.zakladni.informace',
    SERVISNI_ZAKAZKY_EAN_SUBTITLE: 'servisni.zakazky.ean.subtitle',
    SERVISNI_ZAKAZKY_EIC_SUBTITLE: 'servisni.zakazky.eic.subtitle',
    SERVISNI_ZAKAZKY_TEXTACE_1: 'servisni.zakazky.textace.1',
    SERVISNI_ZAKAZKY_TEXTACE_2: 'servisni.zakazky.textace.2',
    SERVISNI_ZAKAZKY_NAPLANOVANA_ZAKAZKA: 'servisni.zakazky.naplanovana.zakazka',
    SERVISNI_ZAKAZKY_NAPLANOVANA_ZAKAZKA_SUBTITLE: 'servisni.zakazky.naplanovana.zakazka.subtitle',
    SERVISNI_ZAKAZKY_NAPLANOVANA_ZAKAZKA_INFO: 'servisni.zakazky.naplanovana.zakazka.info',
    SERVISNI_ZAKAZKY_LEGENDA: 'servisni.zakazky.legenda',
    SERVISNI_ZAKAZKY_NAPLANOVANY_TERMIN: 'servisni.zakazky.naplanovany.termin',
    SERVISNI_ZAKAZKY_PREFEROVANY_TERMIN: 'servisni.zakazky.preferovany.termin',
    SERVISNI_ZAKAZKY_VOLNY_TERMIN: 'servisni.zakazky.volny.termin',
    SERVISNI_ZAKAZKY_VSECHNY_TERMINY: 'servisni.zakazky.vsechny.terminy',
    SERVISNI_ZAKAZKY_SUPPORT_TELEFON: 'servisni.zakazky.support.telefon',
    SERVISNI_ZAKAZKY_TELEFON_TOOLTIP: 'servisni.zakazky.telefon.tooltip',
    SERVISNI_ZAKAZKY_NAPLANOVANA_ZAKAZKA_ZMENA: 'servisni.zakazky.naplanovana.zakazka.zmena',
    SERVISNI_ZAKAZKY_TERMIN_NENI: 'servisni.zakazky.termin.neni',
    SERVISNI_ZAKAZKY_NAZEV: 'servisni.zakazky.nazev',
    SERVISNI_ZAKAZKY_CISLO_SZ: 'servisni.zakazky.cislo.sz',
    SERVISNI_ZAKAZKY_EAN: 'servisni.zakazky.ean',
    SERVISNI_ZAKAZKY_EIC: 'servisni.zakazky.eic',
    SERVISNI_ZAKAZKY_EAN_EIC: 'servisni.zakazky.ean.eic',
    SERVISNI_ZAKAZKY_ADRESA: 'servisni.zakazky.adresa',
    SERVISNI_ZAKAZKY_DUVOD: 'servisni.zakazky.duvod',
    SERVISNI_ZAKAZKY_DATUM: 'servisni.zakazky.datum',
    SERVISNI_ZAKAZKY_ZMENA_TERMINU: 'servisni.zakazky.zmena.terminu',
    SERVISNI_ZAKAZKY_POTVRZENI: 'servisni.zakazky.potvrzeni',
    SERVISNI_ZAKAZKY_NASTENKA: 'servisni.zakazky.nastenka',
    SERVISNI_ZAKAZKY_NO_DATA: 'servisni.zakazky.no.data',
    CELY_DEN: 'cely.den',
    SERVISNI_ZAKAZKY_NAPLANOVANA_ZAKAZKA_SUBTITLE_MORE: 'servisni.zakazky.naplanovana.zakazka.subtitle.more',
    SERVISNI_ZAKAZKY_NAPLANOVANA_ZAKAZKA_SUBTITLE_NO_MORE: 'servisni.zakazky.naplanovana.zakazka.subtitle.no.more',
    EXPORT_BUTTON_TOOLTIP: 'export.button.tooltip',
    EXPORT_WORKING: 'export.working',
    EXPORT_SUCCESS: 'export.success',
    EXPORT_NO_DATA: 'export.no.data',
    EXPORT_NO_DATA_DAVKA: 'export.no.data.davka',
    EXPORT_ODECTY_TITLE: 'export.odecty.title',
    EXPORT_ODECTY_COLUMN_EAN: 'export.odecty.column.ean',
    EXPORT_ODECTY_COLUMN_EIC: 'export.odecty.column.eic',
    EXPORT_ODECTY_COLUMN_CISLOPRISTROJE: 'export.odecty.column.cisloPristroje',
    EXPORT_ODECTY_COLUMN_ODESLANO: 'export.odecty.column.odeslano',
    EXPORT_ODECTY_COLUMN_STAVRESENI: 'export.odecty.column.stavReseni',
    EXPORT_ODECTY_COLUMN_CISLODAVKY: 'export.odecty.column.cisloDavky',
    EXPORT_ODECTY_COLUMN_CISLO: 'export.odecty.column.cislo',
    EXPORT_ODECTY_COLUMN_DATUMODECTU: 'export.odecty.column.datumOdectu',
    EXPORT_ODECTY_COLUMN_DUVOD: 'export.odecty.column.duvod',
    EXPORT_ODECTY_COLUMN_STAVMERIDLA: 'export.odecty.column.stavMeridla',
    EXPORT_ODECTY_COLUMN_STAVMERIDLAVT: 'export.odecty.column.stavMeridlaVt',
    EXPORT_ODECTY_COLUMN_STAVMERIDLANT: 'export.odecty.column.stavMeridlaNt',
    EXPORT_ODECTY_COLUMN_VAROVANICHYBY: 'export.odecty.column.varovaniChyby',
    EXPORT_ODECTY_COLUMN_OPRAVNYODECET: 'export.odecty.column.opravnyOdecet',
    EXPORT_ODECTY_COLUMN_ZAMITNOUTNEVEROHODNEIHNED: 'export.odecty.column.zamitnoutNeverohodneIhned',
    VYJADROVACKY_WIDGET_TITLE: 'vyjadrovacky.widget.title',
    VYJADROVACKY_WIDGET_TEXT: 'vyjadrovacky.widget.text',
    SYMBOL_OTAZNIK: 'symbol.otaznik',
    PRILOHA_ZADNY_NAZEV: 'priloha.zadny.nazev',
    TOOLTIP_EAN: 'tooltip.ean',
    TOOLTIP_EIC: 'tooltip.eic',
    TOOLTIP_ELEKTROMER: 'tooltip.elektromer',
    TOOLTIP_PLYNOMER: 'tooltip.plynomer',
    YUP_MIN_ONE: 'yup.min.one',
    YUP_ODPLVAL001: 'yup.odplval001',
    YUP_NUMBER_ZADVAL061: 'yup.number.zadval061',
    YUP_NUMBER_ZADVAL034: 'yup.number.zadval034',
    YUP_NUMBER_ZADVAL033: 'yup.number.zadval033',
    YUP_NUMBER_ICO_NO_VALID: 'yup.number.ico.no.valid',
    YUP_ONEOF: 'yup.oneOf',
    YUP_DATUMOD_DATUMDO: 'yup.datumOd.datumDo',
    YUP_NUMBER_ICO: 'yup.number.ico',
    YUP_NUMBER: 'yup.number',
    YUP_NUMBER_ODECTY: 'yup.number.odecty',
    YUP_NUMBER_ZADVAL147: 'yup.number.zadval147',
    YUP_EAN: 'yup.ean',
    YUP_EIC: 'yup.eic',
    YUP_DATUM_MAX_THREE_YEARS: 'yup.datum.max.three.years',
    YUP_DATUM_MIN_FIVE_DAYS: 'yup.datum.min.five.days',
    YUP_FIRST_DATE: 'yup.first.date',
    YUP_DATUM_BUDOUCNOST: 'yup.datum.budoucnost',
    YUP_DATUM_INVALID: 'yup.datum.invalid',
    YUP_DATUM_MINULOST: 'yup.datum.minulost',
    YUP_DATUM_MAX_ONE_MONTH: 'yup.datum.max.one.month',
    YUP_DATUM_MAX_C123BNN: 'yup.datum.max.c123bnn',
    YUP_DATUM_LAST_MONTH: 'yup.datum.last.month',
    YUP_PHONE_CZ: 'yup.phone.cz',
    YUP_PHONE_NOT_CORRECT: 'yup.phone.not.correct',
    YUP_TELEFON_PREDVOLBA: 'yup.telefon.predvolba',
    YUP_DATUM_ROZMEZI: 'yup.datum.rozmezi',
    YUP_PHONE_SK: 'yup.phone.sk',
    YUP_EMAIL: 'yup.email',
    YUP_MIN: 'yup.min',
    YUP_MIN_2: 'yup.min.2',
    YUP_MAX: 'yup.max',
    YUP_REQUIRED: 'yup.required',
    YUP_STRING_WHITESPACE: 'yup.string.whitespace',
    YUP_DATUM_MINDATE: 'yup.datum.minDate',
    YUP_STRING_ONLY: 'yup.string.only',
    MOBILE_TITLE: 'mobile.title',
    MOBILE_LATITUDE: 'mobile.latitude',
    MOBILE_LONGITUDE: 'mobile.longitude',
    MOBILE_TITLE_TEXTACE: 'mobile.title.textace',
    MOBILE_CONFIRMATION_TEXT: 'mobile.confirmation.text',
    CEKEJTE_PROSIM: 'cekejte.prosim',
    ANO: 'ano',
    NE: 'ne',
    NN: 'nn',
    VN: 'vn',
    VYKRICNIK: 'vykricnik',
    POTVRZENI: 'potvrzeni',
    GRAPHQL_ERROR: 'graphql.error',
    SAP_ERROR: 'sap.error',
    ERROR_SHOWSOURCE: 'error.showSource',
    AUTOCOMPLETE_NO_RECORDS: 'autocomplete.no.records',
    AUTOCOMPLETE_PLACEHOLDER: 'autocomplete.placeholder',
    BUTTON_PRIDAT: 'button.pridat',
    BUTTON_PODEPSAT: 'button.podepsat',
    BUTTON_ZPET: 'button.zpet',
    BUTTON_ZPET_NA_PREHLED: 'button.zpet.na.prehled',
    KALENDAR: 'kalendar',
    DATUM_OD: 'datum.od',
    DATUM_DO: 'datum.do',
    KALENDAR_NEOMEZENE: 'kalendar.neomezene',
    MORE_INFO: 'more.info',
    MORE_INFO_TITLE: 'more.info.title',
    BUTTON_VYBRAT: 'button.vybrat',
    BUTTON_POTVRDIT: 'button.potvrdit',
    BUTTON_ZAVRIT: 'button.zavrit',
    BUTTON_VYTVORIT: 'button.vytvorit',
    BUTTON_SMAZAT: 'button.smazat',
    BUTTON_AKTIVOVAT: 'button.aktivovat',
    BUTTON_DEAKTIVOVAT: 'button.deaktivovat',
    BUTTON_UPRAVIT: 'button.upravit',
    BUTTON_DOWNLOAD: 'button.download',
    BUTTON_EXPORT: 'button.export',
    BUTTON_VICE_INFORMACI: 'button.vice.informaci',
    BUTTON_AKTUALIZOVAT: 'button.aktualizovat',
    BUTTON_ULOZIT: 'button.ulozit',
    BUTTON_ULOZIT_KONCEPT: 'button.ulozit.koncept',
    BUTTON_VYHLEDAT: 'button.vyhledat',
    BUTTON_ODESLAT: 'button.odeslat',
    BUTTON_ROOT: 'button.root',
    BUTTON_STORNO: 'button.storno',
    BUTTON_DALSI: 'button.dalsi',
    BUTTON_ZRUSIT_FILTR: 'button.zrusit.filtr',
    BUTTON_SOUHLAS: 'button.souhlas',
    BUTTON_ZRUSIT: 'button.zrusit',
    POZADAVEK_TYP_OSTATNI: 'pozadavek.typ.OSTATNI',
    POZADAVEK_TYP_CHYBA_SYSTEMU: 'pozadavek.typ.CHYBA_SYSTEMU',
    POZADAVEK_TYP_NAVRH_NA_VYLEPSENI: 'pozadavek.typ.NAVRH_NA_VYLEPSENI',
    POZADAVEK_TYP_ZADOST_O_REGISTRACI: 'pozadavek.typ.ZADOST_O_REGISTRACI',
    POZADAVEK_STAV_CEKA_NA_DOPLNENI: 'pozadavek.stav.CEKA_NA_DOPLNENI',
    POZADAVEK_STAV_V_RESENI: 'pozadavek.stav.V_RESENI',
    POZADAVEK_STAV_SCHVALENO: 'pozadavek.stav.SCHVALENO',
    POZADAVEK_STAV_ZAMITNUTO: 'pozadavek.stav.ZAMITNUTO',
    POZADAVEK_STAV_STORNO: 'pozadavek.stav.STORNO',
    UCET_SKUPINA_TYP_OBCHODNIK: 'ucet.skupina.typ.OBCHODNIK',
    UCET_SKUPINA_TYP_MUNICIPALITA: 'ucet.skupina.typ.MUNICIPALITA',
    UCET_SKUPINA_TYP_ZAKAZNIK_SE_SMLOUVOU_NAPRIMO: 'ucet.skupina.typ.ZAKAZNIK_SE_SMLOUVOU_NAPRIMO',
    UCET_SKUPINA_TYP_KONCOVY_ZAKAZNIK: 'ucet.skupina.typ.KONCOVY_ZAKAZNIK',
    UCET_SKUPINA_TYP_ZADATEL: 'ucet.skupina.typ.ZADATEL',
    UCET_SKUPINA_TYP_VYROBCE_PROVOZOVATEL: 'ucet.skupina.typ.VYROBCE_PROVOZOVATEL',
    UCET_SKUPINA_TYP_DODAVATEL: 'ucet.skupina.typ.DODAVATEL',
    PRILOHY_PRILIS_MNOHO_SOUBORU: 'prilohy.prilis.mnoho.souboru',
    PRILOHY_VELKY_SOUBOR: 'prilohy.velky.soubor',
    PRILOHY_MAX_FILE_SIZE_ALL: 'prilohy.max.file.size.all',
    PRILOHY_MUZETE_VLOZIT_0: 'prilohy.muzete.vlozit.0',
    PRILOHA_NAZEV_ERROR: 'priloha.nazev.error',
    PRILOHA_POVINNE_TYPY_INFO: 'priloha.povinne.typy.info',
    PRILOHY_MUZETE_VLOZIT_1: 'prilohy.muzete.vlozit.1',
    PRILOHY_MUZETE_VLOZIT_2: 'prilohy.muzete.vlozit.2',
    PRILOHY_MUZETE_VLOZIT_5: 'prilohy.muzete.vlozit.5',
    PRILOHY_ARCHIVOVANE: 'prilohy.archivovane',
    PRILOHY_K_NAHRANI: 'prilohy.k.nahrani',
    PRILOHY_POTVRZENI_SMAZANI: 'prilohy.potvrzeni.smazani',
    PRILOHY_VYBERTE_TYP: 'prilohy.vyberte.typ',
    PRILOHY_VYBERTE_TYP_HINT: 'prilohy.vyberte.typ.hint',
    PRILOHY_VYBERTE_DIAKRITIKA_HINT: 'prilohy.vyberte.diakritika.hint',
    PRILOHY_ZADNE_INFO: 'prilohy.zadne.info',
    PRILOHY_OTAZNIK: 'prilohy.otaznik',
    PRILOHA_TYP_ZRQ01: 'priloha.typ.ZRQ01',
    PRILOHA_TYP_ZRQ02: 'priloha.typ.ZRQ02',
    PRILOHA_TYP_ZRQ03: 'priloha.typ.ZRQ03',
    PRILOHA_TYP_ZRQ04: 'priloha.typ.ZRQ04',
    PRILOHA_TYP_ZRQ05: 'priloha.typ.ZRQ05',
    PRILOHA_TYP_ZRQ06: 'priloha.typ.ZRQ06',
    PRILOHA_TYP_ZRQ07: 'priloha.typ.ZRQ07',
    PRILOHA_TYP_ZRQ08: 'priloha.typ.ZRQ08',
    PRILOHA_TYP_ZRQ09: 'priloha.typ.ZRQ09',
    PRILOHA_TYP_ZRQ10: 'priloha.typ.ZRQ10',
    PRILOHA_TYP_ZRQ11: 'priloha.typ.ZRQ11',
    PRILOHA_TYP_ZRQ12: 'priloha.typ.ZRQ12',
    PRILOHA_TYP_ZRQ13: 'priloha.typ.ZRQ13',
    PRILOHA_TYP_ZRQ99: 'priloha.typ.ZRQ99',
    PRILOHA_TYP_ZRAS0001: 'priloha.typ.ZRAS0001',
    PRILOHA_TYP_ZRAS0003: 'priloha.typ.ZRAS0003',
    PRILOHA_TYP_ZRAS0004: 'priloha.typ.ZRAS0004',
    PRILOHA_TYP_ZRAS0005: 'priloha.typ.ZRAS0005',
    PRILOHA_TYP_ZADOST: 'priloha.typ.ZADOST',
    PRILOHA_TYP_ZDOCOST: 'priloha.typ.ZDOCOST',
    PRILOHA_TYP_ZDOD: 'priloha.typ.ZDOD',
    PRILOHA_TYP_ZPM: 'priloha.typ.ZPM',
    PRILOHA_TYP_ZRZ: 'priloha.typ.ZRZ',
    PRILOHA_TYP_ZSMLIN: 'priloha.typ.ZSMLIN',
    PRILOHA_TYP_ZSMLNAVOUT: 'priloha.typ.ZSMLNAVOUT',
    PRILOHA_TYP_ZSMLUK: 'priloha.typ.ZSMLUK',
    PRILOHA_TYP_ZELEMOBIL: 'priloha.typ.ZELEMOBIL',
    PRILOHA_TYP_ZRQ22: 'priloha.typ.ZRQ22',
    PRILOHA_TYP_ZRQ24: 'priloha.typ.ZRQ24',
    PRILOHA_TYP_OSTATNI: 'priloha.typ.OSTATNI',
    PRILOHA_TYP_VYLEPSENI: 'priloha.typ.VYLEPSENI',
    PRILOHA_TYP_CHYBA: 'priloha.typ.CHYBA',
    PRILOHA_TYP_REGISTRACE: 'priloha.typ.REGISTRACE',
    PRILOHA_TYP_ZSOUHLAS: 'priloha.typ.ZSOUHLAS',
    PRILOHA_TYP_ZCA_PROHL: 'priloha.typ.ZCA_PROHL',
    PRILOHA_TYP_ZCA_CP: 'priloha.typ.ZCA_CP',
    PRILOHA_TYP_ZPD: 'priloha.typ.ZPD',
    PRILOHA_TYP_Z1PSCHEMA: 'priloha.typ.Z1PSCHEMA',
    PRILOHA_TYP_SOUHLAS_VLASTNIKA_NEMOVITOSTI: 'priloha.typ.SOUHLAS_VLASTNIKA_NEMOVITOSTI',
    PRILOHA_TYP_PROJEKTOVA_DOKUMENTACE: 'priloha.typ.PROJEKTOVA_DOKUMENTACE',
    PRILOHA_TYP_JEDNOPOLOVE_SCHEMA: 'priloha.typ.JEDNOPOLOVE_SCHEMA',
    PRILOHA_TYP_PRILOHY_ERROR: 'priloha.typ.prilohy.error',
    PRILOHA_TYP_PRILOHY_POVINNE: 'priloha.typ.prilohy.povinne',
    PRILOHY_NEPOVOLENY_FORMAT_EXTENSION: 'prilohy.nepovoleny.format.extension',
    PRILOHY_BEZ_PRILOHY: 'prilohy.bez.prilohy',
    PRILOHY_MAX: 'prilohy.max',
    PRILOHY_NAZEV_EXIST: 'prilohy.nazev.exist',
    PRILOHY_NAZEV_DUPLICITA: 'prilohy.nazev.duplicita',
    PRILOHY_NEPOVOLENY_POCET: 'prilohy.nepovoleny.pocet',
    PRILOHA_NEJDE_VLOZIT: 'priloha.nejde.vlozit',
    PRILOHA_TYP_OBJEKTU_REKLAMACE_ELEKTRINA: 'priloha.typ.objektu.REKLAMACE_ELEKTRINA',
    PRILOHA_TYP_OBJEKTU_REKLAMACE_PLYN: 'priloha.typ.objektu.REKLAMACE_PLYN',
    PRILOHA_TYP_OBJEKTU_REKLAMACE_KOMENTAR: 'priloha.typ.objektu.REKLAMACE_KOMENTAR',
    PRILOHA_TYP_OBJEKTU_ZADOST_ELEKTRINA_RAMCOVA_SMLOUVA: 'priloha.typ.objektu.ZADOST_ELEKTRINA_RAMCOVA_SMLOUVA',
    PRILOHA_TYP_OBJEKTU_ZADOST_ELEKTRINA_SOP_PREPIS: 'priloha.typ.objektu.ZADOST_ELEKTRINA_SOP_PREPIS',
    PRILOHA_TYP_OBJEKTU_ZADOST_PLYN_SOP_PREPIS: 'priloha.typ.objektu.ZADOST_PLYN_SOP_PREPIS',
    PRILOHA_TYP_OBJEKTU_ZADOST_ELEKTRINA_SOP_ZMENA_PRIKONU_JISTICE: 'priloha.typ.objektu.ZADOST_ELEKTRINA_SOP_ZMENA_PRIKONU_JISTICE',
    PRILOHA_TYP_OBJEKTU_ZADOST_SOP_NOVE_PRIPOJENI: 'priloha.typ.objektu.ZADOST_SOP_NOVE_PRIPOJENI',
    PRILOHA_TYP_OBJEKTU_DODATEK_PLYN: 'priloha.typ.objektu.DODATEK_PLYN',
    PRILOHA_TYP_OBJEKTU_POZADAVEK_ZADOST_O_REGISTRACI: 'priloha.typ.objektu.POZADAVEK_ZADOST_O_REGISTRACI',
    PRILOHA_TYP_OBJEKTU_POZADAVEK_OSTATNI: 'priloha.typ.objektu.POZADAVEK_OSTATNI',
    PRILOHA_TYP_OBJEKTU_POZADAVEK_CHYBA_SYSTEMU: 'priloha.typ.objektu.POZADAVEK_CHYBA_SYSTEMU',
    PRILOHA_TYP_OBJEKTU_POZADAVEK_NAVRH_NA_VYLEPSENI: 'priloha.typ.objektu.POZADAVEK_NAVRH_NA_VYLEPSENI',
    PRILOHA_TYP_OBJEKTU_REVIZNI_ZPRAVA_NN: 'priloha.typ.objektu.REVIZNI_ZPRAVA_NN',
    PRILOHA_TYP_OBJEKTU_REVIZNI_ZPRAVA_VN_VVN: 'priloha.typ.objektu.REVIZNI_ZPRAVA_VN_VVN',
    FORM_FILEINPUT_ARIA: 'form.fileInput.aria',
    FORM_FILEINPUT_MESSAGE1: 'form.fileInput.message1',
    FORM_FILEINPUT_MESSAGE2: 'form.fileInput.message2',
    FORM_DATEPICKER_BUTTON_DNES: 'form.datepicker.button.dnes',
    FORM_DATEPICKER_BUTTON_ZRUSIT: 'form.datepicker.button.zrusit',
    FORM_DATEPICKER_BUTTON_OK: 'form.datepicker.button.ok',
    FORM_DATEPICKER_TOOLTIP_SMAZAT: 'form.datepicker.tooltip.smazat',
    FORM_DATEPICKER_TOOLTIP_KALENDAR: 'form.datepicker.tooltip.kalendar',
    FORM_DATEPICKER_TOOLTIP_INFINITY: 'form.datepicker.tooltip.infinity',
    ERROR_HTTP_NOT_FOUND_TITLE: 'error.http.not.found.title',
    ERROR_HTTP_NOT_FOUND_CONTENT: 'error.http.not.found.content',
    ERROR_HTTP_INTERNAL_SERVER_ERROR_TITLE: 'error.http.internal.server.error.title',
    ERROR_HTTP_INTERNAL_SERVER_ERROR_CONTENT1: 'error.http.internal.server.error.content1',
    ERROR_HTTP_INTERNAL_SERVER_ERROR_CONTENT2: 'error.http.internal.server.error.content2',
    ERROR_HTTP_UNEXPECTED_TITLE: 'error.http.unexpected.title',
    ERROR_HTTP_UNEXPECTED_CONTENT1: 'error.http.unexpected.content1',
    ERROR_HTTP_UNEXPECTED_CONTENT2: 'error.http.unexpected.content2',
    ERROR_HTTP_HINT: 'error.http.hint',
    SUPPORT_EMAIL: 'support.email',
    YUP_VALIDATION_EAN: 'yup.validation.ean',
    YUP_VALIDATION_EIC: 'yup.validation.eic',
    YUP_VALIDATION_TYP: 'yup.validation.typ',
    YUP_VALIDATION_CISLO: 'yup.validation.cislo',
    ENVBADGE_TEXT: 'EnvBadge.text',
    TABLE_RADKY_DALSI: 'table.radky.dalsi',
    TABLE_RADKY_PREDCHOZI: 'table.radky.predchozi',
    TABLE_RADKY_STRANKA: 'table.radky.stranka',
    TABLE_VYBRANO: 'table.vybrano',
    TABLE_SLOUPEC: 'table.sloupec',
    TABLE_OBSAHUJE: 'table.obsahuje',
    TABLE_VALUE: 'table.value',
    DETAIL_PRILOHY: 'detail.prilohy',
    DIALOG_DETAIL: 'dialog.detail',
};
